import {
  APP_AUTHORITY,
  BANNER_DESCRIPTION_COLOR,
  CAR_TYPE,
  CREDIT_CARD_BRAND,
  INTERNAL_DOCUMENT_PROGRESS_STATUS,
  MISSION_TARGET_TYPE,
  MISSION_TYPE,
  PASSWORD_CRITERIA,
  PLACE_CATEGORY,
  PLACE_CHANGE_REQUEST,
  PLACE_CHANGE_REQUEST_STATUS,
  PROMO_TYPE_LOYALTY,
  PROMO_TYPE_NORMAL,
  PROMO_TYPE_SIGN_UP,
  PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE,
  PROMO_VALID_UNTIL_POLICY_FIXED,
  PROMO_VALID_UNTIL_POLICY_INHERIT,
  REGION,
  MISSION_BACKGROUND_PROGRESS_STATUS,
  MISSION_BACKGROUND_PROGRESS_TYPE,
  SINGPASS_DRIVER_LICENSE_VALID_TYPE,
  SINGPASS_LTA_LICENSE_STATUS_TYPE,
  TAXI_TYPE,
  SG_TRANSACTION_CATEGORY_TYPE,
  ADDITIONAL_FARE_TYPE,
  RESTRICT_TYPE_AND_ALLOWANCE_TYPE,
  DISCLAIMER_TYPE,
  BANK_NAME_HK,
} from '@/assets/constants/enum';
import {
  AppAuthority,
  BannerDescriptionColorType,
  CarType,
  InternalDocumentProgressStatusType,
  MissionTargetType,
  MissionType,
  PasswordCriteriaType,
  MissionBackgroundProgressStatus,
  MissionBackgroundProgressType,
  SingpassDriverLicenseValidType,
  SingpassLTALicenseStatusType,
  TaxiType,
  SGTransactionCategoryType,
  RestrictTypeAndAllowanceType,
} from '@/assets/constants/type';

export const PLACE_CHANGE_REQUEST_STRING: Record<string, string> = {
  [PLACE_CHANGE_REQUEST.REPORT]: 'Report',
  [PLACE_CHANGE_REQUEST.ADD]: 'Add',
};

export const PLACE_CHANGE_REQUEST_STATUS_STRING: Record<number, string> = {
  [PLACE_CHANGE_REQUEST_STATUS.PENDING]: 'Pending',
  [PLACE_CHANGE_REQUEST_STATUS.APPROVED]: 'Approved',
  [PLACE_CHANGE_REQUEST_STATUS.REJECTED]: 'Rejected',
};

export const PLACE_CHANGE_REQUEST_STATUS_STRING_COLOR: Record<number, string> = {
  [PLACE_CHANGE_REQUEST_STATUS.PENDING]: 'tw-text-black-poi',
  [PLACE_CHANGE_REQUEST_STATUS.APPROVED]: 'tw-text-green-poi',
  [PLACE_CHANGE_REQUEST_STATUS.REJECTED]: 'tw-text-red-poi',
};

export const PLACE_CATEGORY_STRING: Record<number, string> = {
  [PLACE_CATEGORY.HDB]: 'HDB',
  [PLACE_CATEGORY.CONDO]: 'Condo',
  [PLACE_CATEGORY.OTHERS]: 'Others',
};

export const TRANSLATE_REGION_STRINGS: Record<string | REGION, string> = {
  [REGION.SG]: 'constants.region_string.singapore',
  [REGION.VN]: 'constants.region_string.vietnam',
  [REGION.KH]: 'constants.region_string.cambodia',
  [REGION.KR]: 'constants.region_string.korea',
  [REGION.ET]: 'constants.region_string.ethiopia',
  [REGION.ID]: 'constants.region_string.indonesia',
  [REGION.HK]: 'constants.region_string.hongkong',
  [REGION.TH]: 'constants.region_string.thailand',
  [REGION.GLOBAL]: 'constants.region_string.global',
};

export const REGION_STRINGS: Record<string | REGION, string> = {
  [REGION.SG]: 'Singapore',
  [REGION.VN]: 'Vietnam',
  [REGION.KH]: 'Cambodia',
  [REGION.KR]: 'Korea',
  [REGION.ET]: 'Ethiopia',
  [REGION.ID]: 'Indonesia',
  [REGION.HK]: 'Hongkong',
  [REGION.TH]: 'Thailand',
  [REGION.GLOBAL]: 'Global',
};

export const PROMO_VALID_UNTIL_POLICY_STRINGS: Record<string | number, string> = {
  [PROMO_VALID_UNTIL_POLICY_INHERIT]: 'Inherit',
  [PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE]: 'After Issue',
  [PROMO_VALID_UNTIL_POLICY_FIXED]: 'Fixed',
};

export const TRANSLATE_PROMO_VALID_UNTIL_POLICY_STRINGS = {
  [PROMO_VALID_UNTIL_POLICY_INHERIT]: 'constants.promo_valid_until_policy.inherit',
  [PROMO_VALID_UNTIL_POLICY_AFTER_ISSUE]: 'constants.promo_valid_until_policy.after_issue',
  [PROMO_VALID_UNTIL_POLICY_FIXED]: 'constants.promo_valid_until_policy.fixed',
};

export const PROMO_TYPE_STRINGS: Record<string | number, string> = {
  [PROMO_TYPE_NORMAL]: 'Normal',
  [PROMO_TYPE_SIGN_UP]: 'Sign Up',
  [PROMO_TYPE_LOYALTY]: 'Loyalty',
};

export const PASSWORD_CRITERIA_STRING: Record<PasswordCriteriaType, string> = {
  [PASSWORD_CRITERIA.NOT_SAME_CURRENT_PASSWORD]: 'New password cannot be the same as current password',
  [PASSWORD_CRITERIA.MINIMUM_CHARACTER_LENGTH]: 'Minimum of 8 Characters',
  [PASSWORD_CRITERIA.CONTAIN_BOTH_LETTER_AND_NUMBER]: 'Contains both letters and numbers (Alphanumeric)',
  [PASSWORD_CRITERIA.CONTAIN_UPPER_CASE_LETTER]: 'Contain at least 1 Uppercase Letter',
  [PASSWORD_CRITERIA.CONTAIN_LOWER_CASE_LETTER]: 'Contain at least 1 Lowercase Letter',
  [PASSWORD_CRITERIA.CONTAIN_SYMBOL]: 'Contains at least 1 symbol',
  [PASSWORD_CRITERIA.DO_NOT_INCLUDE_SPACE]: "Don't include space",
};

export const TRANSLATE_CAR_TYPE_STRINGS: Record<CarType, string> = {
  [CAR_TYPE.CAR_TYPE_SEDAN]: 'constants.car_type_sedan',
  [CAR_TYPE.CAR_TYPE_VAN]: 'constants.car_type_van',
  [CAR_TYPE.CAR_TYPE_BUSINESS_VAN]: 'constants.car_type_business_van',
  [CAR_TYPE.CAR_TYPE_BLACK_SEDAN]: 'constants.car_type_black_sedan',
  [CAR_TYPE.CAR_TYPE_TUKTUK]: 'constants.car_type_tuktuk',
  [CAR_TYPE.CAR_TYPE_BIKE]: 'constants.car_type_bike',
  [CAR_TYPE.CAR_TYPE_REMORQUE]: 'constants.car_type_remorque',
  [CAR_TYPE.CAR_TYPE_PREMIUM_BIKE]: 'constants.car_type_premium_bike',
  [CAR_TYPE.CAR_TYPE_WIN_BIKE]: 'constants.car_type_win_bike',
  [CAR_TYPE.CAR_TYPE_MINI_BUS]: 'constants.car_type_mini_bus',
  [CAR_TYPE.CAR_TYPE_BUS]: 'constants.car_type_bus',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD]: 'constants.car_type_taxi_standard',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE]: 'constants.car_type_taxi_standard_large',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM]: 'constants.car_type_taxi_premium',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE]: 'constants.car_type_taxi_premium_large',
  [CAR_TYPE.CAR_TYPE_DELIVERY_VAN]: 'constants.car_type_delivery_van',
  [CAR_TYPE.CAR_TYPE_DELIVERY_BIKE]: 'constants.car_type_delivery_bike',
  [CAR_TYPE.CAR_TYPE_DELIVERY_CAR]: 'constants.car_type_delivery_car',
  [CAR_TYPE.CAR_TYPE_ONION_TUKTUK]: 'constants.car_type_onion_tuktuk',
  // Deprecate Car Type
  [CAR_TYPE.CAR_TYPE_MIDDLE_SEDAN]: 'constants.car_type_middle_sedan',
  [CAR_TYPE.CAR_TYPE_BUSINESS_SEDAN]: 'constants.car_type_business_sedan',
  [CAR_TYPE.CAR_TYPE_BUSINESS_BUS]: 'constants.car_type_business_bus',
  [CAR_TYPE.CAR_TYPE_SUV]: 'constants.car_type_suv',
  [CAR_TYPE.CAR_TYPE_COMPACT_SUV]: 'constants.car_type_compact_suv',
  [CAR_TYPE.CAR_TYPE_FOOT]: 'constants.car_type_foot',
};

export const CAR_TYPE_STRINGS: Record<CarType | number, string> = {
  [CAR_TYPE.CAR_TYPE_SEDAN]: 'Economy',
  [CAR_TYPE.CAR_TYPE_VAN]: 'Economy large',
  [CAR_TYPE.CAR_TYPE_BUSINESS_VAN]: 'Premium large',
  [CAR_TYPE.CAR_TYPE_BLACK_SEDAN]: 'Premium',
  [CAR_TYPE.CAR_TYPE_TUKTUK]: 'TUKTUK',
  [CAR_TYPE.CAR_TYPE_BIKE]: 'BIKE',
  [CAR_TYPE.CAR_TYPE_REMORQUE]: 'Remorque',
  [CAR_TYPE.CAR_TYPE_PREMIUM_BIKE]: 'Premium Bike',
  [CAR_TYPE.CAR_TYPE_WIN_BIKE]: 'Win Bike',
  [CAR_TYPE.CAR_TYPE_MINI_BUS]: 'Mini Bus',
  [CAR_TYPE.CAR_TYPE_BUS]: 'Bus',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD]: 'STANDARD TAXI',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE]: 'STANDARD LARGE TAXI',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM]: 'PREMIUM TAXI',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE]: 'PREMIUM LARGE TAXI',
  [CAR_TYPE.CAR_TYPE_DELIVERY_VAN]: 'DELIVERY VAN',
  [CAR_TYPE.CAR_TYPE_DELIVERY_BIKE]: 'DELIVERY BIKE',
  [CAR_TYPE.CAR_TYPE_DELIVERY_CAR]: 'DELIVERY CAR',
  [CAR_TYPE.CAR_TYPE_ONION_TUKTUK]: 'Onion T1',
  // Deprecated Car Type
  [CAR_TYPE.CAR_TYPE_MIDDLE_SEDAN]: 'Middle Sedan (Deprecated)',
  [CAR_TYPE.CAR_TYPE_BUSINESS_SEDAN]: 'Business Sedan (Deprecated)',
  [CAR_TYPE.CAR_TYPE_BUSINESS_BUS]: 'Business Bus (Deprecated)',
  [CAR_TYPE.CAR_TYPE_SUV]: 'SUV (Deprecated)',
  [CAR_TYPE.CAR_TYPE_COMPACT_SUV]: 'Compact SUV (Deprecated)',
  [CAR_TYPE.CAR_TYPE_FOOT]: 'Foot (Deprecated)',
};

export const MISSION_TYPE_STRINGS: Record<MissionType, string> = {
  [MISSION_TYPE.TOTAL_RIDES]: 'Finished rides',
  [MISSION_TYPE.TOTAL_EARNING]: 'Total Earning',
  [MISSION_TYPE.TOTAL_SYSTEM_FEE]: 'Total System fee',
  [MISSION_TYPE.STAMP]: 'Stamp',
};

export const MISSION_TARGET_TYPE_STRINGS: Record<MissionTargetType, string> = {
  [MISSION_TARGET_TYPE.PRIVATE]: 'Upload',
  [MISSION_TARGET_TYPE.PUBLIC]: 'All',
  [MISSION_TARGET_TYPE.SIGN_UP]: 'Sign Up',
};

export const CREDIT_CARD_BRAND_STRING = {
  [CREDIT_CARD_BRAND.VISA]: 'Visa',
  [CREDIT_CARD_BRAND.MASTER_CARD]: 'Master Card',
  [CREDIT_CARD_BRAND.AMERICAN_EXPRESS]: 'American Express',
  [CREDIT_CARD_BRAND.DINERS_CLUB]: 'Diners Club',
  [CREDIT_CARD_BRAND.UNION_PAY]: 'Union Pay',
  [CREDIT_CARD_BRAND.JCB]: 'JCB',
  [CREDIT_CARD_BRAND.NONE]: 'None',
};

export const SINGPASS_DRIVER_LICENSE_VALID_TYPE_STRINGS: Record<SingpassDriverLicenseValidType, string> = {
  [SINGPASS_DRIVER_LICENSE_VALID_TYPE.VALID]: 'Valid',
  [SINGPASS_DRIVER_LICENSE_VALID_TYPE.EXPIRED]: 'Expired',
  [SINGPASS_DRIVER_LICENSE_VALID_TYPE.INVALID]: 'Invalid',
  [SINGPASS_DRIVER_LICENSE_VALID_TYPE.NOT_HOLDING_QDL]: 'Not Holding QDL',
};

export const SINGPASS_LTA_LICENSE_TYPE_STRINGS: Record<SingpassLTALicenseStatusType, string> = {
  [SINGPASS_LTA_LICENSE_STATUS_TYPE.ACTIVE]: 'Active',
};

export const MISSION_BACKGROUND_PROGRESS_TYPE_STRINGS: Record<MissionBackgroundProgressType, string> = {
  [MISSION_BACKGROUND_PROGRESS_TYPE.ADD_PARTICIPANTS]: 'Add Participants',
  [MISSION_BACKGROUND_PROGRESS_TYPE.REPLACES_PARTICIPANTS]: 'Replace Participants',
  [MISSION_BACKGROUND_PROGRESS_TYPE.EXCLUDE_PARTICIPANTS]: 'Exclude Participants',
};

export const MISSION_BACKGROUND_PROGRESS_STATUS_STRINGS: Record<MissionBackgroundProgressStatus, string> = {
  [MISSION_BACKGROUND_PROGRESS_STATUS.IN_PROGRESS]: 'In Progress',
  [MISSION_BACKGROUND_PROGRESS_STATUS.FINISHED]: 'Finished',
  [MISSION_BACKGROUND_PROGRESS_STATUS.ERROR]: 'Error',
};

export const INTERNAL_DOCUMENT_PROGRESS_STATUS_STRINGS: Record<InternalDocumentProgressStatusType, string> = {
  [INTERNAL_DOCUMENT_PROGRESS_STATUS.PENDING]: 'Pending',
  [INTERNAL_DOCUMENT_PROGRESS_STATUS.IN_PROGRESS]: 'In Progress',
  [INTERNAL_DOCUMENT_PROGRESS_STATUS.FINISHED]: 'Finished',
};

export const BANNER_DESCRIPTION_COLOR_STRINGS: Record<BannerDescriptionColorType, string> = {
  [BANNER_DESCRIPTION_COLOR.BLACK]: 'Black',
  [BANNER_DESCRIPTION_COLOR.WHITE]: 'White',
};

export const APP_AUTHORITY_STRINGS: Record<AppAuthority, string> = {
  [APP_AUTHORITY.DRIVER_APP]: 'Driver App',
  [APP_AUTHORITY.RIDER_APP]: 'Rider App',
  [APP_AUTHORITY.WEB_HOMEPAGE]: 'Web Homepage',
};

export const TAXI_TYPE_STRING: Record<TaxiType, string> = {
  [TAXI_TYPE.URBAN_TAXI]: 'Urban Taxi',
  [TAXI_TYPE.NEW_TERRITORIES_TAXI]: 'New Territories taxi',
  [TAXI_TYPE.LANTAU_TAXI]: 'Lantau Taxi',
};

export const SG_TRANSACTION_CATEGORY_TYPE_STRINGS: Record<SGTransactionCategoryType, string> = {
  [SG_TRANSACTION_CATEGORY_TYPE.INCENTIVES]: 'Incentive (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.NON_PAID_TRIP_COMPENSATION]: 'Compensation for non-paid trips (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.FUNDED_SUBSIDY]: 'Funded subsidy (like govt relief fund or premier program for SG)',
  [SG_TRANSACTION_CATEGORY_TYPE.PAY_RIDER_FEE]: 'Pay Rider Fee',
  [SG_TRANSACTION_CATEGORY_TYPE.BALANCE_TRANSFER]: 'Adjustment to Balance Only',
  [SG_TRANSACTION_CATEGORY_TYPE.TIPS]: 'Tips',
  [SG_TRANSACTION_CATEGORY_TYPE.ERP]: 'ERP',
  [SG_TRANSACTION_CATEGORY_TYPE.CANCELLATION]: 'Cancellation Fee',
  [SG_TRANSACTION_CATEGORY_TYPE.ADDITIONAL_FARES]: 'Additional Fares (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.DELIVERY_COD]: 'Delivery COD',
  [SG_TRANSACTION_CATEGORY_TYPE.DELIVERY_PAYMENT]: 'Delivery Payment',
  [SG_TRANSACTION_CATEGORY_TYPE.WAITING_TIME_FEE_CPF]: 'Waiting Time Fee (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.INSENTIVES_CPF]: 'Incentive (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.ADDITIONAL_FARES_CPF]: 'Additional Fares (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.NON_PAID_TRIP_COMPENSATION_CPF]: 'Compensation for non-paid trips (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.FARE_ADJUSTMENT_CPF]: 'Fare Adjustment (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.TIPS_CPF]: 'Tips (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.CANCELLATION_COMPENSATION]: 'Cancellation Compensation',
  [SG_TRANSACTION_CATEGORY_TYPE.FARE_ADJUSTMENT]: 'Fare Adjustment',
  [SG_TRANSACTION_CATEGORY_TYPE.GANTRY_FEES]: 'Gantry Fees',
  [SG_TRANSACTION_CATEGORY_TYPE.CLEANING_FEE]: 'Cleaning Fee',
  [SG_TRANSACTION_CATEGORY_TYPE.ADMIN_FEE]: 'Admin Fee',
  [SG_TRANSACTION_CATEGORY_TYPE.REFERRAL_REWARD]: 'Referral Reward',
  [SG_TRANSACTION_CATEGORY_TYPE.RIDE_CPF]: 'Ride (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.ONION_LOAN_REPAYMENT]: 'Loan Repayment (Onion)',
  [SG_TRANSACTION_CATEGORY_TYPE.CPF_CONTRIBUTION]: 'CPF Contribution',
  [SG_TRANSACTION_CATEGORY_TYPE.REVERSAL]: 'Reversal',
  [SG_TRANSACTION_CATEGORY_TYPE.OTHERS_CPF]: 'Others (CPF)',
  [SG_TRANSACTION_CATEGORY_TYPE.OTHERS]: 'Others',
};

export const ADDITIONAL_FARE_TYPE_STRINGS = {
  [ADDITIONAL_FARE_TYPE.TOLL_FEE]: 'Toll Fee',
  [ADDITIONAL_FARE_TYPE.ADDITIONAL_STOP]: 'Additional Stop',
  [ADDITIONAL_FARE_TYPE.CAR_SEAT]: 'Car Seat',
  [ADDITIONAL_FARE_TYPE.BOOKING_FEE]: 'Booking Fee',
  [ADDITIONAL_FARE_TYPE.AIRPORT_FEE]: 'Airport Fee',
  [ADDITIONAL_FARE_TYPE.WAITING_FEE]: 'Waiting Fee',
  [ADDITIONAL_FARE_TYPE.BOOSTER_SEAT]: 'Booster Seat',
  [ADDITIONAL_FARE_TYPE.CHANGE_OF_DESTINATION]: 'Change of Destination',
  [ADDITIONAL_FARE_TYPE.HARBOUR_CROSSING_FEE]: 'Harbour Crossing Fee',
  [ADDITIONAL_FARE_TYPE.GANTRY_FEE]: 'Gantry Fee',
  [ADDITIONAL_FARE_TYPE.ETC]: 'Etc',
  [ADDITIONAL_FARE_TYPE.NONE]: 'None',
};

export const TRANSLATE_ADDITIONAL_FARE_TYPE_STRINGS = {
  [ADDITIONAL_FARE_TYPE.TOLL_FEE]: 'constants.additional_fare_type_toll_fee',
  [ADDITIONAL_FARE_TYPE.ADDITIONAL_STOP]: 'constants.additional_fare_type_additional_stop',
  [ADDITIONAL_FARE_TYPE.CAR_SEAT]: 'constants.additional_fare_type_baby_seat',
  [ADDITIONAL_FARE_TYPE.BOOKING_FEE]: 'constants.additional_fare_type_booking_fee',
  [ADDITIONAL_FARE_TYPE.AIRPORT_FEE]: 'constants.additional_fare_type_airport_fee',
  [ADDITIONAL_FARE_TYPE.WAITING_FEE]: 'constants.additional_fare_type_waiting_fee',
  [ADDITIONAL_FARE_TYPE.BOOSTER_SEAT]: 'constants.additional_fare_type_booster_seat',
  [ADDITIONAL_FARE_TYPE.CHANGE_OF_DESTINATION]: 'constants.additional_fare_type_change_of_destination',
  [ADDITIONAL_FARE_TYPE.HARBOUR_CROSSING_FEE]: 'constants.additional_fare_type_harbour_crossing_fee',
  [ADDITIONAL_FARE_TYPE.GANTRY_FEE]: 'constants.additional_fare_type_gantry_fee',
  [ADDITIONAL_FARE_TYPE.ETC]: 'constants.additional_fare_type_etc',
  [ADDITIONAL_FARE_TYPE.NONE]: 'constants.additional_fare_type_none',
};

export const RESTRICT_TYPE_AND_ALLOWANCE_TYPE_STRING: Record<RestrictTypeAndAllowanceType, string> = {
  [RESTRICT_TYPE_AND_ALLOWANCE_TYPE.BOTH]: 'Both',
  [RESTRICT_TYPE_AND_ALLOWANCE_TYPE.PICKUP_ONLY]: 'Pickup Only',
  [RESTRICT_TYPE_AND_ALLOWANCE_TYPE.DROPOFF_ONLY]: 'Dropoff Only',
};

export const TRANSLATE_DISCLAIMER_TYPE_STRING = {
  [DISCLAIMER_TYPE.NO_WAITING]: 'constants.no_waiting',
};

export const BANK_NAME_STRINGS: Record<string, string> = {
  // HK
  [BANK_NAME_HK.STANCHART]: 'STANDARD CHARTERED BANK (HONG KONG) LIMITED',
  [BANK_NAME_HK.HSBC]: 'THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC)',
  [BANK_NAME_HK.CCBC]: 'CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED',
  [BANK_NAME_HK.BOC]: 'BANK OF CHINA (HONG KONG) LIMITED',
  [BANK_NAME_HK.BEA]: 'THE BANK OF EAST ASIA, LIMITED',
  [BANK_NAME_HK.DBS]: 'DBS BANK (HONG KONG) LIMITED',
  [BANK_NAME_HK.HSB]: 'HANG SENG BANK LTD.',
  [BANK_NAME_HK.SCB]: 'SHANGHAI COMMERCIAL BANK LTD.',
  [BANK_NAME_HK.OCBC]: 'OCBC WING HANG BANK LIMITED',
  [BANK_NAME_HK.DAHSING]: 'DAH SING BANK, LTD.',
  [BANK_NAME_HK.CHBANK]: 'CHONG HING BANK LIMITED',
  [BANK_NAME_HK.NCB]: 'NANYANG COMMERCIAL BANK, LTD.',
  [BANK_NAME_HK.ICBC]: 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED',
  [BANK_NAME_HK.CITI]: 'CITIBANK (HONG KONG) LIMITED',
  [BANK_NAME_HK.BCCL]: 'BANK OF COMMUNICATIONS (HONG KONG) LIMITED',
  [BANK_NAME_HK.MOX]: 'MOX BANK LIMITED',
  [BANK_NAME_HK.CITIC]: 'CHINA CITIC BANK INTERNATIONAL LIMITED',
  [BANK_NAME_HK.WLB]: 'CMB WING LUNG BANK LIMITED',
  [BANK_NAME_HK.FUBON]: 'FUBON BANK (HONG KONG) LIMITED',
};
