import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { LoginPageState } from '@/pages/LoginPage/types';
import { changePageStatusSuccess } from '@/svc/admin-proxy-svc/reducer';
import { User } from '@/types';
import { getAccessToken, getAvailableRegion, getRefreshToken, getUserData, saveRefreshTime, saveUserData } from '@/utils/localStorage';
import { sanitizePermission } from '@/utils/permissionUtils';
import { unregisterServiceWorker } from '@/utils/serviceWorker';

const userData = getUserData();

export const initialState: LoginPageState = {
  isAuthenticated: !!getAccessToken() && !!getRefreshToken(),
  isFetchMe: false,
  availableRegion: getAvailableRegion() ?? [],
  username: userData?.name ?? '',
  permissions: userData?.permissions || {},
  regions: userData?.regions ?? [],
  super: userData?.super ?? false,
  metaData: userData?.metaData ?? {
    createTransactionLimits: null,
    createTransactionLimitsByRole: null,
    createTransactionWeeklyLimits: null,
    createTransactionWeeklyLimitsByRole: null,
  },
  transactionLimitData: '',
  pageStatus: null,
};

export const postPushSubscriptionRequest = createAction<any>('login/postPushSubscriptionRequest');
export const postPushSubscriptionSuccess = createAction('login/postPushSubscriptionSuccess');
export const postPushSubscriptionFailure = createAction<string>('login/postPushSubscriptionFailure');

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginRequest: {
      prepare: (payload: User) => ({ payload }),
      reducer: (state) => {
        state.isAuthenticated = false;
      },
    },
    loginSuccess(state) {
      state.isAuthenticated = true;
    },
    loginFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isAuthenticated = false;
      },
    },
    fetchMeRequest(state) {
      state.isFetchMe = true;
    },
    fetchMeSuccess(state, { payload }) {
      saveUserData(payload);
      saveRefreshTime();

      state.permissions = sanitizePermission(
        payload.permissions,
        payload.super || payload.roles.some(({ authority }: { authority: string | null }) => authority === 'ROLE_SUPER_ADMIN')
      );
      state.username = payload.name;
      state.super = payload.super;
      state.regions = payload.regions;
      state.availableRegion = payload.regions;
      state.metaData = payload.metaData;
      state.transactionLimitData = payload.transactionLimitData;
      state.pageStatus = payload.pageStatus;
      state.isFetchMe = false;
    },
    fetchMeFailure: {
      prepare: (payload: string) => ({ payload }),
      reducer: (state) => {
        state.isFetchMe = false;
      },
    },
    logout(state) {
      localStorage.clear();
      unregisterServiceWorker();

      state.isAuthenticated = false;
      state.username = '';
      state.availableRegion = [];
    },
    setAvailableRegionsRequest(state, { payload }: PayloadAction<string[]>) {
      state.availableRegion = payload;
    },
  },
  extraReducers: {
    [changePageStatusSuccess.type]: (state, { payload: { pageStatus } }: ReturnType<typeof changePageStatusSuccess>) => {
      state.pageStatus = pageStatus;
    },
  },
});

export const { loginRequest, loginSuccess, loginFailure, fetchMeRequest, fetchMeSuccess, fetchMeFailure, logout, setAvailableRegionsRequest } =
  loginSlice.actions;

export default loginSlice.reducer;
