// @ts-ignore no-consecutive-blank-lines

import {
  ADDITIONAL_FARE_TYPE,
  AVAILABLE_PAYMENT_METHOD_STRING,
  CAR_TYPE,
  FAIL_REASON,
  MISSION_TYPE,
  PASSWORD_CRITERIA,
  REGION,
} from '@/assets/constants/enum';
import { PATH } from '@/assets/constants/path';
import { AdditionalFareType, CarType, MissionType, PasswordCriteriaType, SGTransactionCategoryType } from '@/assets/constants/type';
import regex from '@/assets/regex';
import { valueOf } from '@/types';
import { removeDeprecatedType } from '@/utils/types';

export * from '@/assets/constants/enum';
export * from '@/assets/constants/all';
export * from '@/assets/constants/column';
export * from '@/assets/constants/corporate';
export * from '@/assets/constants/delivery';
export * from '@/assets/constants/env';
export * from '@/assets/constants/path';
export * from '@/assets/constants/permission';
export * from '@/assets/constants/schema';
export * from '@/assets/constants/string';
export * from '@/assets/constants/type';

export enum DEFAULT_VALUE {
  NUMBER = -999,
  STRING = '',
}

export const AVAILABLE_DRIVER_AGE: Partial<Record<REGION, number>> = {
  [REGION.SG]: 20,
  [REGION.KH]: 18,
};

export const SERVICE_REGIONS = [REGION.SG, REGION.VN, REGION.KH, REGION.TH, REGION.HK];

/* //region */

/* city */
export enum CITY {
  SINGAPORE = 'SIN',
  HOCHIMINH = 'HCM',
  HANOI = 'HAN',
  PHNOMPENH = 'PNH',
  SIEMREAP = 'REP',
  SIHANOUK_VILLE = 'SHV',
  KPK = 'KPK',
  SEOUL = 'SEL',
  ADDIS_ABABA = 'ADD',
  JAKARTA = 'JKT',
  HONGKONG = 'HKG',
  BANGKOK = 'BKK',
  PBM = 'PBM',
  BTB = 'BTB',
  GLOBAL = 'GLOBAL',
}

export const CITY_SINGAPORE = 'SIN';
export const CITY_HOCHIMINH = 'HCM';
export const CITY_HANOI = 'HAN';
export const CITY_PHNOMPENH = 'PNH';
export const CITY_BANGKOK = 'BKK';
export const CITY_SIEMREAP = 'REP';
export const CITY_SEOUL = 'SEL';
export const CITY_ADDIS_ABABA = 'ADD';

export const ALL_CITIES = Object.values(CITY);

export const CITY_STRINGS: Record<string, string> = {
  [CITY.SINGAPORE]: 'Singapore',
  [CITY.HOCHIMINH]: 'Hochiminh',
  [CITY.HANOI]: 'Hanoi',
  [CITY.PHNOMPENH]: 'Phnompenh',
  [CITY.SIEMREAP]: 'Siem Reap',
  [CITY.SIHANOUK_VILLE]: 'Sihanouk Ville',
  [CITY.KPK]: 'Kampot & Kep city',
  [CITY.SEOUL]: 'Seoul',
  [CITY.ADDIS_ABABA]: 'Addis Ababa',
  [CITY.JAKARTA]: 'Jakarta',
  [CITY.HONGKONG]: 'Hong Kong',
  [CITY.BANGKOK]: 'Bangkok',
  [CITY.PBM]: 'Poi Pet & Banteay Meanchey',
  [CITY.BTB]: 'Battambang',
  [CITY.GLOBAL]: 'Global',
};

export const TRANSLATE_CITY_STRINGS: Record<string, string> = {
  [CITY.SINGAPORE]: 'constants.city.singapore',
  [CITY.HOCHIMINH]: 'constants.city.hochiminh',
  [CITY.HANOI]: 'constants.city.hanoi',
  [CITY.PHNOMPENH]: 'constants.city.phnompenh',
  [CITY.SIEMREAP]: 'constants.city.siemreap',
  [CITY.SIHANOUK_VILLE]: 'constants.city.sihanouk_ville',
  [CITY.KPK]: 'constants.city.kampot_and_kep',
  [CITY.SEOUL]: 'constants.city.seoul',
  [CITY.ADDIS_ABABA]: 'constants.city.addis_ababa',
  [CITY.JAKARTA]: 'constants.city.jakarta',
  [CITY.HONGKONG]: 'constants.city.hong_kong',
  [CITY.BANGKOK]: 'constants.city.bangkok',
  [CITY.PBM]: 'constants.city.poi_pet_and_banteay_meanchey',
  [CITY.BTB]: 'constants.city.battambang',
  [CITY.GLOBAL]: 'constants.city.global',
};
/* //city */

/* currency */
export enum Currency {
  SGD = 'SGD', // Singapore
  USD = 'USD', // United States
  KHR = 'KHR', // Cambodia
  VND = 'VND', // Vietnam
  KRW = 'KRW', // Republic of Korea
  ETB = 'ETB', // Ethiopia
  IDR = 'IDR', // Indonesia
  HKD = 'HKD', // Hongkong
  THB = 'THB',
}

export const ALL_CURRENCIES = Object.values(Currency);

export const CURRENCIES_FROM_REGION: Record<REGION | string, Currency[]> = {
  [REGION.SG]: [Currency.SGD],
  [REGION.VN]: [Currency.VND],
  [REGION.KH]: [Currency.KHR, Currency.USD],
  [REGION.KR]: [Currency.KRW],
  [REGION.ET]: [Currency.ETB],
  [REGION.ID]: [Currency.IDR],
  [REGION.HK]: [Currency.HKD],
  [REGION.TH]: [Currency.THB],
  [REGION.GLOBAL]: ALL_CURRENCIES,
};

export const REGION_BY_CURRENCY: Record<Currency | string, REGION> = {
  [Currency.SGD]: REGION.SG,
  [Currency.USD]: REGION.KH,
  [Currency.KHR]: REGION.KH,
  [Currency.VND]: REGION.VN,
  [Currency.KRW]: REGION.KR,
  [Currency.ETB]: REGION.ET,
  [Currency.IDR]: REGION.ID,
  [Currency.HKD]: REGION.HK,
  [Currency.THB]: REGION.TH,
};

export const AVAILABLE_CURRENCIES = Array.from(new Set(SERVICE_REGIONS.map((region) => CURRENCIES_FROM_REGION[region]).flat()));
/* //currency */

/* timezone */
export const TIMEZONE: Record<string | REGION, string> = {
  SG: '+08:00',
  VN: '+07:00',
  KH: '+07:00',
  KR: '+09:00',
  TH: '+07:00',
  HK: '+08:00',
};

export const TIMEZONE_OFFSET: Record<string, number> = {
  SG: 8,
  VN: 7,
  KH: 7,
  KR: 9,
  TH: 7,
  HK: 8,
};
/* //timezone */

/* ride status */
export const RENTAL_STATUS_WAITPAY = 0;
export const RENTAL_STATUS_NOT_MATCHED = 10;
export const RENTAL_STATUS_MATCHED = 12;
export const RENTAL_STATUS_CONFIRMED = 15;
export const RENTAL_STATUS_PENDING = 20;
export const RENTAL_STATUS_ASSIGNED = 40;
export const RENTAL_STATUS_PICKUP = 50;
export const RENTAL_STATUS_PICKUP_ARRIVED = 55;
export const RENTAL_STATUS_INUSE = 60;
export const RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING = 65;
export const RENTAL_STATUS_FINISHED = 70;
export const RENTAL_STATUS_USER_CANCELED = 100;
export const RENTAL_STATUS_COMPANY_CANCELED = 300;
export const RENTAL_STATUS_DRIVER_CANCELED = 310;
export const RENTAL_STATUS_ACCIDENT_CANCELED = 320;
export const RENTAL_STATUS_EXPIRED = 400;
export const RENTAL_STATUS_ERROR_PAYMENT = 900;
export const RENTAL_STATUS_ERROR = 999;
export const RENTAL_STATUS_STRINGS: Record<number, string> = {
  [RENTAL_STATUS_WAITPAY]: 'WAITPAY',
  [RENTAL_STATUS_NOT_MATCHED]: 'NOT_MATCHED',
  [RENTAL_STATUS_MATCHED]: 'MATCHED',
  [RENTAL_STATUS_CONFIRMED]: 'CONFIRMED',
  [RENTAL_STATUS_PENDING]: 'PENDING',
  [RENTAL_STATUS_ASSIGNED]: 'ASSIGNED',
  [RENTAL_STATUS_PICKUP]: 'PICKUP',
  [RENTAL_STATUS_PICKUP_ARRIVED]: 'PICKUP_ARRIVED',
  [RENTAL_STATUS_INUSE]: 'INUSE',
  [RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING]: 'INUSE WITH CANCEL PENDING',
  [RENTAL_STATUS_FINISHED]: 'FINISHED',
  [RENTAL_STATUS_USER_CANCELED]: 'USER_CANCELED',
  [RENTAL_STATUS_COMPANY_CANCELED]: 'COMPANY_CANCELED',
  [RENTAL_STATUS_DRIVER_CANCELED]: 'DRIVER_CANCELED',
  [RENTAL_STATUS_ACCIDENT_CANCELED]: 'ACCIDENT_CANCELED',
  [RENTAL_STATUS_EXPIRED]: 'EXPIRED',
  [RENTAL_STATUS_ERROR_PAYMENT]: 'ERROR_PAYMENT',
  [RENTAL_STATUS_ERROR]: 'ERROR',
};

export const AVAILABLE_RECREATE_AND_BOOK_RIDER_STATUS = [
  RENTAL_STATUS_FINISHED,
  RENTAL_STATUS_USER_CANCELED,
  RENTAL_STATUS_DRIVER_CANCELED,
  RENTAL_STATUS_COMPANY_CANCELED,
  RENTAL_STATUS_EXPIRED,
];

export const TRANSLATE_RENTAL_STATUS_STRINGS: Record<number, string> = {
  [RENTAL_STATUS_WAITPAY]: 'constants.rental_status_waitpay',
  [RENTAL_STATUS_NOT_MATCHED]: 'constants.rental_status_not_matched',
  [RENTAL_STATUS_MATCHED]: 'constants.rental_status_matched',
  [RENTAL_STATUS_CONFIRMED]: 'constants.rental_status_confirmed',
  [RENTAL_STATUS_PENDING]: 'constants.rental_status_pending',
  [RENTAL_STATUS_ASSIGNED]: 'constants.rental_status_assigned',
  [RENTAL_STATUS_PICKUP]: 'constants.rental_status_pickup',
  [RENTAL_STATUS_PICKUP_ARRIVED]: 'constants.rental_status_pickup_arrived',
  [RENTAL_STATUS_INUSE]: 'constants.rental_status_inuse',
  [RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING]: 'constants.rental_status_inuse_with_cancel_pending',
  [RENTAL_STATUS_FINISHED]: 'constants.rental_status_finished',
  [RENTAL_STATUS_USER_CANCELED]: 'constants.rental_status_user_canceled',
  [RENTAL_STATUS_COMPANY_CANCELED]: 'constants.rental_status_company_canceled',
  [RENTAL_STATUS_DRIVER_CANCELED]: 'constants.rental_status_driver_canceled',
  [RENTAL_STATUS_ACCIDENT_CANCELED]: 'constants.rental_status_accident_canceled',
  [RENTAL_STATUS_EXPIRED]: 'constants.rental_status_expired',
  [RENTAL_STATUS_ERROR_PAYMENT]: 'constants.rental_status_error_payment',
  [RENTAL_STATUS_ERROR]: 'constants.rental_status_error',
};

export const RIDE_STATUS = [
  RENTAL_STATUS_PENDING,
  RENTAL_STATUS_NOT_MATCHED,
  RENTAL_STATUS_MATCHED,
  RENTAL_STATUS_CONFIRMED,
  RENTAL_STATUS_ASSIGNED,
  RENTAL_STATUS_PICKUP,
  RENTAL_STATUS_PICKUP_ARRIVED,
  RENTAL_STATUS_INUSE,
  RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING,
  RENTAL_STATUS_FINISHED,
  RENTAL_STATUS_USER_CANCELED,
  RENTAL_STATUS_COMPANY_CANCELED,
  RENTAL_STATUS_DRIVER_CANCELED,
  RENTAL_STATUS_ACCIDENT_CANCELED,
  RENTAL_STATUS_EXPIRED,
];

export enum RIDE_STATUS_ENUM {
  RENTAL_STATUS_NOT_MATCHED = 10,
  RENTAL_STATUS_MATCHED = 12,
  RENTAL_STATUS_CONFIRMED = 15,
  RENTAL_STATUS_PENDING = 20,
  RENTAL_STATUS_ASSIGNED = 40,
  RENTAL_STATUS_PICKUP = 50,
  RENTAL_STATUS_PICKUP_ARRIVED = 55,
  RENTAL_STATUS_INUSE = 60,
  RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING = 65,
  RENTAL_STATUS_FINISHED = 70,
  RENTAL_STATUS_USER_CANCELED = 100,
  RENTAL_STATUS_COMPANY_CANCELED = 300,
  RENTAL_STATUS_DRIVER_CANCELED = 310,
  RENTAL_STATUS_ACCIDENT_CANCELED = 320,
  RENTAL_STATUS_EXPIRED = 400,
}

export const RIDE_STATUS_STRING: Record<RIDE_STATUS_ENUM, string> = {
  [RIDE_STATUS_ENUM.RENTAL_STATUS_NOT_MATCHED]: 'NOT_MATCHED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_MATCHED]: 'MATCHED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_CONFIRMED]: 'CONFIRMED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_PENDING]: 'PENDING',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_ASSIGNED]: 'ASSIGNED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_PICKUP]: 'PICKUP',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_PICKUP_ARRIVED]: 'PICKUP_ARRIVED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_INUSE]: 'INUSE',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_INUSE_WITH_CANCEL_PENDING]: 'INUSE_WITH_CANCEL_PENDING',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_FINISHED]: 'FINISHED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_USER_CANCELED]: 'USER_CANCELED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_COMPANY_CANCELED]: 'COMPANY_CANCELED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_DRIVER_CANCELED]: 'DRIVER_CANCELED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_ACCIDENT_CANCELED]: 'ACCIDENT_CANCELED',
  [RIDE_STATUS_ENUM.RENTAL_STATUS_EXPIRED]: 'EXPIRED',
};

export const RENTAL_TYPE_RENTAL = 0;
export const RENTAL_TYPE_PURCHASE = 1;
export const RENTAL_TYPE_HAILING = 2;
export const RENTAL_TYPE_STRINGS = {
  [RENTAL_TYPE_RENTAL]: 'RENTAL',
  [RENTAL_TYPE_PURCHASE]: 'PURCHASE',
  [RENTAL_TYPE_HAILING]: 'HAILING',
};

export const VISIBLE_RENTAL_TYPE_STRINGS = {
  [RENTAL_TYPE_HAILING]: 'HAILING',
  [RENTAL_TYPE_RENTAL]: 'RENTAL',
};
/* //ride status */

/* payment method */
export const PAYMENT_METHOD_CREDITCARD = 'CREDITCARD';
export const PAYMENT_METHOD_CASH = 'CASH';
export const PAYMENT_METHOD_ALIPAY = 'ALIPAY';
export const PAYMENT_METHOD_PIPAY = 'PIPAY';
export const PAYMENT_METHOD_PAO = 'PAO';
export const PAYMENT_METHOD_ALIPAY_P = 'ALIPAY_P';
export const PAYMENT_METHOD_PIPAY_P = 'PIPAY_P';
export const PAYMENT_METHOD_MOMOPAY_P = 'MOMO_P';
export const PAYMENT_METHOD_CMCB = 'CMCB';
export const PAYMENT_METHOD_TADAPAY = 'TADAPAY';
export const PAYMENT_METHOD_DEPOSIT = 'DEPOSIT';
export const PAYMENT_METHOD_WEIXIN = 'WX';
export const PAYMENT_METHOD_CORPORATE = 'CORPORATE';
export const PAYMENT_METHOD_DRIVER_COLLECT_CASH = 'DRIVER_COLLECT_CASH';
export const PAYMENT_METHOD_WING = 'WING';
export const PAYMENT_METHOD_NETSQR = 'NETSQR';
export const PAYMENT_METHOD_OCBC_PAO = 'OCBC_PAO';
export const PAYMENT_METHOD_DELIVERY = 'DELIVERY';
export const PAYMENT_METHOD_ABA_AOF = 'ABA_AOF';
export const PAYMENT_METHOD_CORPORATE_CARD = 'CORPORATE_CARD';
export const ALL_PAYMENT_REQUEST_METHOD = [PAYMENT_METHOD_DRIVER_COLLECT_CASH, PAYMENT_METHOD_CREDITCARD];

export const ALL_PAYMENT_METHOD = [
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_ALIPAY_P,
  PAYMENT_METHOD_PIPAY_P,
  PAYMENT_METHOD_PAO,
  PAYMENT_METHOD_CMCB,
  PAYMENT_METHOD_WING,
  PAYMENT_METHOD_CREDITCARD,
  PAYMENT_METHOD_CORPORATE,
  PAYMENT_METHOD_TADAPAY,
  PAYMENT_METHOD_MOMOPAY_P,
  PAYMENT_METHOD_NETSQR,
  PAYMENT_METHOD_OCBC_PAO,
  PAYMENT_METHOD_DELIVERY,
  PAYMENT_METHOD_ABA_AOF,
  PAYMENT_METHOD_CORPORATE_CARD,
];

export const ALL_PAYMENT_METHOD_WITH_LEGACY = [
  PAYMENT_METHOD_CREDITCARD,
  PAYMENT_METHOD_CASH,
  PAYMENT_METHOD_ALIPAY,
  PAYMENT_METHOD_PIPAY,
  PAYMENT_METHOD_ALIPAY_P,
  PAYMENT_METHOD_PAO,
  PAYMENT_METHOD_PIPAY_P,
  PAYMENT_METHOD_MOMOPAY_P,
  PAYMENT_METHOD_CMCB,
  PAYMENT_METHOD_TADAPAY,
  PAYMENT_METHOD_CORPORATE,
  PAYMENT_METHOD_WING,
  PAYMENT_METHOD_NETSQR,
  PAYMENT_METHOD_OCBC_PAO,
  PAYMENT_METHOD_ABA_AOF,
];

export const PAYMENT_METHOD_STRINGS: Record<string, string> = {
  [PAYMENT_METHOD_CREDITCARD]: 'Credit Card',
  [PAYMENT_METHOD_CASH]: 'Cash',
  [PAYMENT_METHOD_ALIPAY]: 'Alipay',
  [PAYMENT_METHOD_PIPAY]: 'Pipay',
  [PAYMENT_METHOD_PIPAY_P]: 'Pipay Post',
  [PAYMENT_METHOD_ALIPAY_P]: 'Alipay Post',
  [PAYMENT_METHOD_MOMOPAY_P]: 'MoMo pay',
  [PAYMENT_METHOD_PAO]: 'QR Pay Nets (legacy)',
  [PAYMENT_METHOD_CMCB]: 'CMCB',
  [PAYMENT_METHOD_TADAPAY]: 'TADA Wallet',
  [PAYMENT_METHOD_CORPORATE]: 'Corporate',
  [PAYMENT_METHOD_WING]: 'Wing',
  [PAYMENT_METHOD_NETSQR]: 'NETSQR',
  [PAYMENT_METHOD_OCBC_PAO]: 'OCBC Pay Anyone',
  [PAYMENT_METHOD_DELIVERY]: 'Delivery',
  [PAYMENT_METHOD_ABA_AOF]: 'ABA_AOF',
  [PAYMENT_METHOD_CORPORATE_CARD]: 'Corporate Card',
};

export const TRANSLATE_PAYMENT_METHOD_STRINGS: Record<string, string> = {
  [PAYMENT_METHOD_CREDITCARD]: 'constants.payment_method_creditcard',
  [PAYMENT_METHOD_CASH]: 'constants.payment_method_cash',
  [PAYMENT_METHOD_ALIPAY]: 'constants.payment_method_alipay',
  [PAYMENT_METHOD_PIPAY]: 'constants.payment_method_pipay',
  [PAYMENT_METHOD_PIPAY_P]: 'constants.payment_method_pipay_p',
  [PAYMENT_METHOD_ALIPAY_P]: 'constants.payment_method_alipay_p',
  [PAYMENT_METHOD_MOMOPAY_P]: 'constants.payment_method_momo_pay_p',
  [PAYMENT_METHOD_PAO]: 'constants.payment_method_pao',
  [PAYMENT_METHOD_CMCB]: 'constants.payment_method_cmcb',
  [PAYMENT_METHOD_TADAPAY]: 'constants.payment_method_tadapay',
  [PAYMENT_METHOD_CORPORATE]: 'constants.payment_method_corporate',
  [PAYMENT_METHOD_WING]: 'constants.payment_method_wing',
  [PAYMENT_METHOD_NETSQR]: 'constants.payment_method_netsqr',
  [PAYMENT_METHOD_OCBC_PAO]: 'constants.payment_method_ocbc_pao',
  [PAYMENT_METHOD_DELIVERY]: 'constants.payment_method_delivery',
  [PAYMENT_METHOD_ABA_AOF]: 'constants.payment_method_aba_aof',
  [PAYMENT_METHOD_CORPORATE_CARD]: 'constants.payment_method_corporate_card',
};
/* //payment method */

/* available payment method */
export enum AVAILABLE_PAYMENT_METHOD {
  PAYMENT_METHOD_CASH = 0,
  PAYMENT_METHOD_PAO = 1,
  PAYMENT_METHOD_ALIPAY_P = 2,
  PAYMENT_METHOD_PIPAY_P = 3,
  PAYMENT_METHOD_MOMOPAY_P = 4,
  PAYMENT_METHOD_CREDITCARD = 5,
  PAYMENT_METHOD_TADAPAY = 6,
  PAYMENT_METHOD_CORPORATE = 7,
  PAYMENT_METHOD_CMCB = 8,
  PAYMENT_METHOD_DELIVERY = 9,
  PAYMENT_METHOD_NETSQR = 12,
  PAYMENT_METHOD_OCBC_PAO = 13,
  ABA_AOF = 15,
  CORPORATE_CARD = 16,
}

export const AVAILABLE_PAYMENT_METHOD_CASH = 0;
export const AVAILABLE_PAYMENT_METHOD_PAO = 1;
export const AVAILABLE_PAYMENT_METHOD_ALIPAY_P = 2;
export const AVAILABLE_PAYMENT_METHOD_PIPAY_P = 3;
export const AVAILABLE_PAYMENT_METHOD_MOMOPAY_P = 4;
export const AVAILABLE_PAYMENT_METHOD_CREDITCARD = 5;
export const AVAILABLE_PAYMENT_METHOD_TADAPAY = 6;
export const AVAILABLE_PAYMENT_METHOD_CORPORATE = 7;
export const AVAILABLE_PAYMENT_METHOD_CMCB = 8;
export const AVAILABLE_PAYMENT_METHOD_DELIVERY = 9;
export const AVAILABLE_PAYMENT_METHOD_NETSQR = 12;
export const AVAILABLE_PAYMENT_METHOD_OCBC_PAO = 13;

export const AVAILABLE_PAYMENT_METHOD_STRINGS = {
  [AVAILABLE_PAYMENT_METHOD_CASH]: 'Cash',
  [AVAILABLE_PAYMENT_METHOD_STRING.CASH]: 'Cash',
  [AVAILABLE_PAYMENT_METHOD_PAO]: 'QR Pay Nets (legacy)',
  [AVAILABLE_PAYMENT_METHOD_STRING.PAO]: 'QR Pay Nets (legacy)',
  [AVAILABLE_PAYMENT_METHOD_ALIPAY_P]: 'Alipay Post',
  [AVAILABLE_PAYMENT_METHOD_STRING.ALIPAY_P]: 'Alipay Post',
  [AVAILABLE_PAYMENT_METHOD_PIPAY_P]: 'Pipay Post',
  [AVAILABLE_PAYMENT_METHOD_STRING.PIPAY_P]: 'Pipay Post',
  [AVAILABLE_PAYMENT_METHOD_MOMOPAY_P]: 'MoMo pay',
  [AVAILABLE_PAYMENT_METHOD_STRING.MOMOPAY_P]: 'MoMo pay',
  [AVAILABLE_PAYMENT_METHOD_CREDITCARD]: 'Creditcard',
  [AVAILABLE_PAYMENT_METHOD_STRING.CREDITCARD]: 'Creditcard',
  [AVAILABLE_PAYMENT_METHOD_TADAPAY]: 'TADA Wallet',
  [AVAILABLE_PAYMENT_METHOD_STRING.TADAPAY]: 'TADA Wallet',
  [AVAILABLE_PAYMENT_METHOD_CORPORATE]: 'Corporate',
  [AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE]: 'Corporate',
  [AVAILABLE_PAYMENT_METHOD_CMCB]: 'CMCB',
  [AVAILABLE_PAYMENT_METHOD_STRING.CMCB]: 'CMCB',
  [AVAILABLE_PAYMENT_METHOD_DELIVERY]: 'Delivery',
  [AVAILABLE_PAYMENT_METHOD_STRING.DELIVERY]: 'Delivery',
  [AVAILABLE_PAYMENT_METHOD_STRING.ABA]: 'ABA',
  [AVAILABLE_PAYMENT_METHOD_STRING.WING]: 'Wing',
  [AVAILABLE_PAYMENT_METHOD_NETSQR]: 'NETSQR',
  [AVAILABLE_PAYMENT_METHOD_STRING.NETSQR]: 'NETSQR',
  [AVAILABLE_PAYMENT_METHOD_OCBC_PAO]: 'OCBC Pay Anyone',
  [AVAILABLE_PAYMENT_METHOD_STRING.OCBC_PAO]: 'OCBC Pay Anyone',
  [AVAILABLE_PAYMENT_METHOD_STRING.CURRENT_BALANCE]: 'Current Balance',
  [AVAILABLE_PAYMENT_METHOD.ABA_AOF]: 'ABA AOF',
  [AVAILABLE_PAYMENT_METHOD_STRING.ABA_AOF]: 'ABA AOF',
  [AVAILABLE_PAYMENT_METHOD.CORPORATE_CARD]: 'Corporate Card',
  [AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE_CARD]: 'Corporate Card',
};

export const TRANSLATE_AVAILABLE_PAYMENT_METHOD_STRINGS: Record<AVAILABLE_PAYMENT_METHOD_STRING | number, string> = {
  [AVAILABLE_PAYMENT_METHOD_CASH]: 'constants.available_payment_method_cash',
  [AVAILABLE_PAYMENT_METHOD_STRING.CASH]: 'constants.available_payment_method_cash',
  [AVAILABLE_PAYMENT_METHOD_PAO]: 'constants.available_payment_method_pao',
  [AVAILABLE_PAYMENT_METHOD_STRING.PAO]: 'constants.available_payment_method_pao',
  [AVAILABLE_PAYMENT_METHOD_ALIPAY_P]: 'constants.available_payment_method_alipay_p',
  [AVAILABLE_PAYMENT_METHOD_STRING.ALIPAY_P]: 'constants.available_payment_method_alipay_p',
  [AVAILABLE_PAYMENT_METHOD_PIPAY_P]: 'constants.available_payment_method_pipay_p',
  [AVAILABLE_PAYMENT_METHOD_STRING.PIPAY_P]: 'constants.available_payment_method_pipay_p',
  [AVAILABLE_PAYMENT_METHOD_MOMOPAY_P]: 'constants.available_payment_method_momopay_p',
  [AVAILABLE_PAYMENT_METHOD_STRING.MOMOPAY_P]: 'constants.available_payment_method_momopay_p',
  [AVAILABLE_PAYMENT_METHOD_CREDITCARD]: 'constants.available_payment_method_creditcard',
  [AVAILABLE_PAYMENT_METHOD_STRING.CREDITCARD]: 'constants.available_payment_method_creditcard',
  [AVAILABLE_PAYMENT_METHOD_TADAPAY]: 'constants.available_payment_method_tadapay',
  [AVAILABLE_PAYMENT_METHOD_STRING.TADAPAY]: 'constants.available_payment_method_tadapay',
  [AVAILABLE_PAYMENT_METHOD_CORPORATE]: 'constants.available_payment_method_corporate',
  [AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE]: 'constants.available_payment_method_corporate',
  [AVAILABLE_PAYMENT_METHOD_CMCB]: 'constants.available_payment_method_cmcb',
  [AVAILABLE_PAYMENT_METHOD_STRING.CMCB]: 'constants.available_payment_method_cmcb',
  [AVAILABLE_PAYMENT_METHOD_DELIVERY]: 'constants.available_payment_method_delivery',
  [AVAILABLE_PAYMENT_METHOD_STRING.DELIVERY]: 'constants.available_payment_method_delivery',
  [AVAILABLE_PAYMENT_METHOD_STRING.ABA]: 'constants.available_payment_method_aba',
  [AVAILABLE_PAYMENT_METHOD_STRING.WING]: 'constants.available_payment_method_wing',
  [AVAILABLE_PAYMENT_METHOD_NETSQR]: 'constants.available_payment_method_netsqr',
  [AVAILABLE_PAYMENT_METHOD_STRING.NETSQR]: 'constants.available_payment_method_netsqr',
  [AVAILABLE_PAYMENT_METHOD_OCBC_PAO]: 'constants.available_payment_method_ocbc_pao',
  [AVAILABLE_PAYMENT_METHOD_STRING.OCBC_PAO]: 'constants.available_payment_method_ocbc_pao',
  [AVAILABLE_PAYMENT_METHOD_STRING.CURRENT_BALANCE]: 'constants.available_payment_method_current_balance',
  [AVAILABLE_PAYMENT_METHOD.ABA_AOF]: 'constants.available_payment_method_aba_aof',
  [AVAILABLE_PAYMENT_METHOD_STRING.ABA_AOF]: 'constants.available_payment_method_aba_aof',
  [AVAILABLE_PAYMENT_METHOD.CORPORATE_CARD]: 'constants.available_payment_method_corporate_card',
  [AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE_CARD]: 'constants.available_payment_method_corporate_card',
};

export const AVAILABLE_PAYMENT_METHOD_TO_ORIGIN_STRING: Record<number, AVAILABLE_PAYMENT_METHOD_STRING> = {
  [AVAILABLE_PAYMENT_METHOD_CASH]: AVAILABLE_PAYMENT_METHOD_STRING.CASH,
  [AVAILABLE_PAYMENT_METHOD_PAO]: AVAILABLE_PAYMENT_METHOD_STRING.PAO,
  [AVAILABLE_PAYMENT_METHOD_ALIPAY_P]: AVAILABLE_PAYMENT_METHOD_STRING.ALIPAY_P,
  [AVAILABLE_PAYMENT_METHOD_PIPAY_P]: AVAILABLE_PAYMENT_METHOD_STRING.PIPAY_P,
  [AVAILABLE_PAYMENT_METHOD_MOMOPAY_P]: AVAILABLE_PAYMENT_METHOD_STRING.MOMOPAY_P,
  [AVAILABLE_PAYMENT_METHOD_CREDITCARD]: AVAILABLE_PAYMENT_METHOD_STRING.CREDITCARD,
  [AVAILABLE_PAYMENT_METHOD_TADAPAY]: AVAILABLE_PAYMENT_METHOD_STRING.TADAPAY,
  [AVAILABLE_PAYMENT_METHOD_CORPORATE]: AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE,
  [AVAILABLE_PAYMENT_METHOD_CMCB]: AVAILABLE_PAYMENT_METHOD_STRING.CMCB,
  [AVAILABLE_PAYMENT_METHOD_DELIVERY]: AVAILABLE_PAYMENT_METHOD_STRING.DELIVERY,
  [AVAILABLE_PAYMENT_METHOD_NETSQR]: AVAILABLE_PAYMENT_METHOD_STRING.NETSQR,
  [AVAILABLE_PAYMENT_METHOD_OCBC_PAO]: AVAILABLE_PAYMENT_METHOD_STRING.OCBC_PAO,
  [AVAILABLE_PAYMENT_METHOD.ABA_AOF]: AVAILABLE_PAYMENT_METHOD_STRING.ABA_AOF,
  [AVAILABLE_PAYMENT_METHOD.CORPORATE_CARD]: AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE_CARD,
};

export const ALL_AVAILABLE_PAYMENT_METHOD = [
  AVAILABLE_PAYMENT_METHOD_CASH,
  AVAILABLE_PAYMENT_METHOD_PAO,
  AVAILABLE_PAYMENT_METHOD_ALIPAY_P,
  AVAILABLE_PAYMENT_METHOD_PIPAY_P,
  AVAILABLE_PAYMENT_METHOD_MOMOPAY_P,
  AVAILABLE_PAYMENT_METHOD_CREDITCARD,
  AVAILABLE_PAYMENT_METHOD_TADAPAY,
  AVAILABLE_PAYMENT_METHOD_CORPORATE,
  AVAILABLE_PAYMENT_METHOD_CMCB,
  AVAILABLE_PAYMENT_METHOD_DELIVERY,
  AVAILABLE_PAYMENT_METHOD_NETSQR,
  AVAILABLE_PAYMENT_METHOD_OCBC_PAO,
  AVAILABLE_PAYMENT_METHOD.ABA_AOF,
  AVAILABLE_PAYMENT_METHOD.CORPORATE_CARD,
];
/* //available payment method */

/* car types */
export const CAR_TYPE_SEDAN = CAR_TYPE.CAR_TYPE_SEDAN;
export const CAR_TYPE_VAN = CAR_TYPE.CAR_TYPE_VAN;
export const CAR_TYPE_BUSINESS_VAN = CAR_TYPE.CAR_TYPE_BUSINESS_VAN;
export const CAR_TYPE_BLACK_SEDAN = CAR_TYPE.CAR_TYPE_BLACK_SEDAN;
export const CAR_TYPE_TUKTUK = CAR_TYPE.CAR_TYPE_TUKTUK;
export const CAR_TYPE_BIKE = CAR_TYPE.CAR_TYPE_BIKE;
export const CAR_TYPE_MINI_BUS = CAR_TYPE.CAR_TYPE_MINI_BUS;
export const CAR_TYPE_BUS = CAR_TYPE.CAR_TYPE_BUS;
export const CAR_TYPE_TAXI_STANDARD = CAR_TYPE.CAR_TYPE_TAXI_STANDARD;
export const CAR_TYPE_TAXI_STANDARD_LARGE = CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE;
export const CAR_TYPE_TAXI_PREMIUM = CAR_TYPE.CAR_TYPE_TAXI_PREMIUM;
export const CAR_TYPE_TAXI_PREMIUM_LARGE = CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE;
export const CAR_TYPE_DELIVERY_VAN = CAR_TYPE.CAR_TYPE_DELIVERY_VAN;
export const CAR_TYPE_DELIVERY_BIKE = CAR_TYPE.CAR_TYPE_DELIVERY_BIKE;
export const CAR_TYPE_DELIVERY_CAR = CAR_TYPE.CAR_TYPE_DELIVERY_CAR;
export const CAR_TYPE_ONION_TUKTUK = CAR_TYPE.CAR_TYPE_ONION_TUKTUK;
// deprecate car type
export const CAR_TYPE_MIDDLE_SEDAN = CAR_TYPE.CAR_TYPE_MIDDLE_SEDAN;
export const CAR_TYPE_BUSINESS_SEDAN = CAR_TYPE.CAR_TYPE_BUSINESS_SEDAN;
export const CAR_TYPE_BUSINESS_BUS = CAR_TYPE.CAR_TYPE_BUSINESS_BUS;
export const CAR_TYPE_SUV = CAR_TYPE.CAR_TYPE_SUV;
export const CAR_TYPE_COMPACT_SUV = CAR_TYPE.CAR_TYPE_COMPACT_SUV;
export const CAR_TYPE_FOOT = CAR_TYPE.CAR_TYPE_FOOT;

export const CAR_TYPE_CATEGORY = {
  BIKE: 'Bike',
  SEDAN: 'Economy',
  VAN: 'Economy Large',
  TUKTUK: 'TUKTUK',
  ONION_TUKTUK: 'Onion T1',
  BLACK_SEDAN: 'Premium',
  BUSINESS_VAN: 'Premium Large',
  TAXI: 'Taxi',
  ETC: 'ETC',
} as const;

export type CarTypeCategoryType = valueOf<typeof CAR_TYPE_CATEGORY>;

export const SD_DISPATCH_CONFIG_CAR_TYPES = [
  CAR_TYPE_SEDAN,
  CAR_TYPE_VAN,
  CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE_TUKTUK,
  CAR_TYPE_BIKE,
  CAR_TYPE.CAR_TYPE_REMORQUE,
  CAR_TYPE.CAR_TYPE_PREMIUM_BIKE,
  CAR_TYPE.CAR_TYPE_WIN_BIKE,
  CAR_TYPE_MINI_BUS,
  CAR_TYPE_TAXI_STANDARD,
  CAR_TYPE_TAXI_STANDARD_LARGE,
  CAR_TYPE_TAXI_PREMIUM,
  CAR_TYPE_TAXI_PREMIUM_LARGE,
  CAR_TYPE_DELIVERY_VAN,
  CAR_TYPE_DELIVERY_BIKE,
  CAR_TYPE_DELIVERY_CAR,
  CAR_TYPE_ONION_TUKTUK,
];

export const CAR_TYPE_FILTERS = [
  CAR_TYPE_SEDAN,
  CAR_TYPE_VAN,
  CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE_TAXI_STANDARD,
  CAR_TYPE_TAXI_STANDARD_LARGE,
  CAR_TYPE_TAXI_PREMIUM,
  CAR_TYPE_TAXI_PREMIUM_LARGE,
  CAR_TYPE_TUKTUK,
  CAR_TYPE_BIKE,
  CAR_TYPE.CAR_TYPE_REMORQUE,
  CAR_TYPE.CAR_TYPE_PREMIUM_BIKE,
  CAR_TYPE.CAR_TYPE_WIN_BIKE,
  CAR_TYPE_MINI_BUS,
  CAR_TYPE_DELIVERY_VAN,
  CAR_TYPE_DELIVERY_BIKE,
  CAR_TYPE_DELIVERY_CAR,
  CAR_TYPE_ONION_TUKTUK,
];

export const ALL_TAXI_CAR_TYPES = [CAR_TYPE_TAXI_STANDARD, CAR_TYPE_TAXI_STANDARD_LARGE, CAR_TYPE_TAXI_PREMIUM, CAR_TYPE_TAXI_PREMIUM_LARGE];

export const VISIBLE_CAR_TYPES_SELECTOR = [
  CAR_TYPE_SEDAN,
  CAR_TYPE_VAN,
  CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE_DELIVERY_VAN,
  CAR_TYPE_DELIVERY_BIKE,
  CAR_TYPE_DELIVERY_CAR,
  CAR_TYPE_ONION_TUKTUK,
];

export const VISIBLE_CAR_TYPES_SELECTOR_SG = [
  CAR_TYPE_SEDAN,
  CAR_TYPE_VAN,
  CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE_BLACK_SEDAN,
  CAR_TYPE_MINI_BUS,
  CAR_TYPE_DELIVERY_VAN,
  CAR_TYPE_DELIVERY_BIKE,
  CAR_TYPE_DELIVERY_CAR,
];

export const CUSTOM_VISIBLE_CAR_TYPES: Partial<Record<REGION, CarType[]>> = {
  SG: VISIBLE_CAR_TYPES_SELECTOR_SG,
};

export const ALL_TUKTUK_CAR_TYPES = [CAR_TYPE_TUKTUK, CAR_TYPE_ONION_TUKTUK, CAR_TYPE.CAR_TYPE_REMORQUE];

export const ALL_TUKTUK_CAR_TYPES_SG = [CAR_TYPE_TUKTUK, CAR_TYPE.CAR_TYPE_REMORQUE];

export const CUSTOM_TUKTUK_CAR_TYPES: Partial<Record<REGION, CarType[]>> = {
  SG: ALL_TUKTUK_CAR_TYPES_SG,
};

export const ONION_CAR_MODEL = {
  T1: 'T1',
  T1L: 'T1L',
  T1S: 'T1S',
} as const;

export const ALL_ONION_CAR_MODEL = Object.values(ONION_CAR_MODEL);

/* @TODO Delivery Bike 제거 https://github.com/easi6dev/tada-driver-admin/pull/1200#discussion_r805567036 */
export const ALL_BIKE_TYPES = [CAR_TYPE_BIKE, CAR_TYPE_DELIVERY_BIKE];

export const TH_BIKE_TYPES = [CAR_TYPE_BIKE, CAR_TYPE_DELIVERY_BIKE, CAR_TYPE.CAR_TYPE_PREMIUM_BIKE, CAR_TYPE.CAR_TYPE_WIN_BIKE];

export const ET_CAR_TYPES = [CAR_TYPE_SEDAN, CAR_TYPE_VAN, CAR_TYPE_TAXI_STANDARD, CAR_TYPE_TUKTUK, CAR_TYPE.CAR_TYPE_REMORQUE];

export const DELIVERY_CAR_TYPES = [CAR_TYPE_DELIVERY_BIKE, CAR_TYPE_DELIVERY_CAR, CAR_TYPE_DELIVERY_VAN];

export const CUSTOM_BIKE_CAR_TYPES: Partial<Record<REGION, CarType[]>> = {
  [REGION.TH]: TH_BIKE_TYPES,
};
/* //car types */

/* locale */
export const LOCALE_TYPE = {
  EN: 'en',
  KO: 'ko',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
  KM: 'km',
  VI: 'vi',
  TH: 'th',
  JP: 'jp',
} as const;

export const ALL_LOCALES = Object.values(LOCALE_TYPE);
export const CORP_MANAGER_LOCALES = [
  LOCALE_TYPE.EN,
  LOCALE_TYPE.KO,
  LOCALE_TYPE.ZH_CN,
  LOCALE_TYPE.ZH_TW,
  LOCALE_TYPE.KM,
  LOCALE_TYPE.VI,
  LOCALE_TYPE.JP,
];
export const ARTICLE_ALL_LOCALES = [
  LOCALE_TYPE.EN,
  LOCALE_TYPE.KO,
  LOCALE_TYPE.ZH_CN,
  LOCALE_TYPE.ZH_TW,
  LOCALE_TYPE.KM,
  LOCALE_TYPE.VI,
  LOCALE_TYPE.JP,
];
export const DESCRIPTION_ALL_LOCALES = [
  LOCALE_TYPE.EN,
  LOCALE_TYPE.KM,
  LOCALE_TYPE.VI,
  LOCALE_TYPE.TH,
  LOCALE_TYPE.ZH_CN,
  LOCALE_TYPE.ZH_TW,
  LOCALE_TYPE.JP,
];
export const PLACE_ALL_LOCALES = [
  LOCALE_TYPE.EN,
  LOCALE_TYPE.ZH_CN,
  LOCALE_TYPE.ZH_TW,
  LOCALE_TYPE.KM,
  LOCALE_TYPE.VI,
  LOCALE_TYPE.TH,
  LOCALE_TYPE.JP,
];

export const DOCUMENT_GUIDE_ALL_LOCALES = [
  LOCALE_TYPE.EN,
  LOCALE_TYPE.KM,
  LOCALE_TYPE.VI,
  LOCALE_TYPE.TH,
  LOCALE_TYPE.ZH_CN,
  LOCALE_TYPE.ZH_TW,
  LOCALE_TYPE.KO,
];

export const UNSUPPORTED_DOCUMENT_GUIDE_LOCALE = [LOCALE_TYPE.JP, 'in'];

export const LOCALE_STRINGS: Record<LocaleType, string> = {
  [LOCALE_TYPE.EN]: 'en',
  [LOCALE_TYPE.VI]: 'vi',
  [LOCALE_TYPE.KM]: 'km',
  [LOCALE_TYPE.ZH_CN]: 'zh-CN',
  [LOCALE_TYPE.ZH_TW]: 'zh-TW',
  [LOCALE_TYPE.KO]: 'ko',
  [LOCALE_TYPE.TH]: 'th',
  [LOCALE_TYPE.JP]: 'jp',
};

export const FAQ_REGION_LOCALE: Record<string, LocaleType> = {
  [REGION.SG]: LOCALE_TYPE.EN,
  [REGION.VN]: LOCALE_TYPE.VI,
  [REGION.KH]: LOCALE_TYPE.KM,
  [REGION.KR]: LOCALE_TYPE.KO,
  [REGION.TH]: LOCALE_TYPE.TH,
  [REGION.HK]: LOCALE_TYPE.EN,
};

export type LocaleType = valueOf<typeof LOCALE_TYPE>;
/* //locale */

/* product type */
export enum PRODUCT_TYPE {
  RIDE_HAILING = 10,
  TAXI_HAILING = 11,
  HAILING = 12,
  STUDENT = 13,
  DELIVERY = 20,
  EV = 14,
}

export const PRODUCT_TYPE_CHAUFFEUR = 0;
export const PRODUCT_TYPE_AIRPORT_PICKUP = 1;
export const PRODUCT_TYPE_HOURLY = 2;
export const PRODUCT_TYPE_DAILY = 3;
export const PRODUCT_TYPE_AIRPORT_SENDING = 4;
export const PRODUCT_TYPE_API = 5;
export const PRODUCT_TYPE_AIRPORT_BOTH = 6;
export const PRODUCT_TYPE_RIDE_HAILING = 10;
export const PRODUCT_TYPE_TAXI_HAILING = 11;
export const PRODUCT_TYPE_HAILING = 12;
export const PRODUCT_TYPE_STUDENT_FLEET = 13;
export const PRODUCT_TYPE_DELIVERY = 20;
export const PRODUCT_TYPE_EV = 14;

export const ALL_PRODUCT_TYPES = [
  PRODUCT_TYPE_RIDE_HAILING,
  PRODUCT_TYPE_TAXI_HAILING,
  PRODUCT_TYPE_HAILING,
  PRODUCT_TYPE_STUDENT_FLEET,
  PRODUCT_TYPE_DELIVERY,
  PRODUCT_TYPE_EV,
];

export const PRODUCT_EXTRA_TYPES = [
  PRODUCT_TYPE_RIDE_HAILING,
  PRODUCT_TYPE_TAXI_HAILING,
  PRODUCT_TYPE_HAILING,
  PRODUCT_TYPE_DELIVERY,
  PRODUCT_TYPE_EV,
];

export const PRODUCT_TYPE_STRINGS: Record<number, string> = {
  [PRODUCT_TYPE_CHAUFFEUR]: 'CHAUFFEUR',
  [PRODUCT_TYPE_AIRPORT_PICKUP]: 'AIRPORT_PICKUP',
  [PRODUCT_TYPE_HOURLY]: 'HOURLY',
  [PRODUCT_TYPE_DAILY]: 'DAILY',
  [PRODUCT_TYPE_AIRPORT_SENDING]: 'AIRPORT_SENDING',
  [PRODUCT_TYPE_API]: 'API_PRODUCT',
  [PRODUCT_TYPE_AIRPORT_BOTH]: 'AIRPORT_BOTH',
  [PRODUCT_TYPE_RIDE_HAILING]: 'RIDE_HAILING',
  [PRODUCT_TYPE_TAXI_HAILING]: 'TAXI_HAILING',
  [PRODUCT_TYPE_HAILING]: 'ANYTADA',
  [PRODUCT_TYPE_DELIVERY]: 'DELIVERY',
  [PRODUCT_TYPE_STUDENT_FLEET]: 'STUDENT FLEET',
  [PRODUCT_TYPE_EV]: 'EV',
};

export const TRANSLATE_PRODUCT_TYPE_STRINGS: Record<number, string> = {
  [PRODUCT_TYPE_CHAUFFEUR]: 'constants.product_type_chauffeur',
  [PRODUCT_TYPE_AIRPORT_PICKUP]: 'constants.product_type_airport_pickup',
  [PRODUCT_TYPE_HOURLY]: 'constants.product_type_hourly',
  [PRODUCT_TYPE_DAILY]: 'constants.product_type_daily',
  [PRODUCT_TYPE_AIRPORT_SENDING]: 'constants.product_type_airport_sending',
  [PRODUCT_TYPE_API]: 'constants.product_type_api',
  [PRODUCT_TYPE_AIRPORT_BOTH]: 'constants.product_type_airport_both',
  [PRODUCT_TYPE_RIDE_HAILING]: 'constants.product_type_ride_hailing',
  [PRODUCT_TYPE_TAXI_HAILING]: 'constants.product_type_taxi_hailing',
  [PRODUCT_TYPE_HAILING]: 'constants.product_type_hailing',
  [PRODUCT_TYPE_DELIVERY]: 'constants.product_type_delivery',
  [PRODUCT_TYPE_EV]: 'constants.product_type_ev',
  [PRODUCT_TYPE_STUDENT_FLEET]: 'constants.product_type_student_fleet',
};
/* //product type */

/* coord type */
export const COORD_TYPE_WGS84 = 'wgs84';
export const COORD_TYPE_GCJ02 = 'gcj02';
export const COORD_TYPE_BD09 = 'bd09';

export const ALL_COORD_TYPES = [COORD_TYPE_WGS84, COORD_TYPE_GCJ02, COORD_TYPE_BD09];

export const COORD_TYPE_STRINGS = {
  [COORD_TYPE_WGS84]: 'WGS84',
  [COORD_TYPE_GCJ02]: 'GCJ02',
  [COORD_TYPE_BD09]: 'BD09',
};
/* //coord type */

/* vehicle ownership */
export const VEHICLE_OWNERSHIP = {
  OWN_VEHICLE: 0,
  RENTAL_VEHICLE: 1,
  COMPANY_VEHICLE: 2,
  ANOTHER_PERSON: 3,
  HIRE_CAR_PERMIT: 4,
} as const;

export const VEHICLE_OWNERSHIP_STRINGS: Record<number, string> = {
  [VEHICLE_OWNERSHIP.OWN_VEHICLE]: 'Driving my own vehicle',
  [VEHICLE_OWNERSHIP.RENTAL_VEHICLE]: 'Driving a rental vehicle',
  [VEHICLE_OWNERSHIP.COMPANY_VEHICLE]: "Driving my company's vehicle",
  [VEHICLE_OWNERSHIP.ANOTHER_PERSON]: 'Driving a vehicle that belongs to another person',
  [VEHICLE_OWNERSHIP.HIRE_CAR_PERMIT]: 'Driving with a hire car permit',
};

export const ALL_DEFAULT_VEHICLE_OWNERSHIP = [VEHICLE_OWNERSHIP.OWN_VEHICLE, VEHICLE_OWNERSHIP.RENTAL_VEHICLE];

export const ALL_SG_PH_VEHICLE_OWNERSHIP = [
  VEHICLE_OWNERSHIP.OWN_VEHICLE,
  VEHICLE_OWNERSHIP.RENTAL_VEHICLE,
  VEHICLE_OWNERSHIP.COMPANY_VEHICLE,
  VEHICLE_OWNERSHIP.ANOTHER_PERSON,
];

export const ALL_TH_VEHICLE_OWNERSHIP = [VEHICLE_OWNERSHIP.OWN_VEHICLE, VEHICLE_OWNERSHIP.ANOTHER_PERSON];

export const ALL_HK_VEHICLE_OWNERSHIP = [VEHICLE_OWNERSHIP.OWN_VEHICLE, VEHICLE_OWNERSHIP.ANOTHER_PERSON, VEHICLE_OWNERSHIP.HIRE_CAR_PERMIT];
/* //vehicle ownership */

/* price type */
export const PRICE_TYPE_ONEWAY = 0;
export const PRICE_TYPE_HOURLY = 1;
export const PRICE_TYPE_DAILY = 2;
export const ALL_PRICE_TYPES_STRINGS = {
  [PRICE_TYPE_ONEWAY]: 'ONEWAY',
  [PRICE_TYPE_HOURLY]: 'HOURLY',
  [PRICE_TYPE_DAILY]: 'DAILY',
};
/* //price type */

/* price add type */
export const PRICE_ADD_TYPE_LATE_FEE = 0;
export const PRICE_ADD_TYPE_LATE_FEE_HOURLY = 1;
export const PRICE_ADD_TYPE_DRIVER_HOTEL = 2;
export const PRICE_ADD_TYPE_ZONE_ADDITIONAL_FEE = 3;
export const PRICE_ADD_TYPE_HOLIDAY = 4;
export const PRICE_ADD_TYPE_ADDITIONAL_STOP = 5;
export const PRICE_ADD_TYPE_BOOKING_FEE = 6;

export const PRICE_ADD_TYPE_STRINGS: Record<number, string> = {
  [PRICE_ADD_TYPE_LATE_FEE]: 'LATE NIGHT FEE',
  [PRICE_ADD_TYPE_LATE_FEE_HOURLY]: 'LATE NIGHT FEE HOURLY',
  [PRICE_ADD_TYPE_DRIVER_HOTEL]: 'DRIVER HOTEL FEE',
  [PRICE_ADD_TYPE_ZONE_ADDITIONAL_FEE]: 'ZONE ADDITIONAL FEE',
  [PRICE_ADD_TYPE_HOLIDAY]: 'HOLIDAY ADDITIONAL FEE',
  [PRICE_ADD_TYPE_ADDITIONAL_STOP]: 'ADDITIONAL STOP FEE',
  [PRICE_ADD_TYPE_BOOKING_FEE]: 'BOOKING FEE',
};
/* //price add type */

/* week type */
export const WEEK_TYPE_ALL = 0;
export const WEEK_TYPE_WEEK_DAYS = 1;
export const WEEK_TYPE_WEEK_ENDS = 2;
export const WEEK_TYPE_MON_THUR = 3; // monday ~ thursday
export const WEEK_TYPE_FRI_SAT = 4; // Friday, Saturday
export const WEEK_TYPE_WEEKENDS_FRI = 5; // Friday~Sunday
export const WEEK_TYPE_MON = 6;
export const WEEK_TYPE_TUE = 7;
export const WEEK_TYPE_WED = 8;
export const WEEK_TYPE_THU = 9;
export const WEEK_TYPE_FRI = 10;
export const WEEK_TYPE_SAT = 11;
export const WEEK_TYPE_SUN = 12;
export const WEEK_TYPE_TUES_FRI = 13;

export const ALL_WEEK_TYPES = [
  WEEK_TYPE_ALL,
  WEEK_TYPE_WEEK_DAYS,
  WEEK_TYPE_WEEK_ENDS,
  WEEK_TYPE_WEEKENDS_FRI,
  WEEK_TYPE_MON_THUR,
  WEEK_TYPE_FRI_SAT,
  WEEK_TYPE_TUES_FRI,
  WEEK_TYPE_FRI,
  WEEK_TYPE_SAT,
  WEEK_TYPE_SUN,
  WEEK_TYPE_MON,
  WEEK_TYPE_TUE,
  WEEK_TYPE_WED,
  WEEK_TYPE_THU,
];

export const PRICE_WEEK_TYPE_STRINGS: Record<number, string> = {
  [WEEK_TYPE_ALL]: 'All',
  [WEEK_TYPE_WEEK_DAYS]: 'Week days only',
  [WEEK_TYPE_WEEK_ENDS]: 'Weekends only',
  [WEEK_TYPE_MON_THUR]: 'Mon~Thu',
  [WEEK_TYPE_FRI_SAT]: 'Fri~Sat',
  [WEEK_TYPE_WEEKENDS_FRI]: 'Fri~Sun',
  [WEEK_TYPE_MON]: 'Mon',
  [WEEK_TYPE_TUE]: 'Tue',
  [WEEK_TYPE_WED]: 'Wed',
  [WEEK_TYPE_THU]: 'Thu',
  [WEEK_TYPE_FRI]: 'Fri',
  [WEEK_TYPE_SAT]: 'Sat',
  [WEEK_TYPE_SUN]: 'Sun',
  [WEEK_TYPE_TUES_FRI]: 'Tue~Fri',
};

export const WEEK_DAY_OF_TYPES = {
  [WEEK_TYPE_WEEK_DAYS]: [1, 2, 3, 4, 5],
  [WEEK_TYPE_WEEK_ENDS]: [6, 7],
  [WEEK_TYPE_ALL]: [1, 2, 3, 4, 5, 6, 7],
  [WEEK_TYPE_MON_THUR]: [1, 2, 3, 4],
  [WEEK_TYPE_FRI_SAT]: [5, 6],
  [WEEK_TYPE_WEEKENDS_FRI]: [5, 6, 7],
  [WEEK_TYPE_MON]: [1],
  [WEEK_TYPE_TUE]: [2],
  [WEEK_TYPE_WED]: [3],
  [WEEK_TYPE_THU]: [4],
  [WEEK_TYPE_FRI]: [5],
  [WEEK_TYPE_SAT]: [6],
  [WEEK_TYPE_SUN]: [7],
  [WEEK_TYPE_TUES_FRI]: [2, 3, 4, 5],
};
/* //week type */

/* payment request type */
export const PAYMENT_REQUEST_TYPE_LATE_NIGHT = 0;
export const PAYMENT_REQUEST_TYPE_WAITING = 1;
export const PAYMENT_REQUEST_TYPE_TOLL = 2;
export const PAYMENT_REQUEST_TYPE_PARKING = 3;
export const PAYMENT_REQUEST_TYPE_ADDITIONAL_STOP = 4;
export const PAYMENT_REQUEST_TYPE_HOLIDAY = 5;
export const PAYMENT_REQUEST_TYPE_CARSEAT = 6;
export const PAYMENT_REQUEST_TYPE_WIFI = 7;
export const PAYMENT_REQUEST_TYPE_ZONE_ADDITIONAL = 8;
export const PAYMENT_REQUEST_TYPE_DRIVER_HOTEL = 9;
export const PAYMENT_REQUEST_TYPE_BOOKING_FEE = 10;
export const PAYMENT_REQUEST_TYPE_AIRPORT_FEE = 11;
export const PAYMENT_REQUEST_TYPE_ETC = 999;
export const PAYMENT_REQUEST_TYPE_STRINGS = {
  [PAYMENT_REQUEST_TYPE_LATE_NIGHT]: 'LATE NIGHT FEE',
  [PAYMENT_REQUEST_TYPE_WAITING]: 'WAITING FEE',
  [PAYMENT_REQUEST_TYPE_TOLL]: 'TOLL FEE',
  [PAYMENT_REQUEST_TYPE_ADDITIONAL_STOP]: 'ADDITIONAL STOP FEE',
  [PAYMENT_REQUEST_TYPE_PARKING]: 'PARKING FEE',
  [PAYMENT_REQUEST_TYPE_HOLIDAY]: 'HOLIDAY FEE',
  [PAYMENT_REQUEST_TYPE_CARSEAT]: 'CAR SEAT FEE',
  [PAYMENT_REQUEST_TYPE_WIFI]: 'WIFI FEE',
  [PAYMENT_REQUEST_TYPE_ZONE_ADDITIONAL]: 'ZONE ADDITIONAL FEE',
  [PAYMENT_REQUEST_TYPE_DRIVER_HOTEL]: 'DRIVER HOTEL FEE',
  [PAYMENT_REQUEST_TYPE_BOOKING_FEE]: 'TAXI BOOKING FEE',
  [PAYMENT_REQUEST_TYPE_AIRPORT_FEE]: 'AIRPORT ADDITIONAL FEE',
  [PAYMENT_REQUEST_TYPE_ETC]: 'ETC',
};
export const HAILING_PAYMENT_REQUEST_TYPE = [
  PAYMENT_REQUEST_TYPE_TOLL,
  PAYMENT_REQUEST_TYPE_CARSEAT,
  PAYMENT_REQUEST_TYPE_ADDITIONAL_STOP,
  PAYMENT_REQUEST_TYPE_BOOKING_FEE,
  PAYMENT_REQUEST_TYPE_AIRPORT_FEE,
  PAYMENT_REQUEST_TYPE_ETC,
];
/* //payment request type */

/* application document status */
export const APPLICATION_STATUS_NOT_YET = -1;
export const APPLICATION_STATUS_PENDING = 0;
export const APPLICATION_STATUS_PASSED = 1;
export const APPLICATION_STATUS_FAILED = 2;
export const APPLICATION_STATUS_REJECTED = 3;
export const APPLICATION_STATUS_CHANGE_REQUESTED = 4;
export const APPLICATION_STATUS_ON_PROBATION = 5;
export const APPLICATION_STATUS_STRING: Record<string | number, string> = {
  [APPLICATION_STATUS_NOT_YET]: 'NOT_YET',
  [APPLICATION_STATUS_PENDING]: 'PENDING',
  [APPLICATION_STATUS_PASSED]: 'PASSED',
  [APPLICATION_STATUS_FAILED]: 'FAILED',
  [APPLICATION_STATUS_REJECTED]: 'REJECTED',
  [APPLICATION_STATUS_CHANGE_REQUESTED]: 'CHANGE_REQUESTED',
  [APPLICATION_STATUS_ON_PROBATION]: 'ON_PROBATION',
};

export enum APPLICATION_DOCUMENT_STATUS {
  PASSED = 1,
  REJECTED = 2,
  CHANGE_REQUESTED = 4,
  ON_PROBATION = 5,
}

export const ALL_APPLICATION_DOCUMENT_STATUES = [
  APPLICATION_DOCUMENT_STATUS.PASSED,
  APPLICATION_DOCUMENT_STATUS.REJECTED,
  APPLICATION_DOCUMENT_STATUS.CHANGE_REQUESTED,
  APPLICATION_DOCUMENT_STATUS.ON_PROBATION,
];
export const APPLICATION_DOCUMENT_STATUSES: Record<string, string> = {
  [APPLICATION_DOCUMENT_STATUS.PASSED]: 'PASSED',
  [APPLICATION_DOCUMENT_STATUS.REJECTED]: 'REJECTED',
  [APPLICATION_DOCUMENT_STATUS.CHANGE_REQUESTED]: 'CHANGE_REQUESTED',
  [APPLICATION_DOCUMENT_STATUS.ON_PROBATION]: 'ON_PROBATION',
};
export const TRANSLATE_APPLICATION_DOCUMENT_STATUSES: Record<string, string> = {
  [APPLICATION_DOCUMENT_STATUS.PASSED]: 'constants.application_status_passed',
  [APPLICATION_DOCUMENT_STATUS.REJECTED]: 'constants.application_status_rejected',
  [APPLICATION_DOCUMENT_STATUS.CHANGE_REQUESTED]: 'constants.application_status_change_requested',
  [APPLICATION_DOCUMENT_STATUS.ON_PROBATION]: 'constants.application_status_on_probation',
};

/*//application document status */

/* application status */
export enum APPLICATION_STATUS {
  IDLE = 'IDLE',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

export const ALL_APPLICATION_STATUES = [
  APPLICATION_STATUS.IDLE,
  APPLICATION_STATUS.IN_REVIEW,
  APPLICATION_STATUS.APPROVED,
  APPLICATION_STATUS.REJECTED,
  APPLICATION_STATUS.EXPIRED,
];
export const APPLICATION_STATUSES: Record<string, string> = {
  [APPLICATION_STATUS.IDLE]: 'IDLE',
  [APPLICATION_STATUS.IN_REVIEW]: 'IN REVIEW',
  [APPLICATION_STATUS.APPROVED]: 'APPROVED',
  [APPLICATION_STATUS.REJECTED]: 'REJECTED',
  [APPLICATION_STATUS.EXPIRED]: 'EXPIRED',
};
export const TRANSLATE_APPLICATION_STATUSES: Record<string, string> = {
  [APPLICATION_STATUS.IDLE]: 'constants.application_status_idle',
  [APPLICATION_STATUS.IN_REVIEW]: 'constants.application_status_in_review',
  [APPLICATION_STATUS.APPROVED]: 'constants.application_status_approved',
  [APPLICATION_STATUS.REJECTED]: 'constants.application_status_rejected',
};
/* //application status */

/* driver status */
export enum DRIVER_STATUS {
  IDLE = 'IDLE',
  APPROVED = 'APPROVED',
  RESIGNED = 'RESIGNED',
  BANNED = 'BANNED',
  ERASED = 'ERASED',
  EXPIRED = 'EXPIRED',
}

export const ALL_DRIVER_STATUES = [
  DRIVER_STATUS.IDLE,
  DRIVER_STATUS.APPROVED,
  DRIVER_STATUS.RESIGNED,
  DRIVER_STATUS.BANNED,
  DRIVER_STATUS.ERASED,
  DRIVER_STATUS.EXPIRED,
];

export const DRIVER_STATUSES: Record<string, string> = {
  [DRIVER_STATUS.IDLE]: 'IDLE',
  [DRIVER_STATUS.APPROVED]: 'APPROVED',
  [DRIVER_STATUS.RESIGNED]: 'RESIGNED',
  [DRIVER_STATUS.BANNED]: 'BANNED',
  [DRIVER_STATUS.ERASED]: 'ERASED',
  [DRIVER_STATUS.EXPIRED]: 'EXPIRED',
};

export const TRANSLATE_DRIVER_STATUSES: Record<string, string> = {
  [DRIVER_STATUS.IDLE]: 'constants.driver_status_idle',
  [DRIVER_STATUS.APPROVED]: 'constants.driver_status_approved',
  [DRIVER_STATUS.RESIGNED]: 'constants.driver_status_resigned',
  [DRIVER_STATUS.BANNED]: 'constants.driver_status_banned',
  [DRIVER_STATUS.ERASED]: 'constants.driver_status_erased',
  [DRIVER_STATUS.EXPIRED]: 'constants.driver_status_expired',
};
/* //driver status */

/* driver type */
export const DRIVER_TYPE_PH = 0;
export const DRIVER_TYPE_PH_PARTTIME = 1;
export const DRIVER_TYPE_TAXI = 2;
export const DRIVER_TYPE_TUKTUK = 3;
export const DRIVER_TYPE_BIKE = 4;
export const DRIVER_TYPE_HOURLY_RENTAL = 5;
export const DRIVER_TYPE_DELIVERY = 6;

export const ALL_DRIVER_TYPE_NUMBERS = [
  DRIVER_TYPE_PH,
  DRIVER_TYPE_PH_PARTTIME,
  DRIVER_TYPE_TAXI,
  DRIVER_TYPE_TUKTUK,
  DRIVER_TYPE_BIKE,
  DRIVER_TYPE_HOURLY_RENTAL,
  DRIVER_TYPE_DELIVERY,
];

export const VALIDATE_DRIVER_TYPE_NUMBERS = [DRIVER_TYPE_PH, DRIVER_TYPE_HOURLY_RENTAL, DRIVER_TYPE_TAXI, DRIVER_TYPE_DELIVERY];

export const VALIDATE_CAR_MODEL_DRIVER_TYPE_NUMBERS = [DRIVER_TYPE_PH, DRIVER_TYPE_TAXI, DRIVER_TYPE_DELIVERY];

export enum DRIVER_TYPE {
  PRIVATE_HIRE = 'PRIVATE_HIRE',
  LITE = 'LITE',
  TAXI = 'TAXI',
  TUKTUK = 'TUKTUK',
  BIKE = 'BIKE',
  HOURLY_RENTAL = 'HOURLY_RENTAL',
  DELIVERY = 'DELIVERY',
}

export const ALL_DRIVER_TYPES = [
  DRIVER_TYPE.PRIVATE_HIRE,
  DRIVER_TYPE.LITE,
  DRIVER_TYPE.TAXI,
  DRIVER_TYPE.TUKTUK,
  DRIVER_TYPE.BIKE,
  DRIVER_TYPE.HOURLY_RENTAL,
  DRIVER_TYPE.DELIVERY,
];

export const ALL_VEHICLE_MAKER_DRIVER_TYPES = [
  DRIVER_TYPE.PRIVATE_HIRE,
  DRIVER_TYPE.TAXI,
  DRIVER_TYPE.TUKTUK,
  DRIVER_TYPE.BIKE,
  DRIVER_TYPE.DELIVERY,
];

export const SG_CAR_TYPES_FOR_PLATE_VALIDATION = [DRIVER_TYPE_PH, DRIVER_TYPE_TAXI];

export const DRIVER_TYPE_STRINGS: Record<string | number, string> = {
  [DRIVER_TYPE.PRIVATE_HIRE]: 'Private Hire',
  [DRIVER_TYPE.LITE]: 'Lite',
  [DRIVER_TYPE.TAXI]: 'Taxi',
  [DRIVER_TYPE.TUKTUK]: 'Tuk Tuk',
  [DRIVER_TYPE.BIKE]: 'Bike',
  [DRIVER_TYPE.HOURLY_RENTAL]: 'Hourly Rental',
  [DRIVER_TYPE.DELIVERY]: 'Delivery',
  [DRIVER_TYPE_PH]: 'Private Hire',
  [DRIVER_TYPE_PH_PARTTIME]: 'Lite',
  [DRIVER_TYPE_TAXI]: 'Taxi',
  [DRIVER_TYPE_TUKTUK]: 'Tuk Tuk',
  [DRIVER_TYPE_BIKE]: 'Bike',
  [DRIVER_TYPE_HOURLY_RENTAL]: 'Hourly Rental',
  [DRIVER_TYPE_DELIVERY]: 'Delivery',
};

export const TRANSLATE_DRIVER_TYPE_STRINGS: Record<string | number, string> = {
  [DRIVER_TYPE.PRIVATE_HIRE]: 'constants.driver_type_ph',
  [DRIVER_TYPE.LITE]: 'constants.driver_type_ph_parttime',
  [DRIVER_TYPE.TAXI]: 'constants.driver_type_taxi',
  [DRIVER_TYPE.TUKTUK]: 'constants.driver_type_tuktuk',
  [DRIVER_TYPE.BIKE]: 'constants.driver_type_bike',
  [DRIVER_TYPE.HOURLY_RENTAL]: 'constants.driver_type_hourly_rental',
  [DRIVER_TYPE.DELIVERY]: 'constants.driver_type_delivery',
  [DRIVER_TYPE_PH]: 'constants.driver_type_ph',
  [DRIVER_TYPE_PH_PARTTIME]: 'constants.driver_type_ph_parttime',
  [DRIVER_TYPE_TAXI]: 'constants.driver_type_taxi',
  [DRIVER_TYPE_TUKTUK]: 'constants.driver_type_tuktuk',
  [DRIVER_TYPE_BIKE]: 'constants.driver_type_bike',
  [DRIVER_TYPE_HOURLY_RENTAL]: 'constants.driver_type_hourly_rental',
  [DRIVER_TYPE_DELIVERY]: 'constants.driver_type_delivery',
};

export const DRIVER_TYPE_TO_NUMBER: Record<string, number> = {
  [DRIVER_TYPE.PRIVATE_HIRE]: DRIVER_TYPE_PH,
  [DRIVER_TYPE.LITE]: DRIVER_TYPE_PH_PARTTIME,
  [DRIVER_TYPE.TAXI]: DRIVER_TYPE_TAXI,
  [DRIVER_TYPE.TUKTUK]: DRIVER_TYPE_TUKTUK,
  [DRIVER_TYPE.BIKE]: DRIVER_TYPE_BIKE,
  [DRIVER_TYPE.HOURLY_RENTAL]: DRIVER_TYPE_HOURLY_RENTAL,
  [DRIVER_TYPE.DELIVERY]: DRIVER_TYPE_DELIVERY,
};

export const DRIVER_TYPE_TO_STRING: Record<number, string> = {
  [DRIVER_TYPE_PH]: DRIVER_TYPE.PRIVATE_HIRE,
  [DRIVER_TYPE_PH_PARTTIME]: DRIVER_TYPE.LITE,
  [DRIVER_TYPE_TAXI]: DRIVER_TYPE.TAXI,
  [DRIVER_TYPE_TUKTUK]: DRIVER_TYPE.TUKTUK,
  [DRIVER_TYPE_BIKE]: DRIVER_TYPE.BIKE,
  [DRIVER_TYPE_HOURLY_RENTAL]: DRIVER_TYPE.HOURLY_RENTAL,
  [DRIVER_TYPE_DELIVERY]: DRIVER_TYPE.DELIVERY,
};

export const DRIVER_TYPE_NUMBER_STRINGS: Record<number, string> = {
  [DRIVER_TYPE_PH]: 'Private Hire',
  [DRIVER_TYPE_PH_PARTTIME]: 'Lite',
  [DRIVER_TYPE_TAXI]: 'Taxi',
  [DRIVER_TYPE_TUKTUK]: 'Tuk Tuk',
  [DRIVER_TYPE_BIKE]: 'Bike',
  [DRIVER_TYPE_HOURLY_RENTAL]: 'Hourly Rental',
  [DRIVER_TYPE_DELIVERY]: 'Delivery',
};

/* driver document */
export const DRIVER_DOCUMENT = {
  DriverLicense: 'driving_license',
  PDVL: 'pdvl',
  VehicleOwnership: 'vehicle_ownership',
  Insurance: 'insurance',
  CarModel: 'car_model',
  ProfilePicture: 'profile_picture',
  NRIC: 'nric',
  BabySeat: 'car_seat',
  BankInfo: 'bank_info',
  TDVL: 'tdvl',
  TDIP: 'tdip',
  TaxiDetail: 'taxi_detail',
  TaxiHiringAgreement: 'taxi_hiring_agreement',
  JudicialRecord: 'judicial_record',
  VehicleRegister: 'vehicle_register',
  Decal: 'decal',
  PDVL_OR_TDVL: 'pdvl_or_tdvl',
  VTI_DOCS: 'vti_docs',
  VaccinatedStatus: 'vaccinated_status',
  TransportBusinessLicense: 'transport_business_license',
  VehicleElectronicMarkings: 'vehicle_electronic_markings',
  PoliceClearanceCertificate: 'driver_police_clearance_certificate',
} as const;

export type DriverDocument = valueOf<typeof DRIVER_DOCUMENT>;
/* //driver document */

/* company */
export enum TAXI_COMPANY {
  STRIDES_PREMIER = 'STRIDES_PREMIER', //SG
  PRIME = 'PRIME', //SG
  COMFORT_AND_CITICAB = 'COMFORT_AND_CITICAB', //SG
  TRANS_CAB = 'TRANS_CAB', //SG
  YELLOW_TOP = 'YELLOW_TOP', //SG
  VINA_TAXI = 'VINA_TAXI', //VN
  HDT = 'HDT', //SG
  CHOICE_TAXI = 'CHOICE_TAXI', //KH
  TRIBECAR = 'TRIBECAR', //VN
  SHARIOT = 'SHARIOT', //VN
  LUMENS = 'LUMENS', //VN
  HOWA_INTERNATIONAL_CO = 'HOWA_INTERNATIONAL_CO', //TH
  OTHER = 'OTHER',
}

export const TAXI_COMPANY_LOW_CASE_TO_UPPPER_CASE: Record<string, TAXI_COMPANY> = {
  ['Strides Premier']: TAXI_COMPANY.STRIDES_PREMIER,
  ['Prime']: TAXI_COMPANY.PRIME,
  ['ComfortDelGro & Citycab']: TAXI_COMPANY.COMFORT_AND_CITICAB,
  ['Trans-cab']: TAXI_COMPANY.TRANS_CAB,
  ['Yellow-Top']: TAXI_COMPANY.YELLOW_TOP,
  ['Vinataxi']: TAXI_COMPANY.VINA_TAXI,
};

export const TAXI_COMPANIES = [
  TAXI_COMPANY.STRIDES_PREMIER,
  TAXI_COMPANY.PRIME,
  TAXI_COMPANY.COMFORT_AND_CITICAB,
  TAXI_COMPANY.TRANS_CAB,
  TAXI_COMPANY.YELLOW_TOP,
  TAXI_COMPANY.VINA_TAXI,
  TAXI_COMPANY.HDT,
  TAXI_COMPANY.CHOICE_TAXI,
  TAXI_COMPANY.TRIBECAR,
  TAXI_COMPANY.SHARIOT,
  TAXI_COMPANY.LUMENS,
  TAXI_COMPANY.HOWA_INTERNATIONAL_CO,
  TAXI_COMPANY.OTHER,
];

export const TAXI_COMPANY_STRINGS = {
  [TAXI_COMPANY.STRIDES_PREMIER]: 'Strides Premier',
  [TAXI_COMPANY.PRIME]: 'Prime',
  [TAXI_COMPANY.COMFORT_AND_CITICAB]: 'Comfort And Citicab',
  [TAXI_COMPANY.TRANS_CAB]: 'Trans Cab',
  [TAXI_COMPANY.YELLOW_TOP]: 'Yellow Top',
  [TAXI_COMPANY.VINA_TAXI]: 'Vina Taxi',
  [TAXI_COMPANY.HDT]: 'HDT',
  [TAXI_COMPANY.CHOICE_TAXI]: 'Choice Taxi',
  [TAXI_COMPANY.TRIBECAR]: 'Tribe Car',
  [TAXI_COMPANY.SHARIOT]: 'Shariot',
  [TAXI_COMPANY.LUMENS]: 'Lumens',
  [TAXI_COMPANY.HOWA_INTERNATIONAL_CO]: 'Howa International Co., Ltd.',
  [TAXI_COMPANY.OTHER]: 'Other',
};

export const TAXI_COMPANY_BY_REGION: Record<string, TAXI_COMPANY[]> = {
  [REGION.SG]: [TAXI_COMPANY.STRIDES_PREMIER, TAXI_COMPANY.PRIME, TAXI_COMPANY.COMFORT_AND_CITICAB, TAXI_COMPANY.TRANS_CAB, TAXI_COMPANY.YELLOW_TOP],
  [REGION.KH]: [],
  [REGION.VN]: [TAXI_COMPANY.VINA_TAXI],
  [REGION.TH]: [TAXI_COMPANY.HOWA_INTERNATIONAL_CO, TAXI_COMPANY.OTHER],
  [REGION.HK]: [TAXI_COMPANY.OTHER],
};
/* //company */

/* driver push type */
export const PUSH_DRIVER_TYPES = {
  [REGION.SG]: ['ph', 'taxi'],
  [REGION.VN]: ['ph', 'taxi', 'bike'],
  [REGION.KH]: ['ph', 'taxi', 'tuktuk'],
  [REGION.KR]: [],
  [REGION.ET]: [],
  [REGION.HK]: [],
};
/* //driver push type */

/* notice type */
export const NOTICE_TYPE_STRING = 0;
export const NOTICE_TYPE_URL = 1;
export const NOTICE_TYPE_STRINGS = {
  [NOTICE_TYPE_STRING]: 'text',
  [NOTICE_TYPE_URL]: 'url',
};
/* //notice type */

/* notice */
export const NOTICE_POSITION_MAIN = 0;
export const NOTICE_POSITIONS = [NOTICE_POSITION_MAIN];

export const NOTICE_POSITIONS_STRINGS = {
  [NOTICE_POSITION_MAIN]: 'main',
};
/* //notice */

/* instant surge */
export const INSTANT_SURGE_TYPE_BOTH = 0;
export const INSTANT_SURGE_TYPE_FROM_ONLY = 1;
export const INSTANT_SURGE_TYPE_TO_ONLY = 2;

export const ALL_INSTANT_SURGE_TYPE_STRINGS: Record<number, string> = {
  [INSTANT_SURGE_TYPE_BOTH]: 'Both',
  [INSTANT_SURGE_TYPE_FROM_ONLY]: 'From',
  [INSTANT_SURGE_TYPE_TO_ONLY]: 'To',
};
/* //instant surge */

/* force dispatch status */
export const FORCE_DISPATCH_STATUS_RETURN = -1;
export const FORCE_DISPATCH_STATUS_RETURN_ARRIVED = -5;
export const FORCE_DISPATCH_STATUS_IDLE = 0;
export const FORCE_DISPATCH_STATUS_RETURN_WARNING = -101;
export const FORCE_DISPATCH_STATUS_PICKUP_WARNING = -140;
export const FORCE_DISPATCH_STATUS_INUSE_WARNING = -160;
export const FORCE_DISPATCH_STATUS_PICKUP = 50;
export const FORCE_DISPATCH_STATUS_PICKUP_ARRIVED = 55;
export const FORCE_DISPATCH_STATUS_IDLES = [FORCE_DISPATCH_STATUS_IDLE];

export const FORCE_DISPATCH_STATUS_PICKUPS = [
  RENTAL_STATUS_ASSIGNED,
  RENTAL_STATUS_PICKUP,
  RENTAL_STATUS_PICKUP_ARRIVED,
  FORCE_DISPATCH_STATUS_PICKUP,
  FORCE_DISPATCH_STATUS_PICKUP_ARRIVED,
];

export const FORCE_DISPATCH_STATUS_DRIVINGS = [RENTAL_STATUS_INUSE];

export const FORCE_DISPATCH_STATUS_RETURNS = [FORCE_DISPATCH_STATUS_RETURN];
export const FORCE_DISPATCH_STATUS_RETURN_ARRIVEDS = [FORCE_DISPATCH_STATUS_RETURN_ARRIVED];

export const FORCE_DISPATCH_STATUS_PICKUP_WARNINGS = [FORCE_DISPATCH_STATUS_PICKUP_WARNING];
export const FORCE_DISPATCH_STATUS_INUSE_WARNINGS = [FORCE_DISPATCH_STATUS_INUSE_WARNING];
export const FORCE_DISPATCH_STATUS_RETURN_WARNINGS = [FORCE_DISPATCH_STATUS_RETURN_WARNING];

export const FORCE_DISPATCH_STATUS_STRINGS = {
  [FORCE_DISPATCH_STATUS_RETURN]: 'Return',
  [FORCE_DISPATCH_STATUS_RETURN_ARRIVED]: 'Return Arrived',
  [FORCE_DISPATCH_STATUS_RETURN_WARNING]: 'Return Warning',
  [FORCE_DISPATCH_STATUS_PICKUP_WARNING]: 'Pickup Warning',
  [FORCE_DISPATCH_STATUS_INUSE_WARNING]: 'Inuse Warning',
  [FORCE_DISPATCH_STATUS_IDLE]: 'Idle',
  [RENTAL_STATUS_ASSIGNED]: 'Assigned',
  // [RENTAL_STATUS_PICKUP]: 'Pickup',
  [FORCE_DISPATCH_STATUS_PICKUP]: 'Pickup',
  // [FORCE_DISPATCH_STATUS_PICKUP_ARRIVED]: 'Pickup Arrived',
  [RENTAL_STATUS_PICKUP_ARRIVED]: 'Pickup Arrived',
  [RENTAL_STATUS_INUSE]: 'In Use',
};

export const TRANSLATE_FORCE_DISPATCH_STATUS_STRINGS = {
  [FORCE_DISPATCH_STATUS_RETURN]: 'constants.force_dispatch_status_return',
  [FORCE_DISPATCH_STATUS_RETURN_ARRIVED]: 'constants.force_dispatch_status_return_arrived',
  [FORCE_DISPATCH_STATUS_RETURN_WARNING]: 'constants.force_dispatch_status_return_warning',
  [FORCE_DISPATCH_STATUS_PICKUP_WARNING]: 'constants.force_dispatch_status_pickup_warning',
  [FORCE_DISPATCH_STATUS_INUSE_WARNING]: 'constants.force_dispatch_status_inuse_warning',
  [FORCE_DISPATCH_STATUS_IDLE]: 'constants.force_dispatch_status_idle',
  [RENTAL_STATUS_ASSIGNED]: 'constants.rental_status_assigned',
  // [RENTAL_STATUS_PICKUP]: 'constants.rental_status_pickup',
  [FORCE_DISPATCH_STATUS_PICKUP]: 'constants.force_dispatch_status_pickup',
  // [FORCE_DISPATCH_STATUS_PICKUP_ARRIVED]: 'constants.force_dispatch_status_pickup_arrived',
  [RENTAL_STATUS_PICKUP_ARRIVED]: 'constants.rental_status_pickup_arrived',
  [RENTAL_STATUS_INUSE]: 'constants.rental_stauts_inuse',
};
/* //force dispatch status */

/* discount type */
export enum COUPON_DISCOUNT_TYPE {
  RATE = 0,
  AMOUNT = 1,
  TARGET = 2,
}

export const DISCOUNT_TYPE_PERCENT = 0;
export const DISCOUNT_TYPE_FIXED_AMOUNT = 1;
export const DISCOUNT_TYPE_TARGET = 2;

export const ALL_DISCOUNT_TYPE = [DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_FIXED_AMOUNT, DISCOUNT_TYPE_TARGET];

export const DISCOUNT_TYPE_STRINGS = {
  [DISCOUNT_TYPE_PERCENT]: 'Percent',
  [DISCOUNT_TYPE_FIXED_AMOUNT]: 'Fixed Amount',
  [DISCOUNT_TYPE_TARGET]: 'Target Price',
};

export const TRANSLATE_DISCOUNT_TYPE_STRINGS: Record<number, string> = {
  [DISCOUNT_TYPE_PERCENT]: 'constants.discount_type.percent',
  [DISCOUNT_TYPE_FIXED_AMOUNT]: 'constants.discount_type.fixed_amount',
  [DISCOUNT_TYPE_TARGET]: 'constants.discount_type.target_price',
};
/* //discount type */

/* force dispatch warning type */
export const FORCE_DISPATCH_WARNING_TYPE_EXCEED_RETURN_TIME = -101;
export const FORCE_DISPATCH_WARNING_TYPE_IGNORE_RETURN_COMMAND = -105;
export const FORCE_DISPATCH_WARNING_TYPE_EXCEED_TRIP_TIME = -160;
export const FORCE_DISPATCH_WARNING_TYPE_EXCEED_PICKUP_TIME = -140;

export const FORCE_DISPATCH_WARNING_STRINGS = {
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_RETURN_TIME]: 'Return Warning',
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_PICKUP_TIME]: 'Pickup Warning',
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_TRIP_TIME]: 'Inuse Warning',
  [FORCE_DISPATCH_WARNING_TYPE_IGNORE_RETURN_COMMAND]: 'Return Warning',
};
export const FORCE_DISPATCH_WARNING_STRINGS_DESCRIPTION = {
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_RETURN_TIME]: 'Exceed Return Time',
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_PICKUP_TIME]: 'Exceed Pickup Time',
  [FORCE_DISPATCH_WARNING_TYPE_EXCEED_TRIP_TIME]: 'Exceed Trip Time',
  [FORCE_DISPATCH_WARNING_TYPE_IGNORE_RETURN_COMMAND]: 'Ignore Return Command',
};
/* //force dispatch warning type */

/* fixed surge rate */
export const FIXED_SURGE_TO_RATE: Record<string, number> = {
  SGD: 7.5,
  VND: 0.0,
  KHR: 0.0,
  THB: 7.5,
};
/* //fixed surge rate */

/* pay status */
export enum PAY_STATUS {
  PENDING = 0,
  HOLDING = 5,
  PAYING = 10,
  PAID = 20,
  REFUNDED = 90,
  CANCELED = 100,
  ERROR_PAYMENT = 900,
  ERROR_UNPAID = 910,
  PARTIAL_ERROR_PAYMENT = 920,
}

export const ALL_PAY_STATUS_STRINGS: Record<string, string> = {
  [PAY_STATUS.PENDING]: 'Pending',
  [PAY_STATUS.HOLDING]: 'Holding',
  [PAY_STATUS.PAYING]: 'Paying',
  [PAY_STATUS.PAID]: 'Paid',
  [PAY_STATUS.REFUNDED]: 'Refunded',
  [PAY_STATUS.CANCELED]: 'Canceled',
  [PAY_STATUS.ERROR_PAYMENT]: 'Error Payment',
  [PAY_STATUS.ERROR_UNPAID]: 'Error Unpaid',
  [PAY_STATUS.PARTIAL_ERROR_PAYMENT]: 'Partial Error Payment',
};

export const TRANSLATE_PAY_STATUS_STRINGS: Record<PAY_STATUS, string> = {
  [PAY_STATUS.PENDING]: 'constants.pay_status_pending',
  [PAY_STATUS.HOLDING]: 'constants.pay_status_holding',
  [PAY_STATUS.PAYING]: 'constants.pay_status_paying',
  [PAY_STATUS.PAID]: 'constants.pay_status_paid',
  [PAY_STATUS.REFUNDED]: 'constants.charge_status_refunded',
  [PAY_STATUS.CANCELED]: 'constants.pay_status_canceled',
  [PAY_STATUS.ERROR_PAYMENT]: 'constants.pay_status_error_payment',
  [PAY_STATUS.ERROR_UNPAID]: 'constants.pay_status_error_unpaid',
  [PAY_STATUS.PARTIAL_ERROR_PAYMENT]: 'constants.pay_status_partial_error_payment',
};

export const ALL_PAY_STATUS = [
  PAY_STATUS.PENDING,
  PAY_STATUS.HOLDING,
  PAY_STATUS.PAYING,
  PAY_STATUS.PAID,
  PAY_STATUS.REFUNDED,
  PAY_STATUS.CANCELED,
  PAY_STATUS.ERROR_PAYMENT,
  PAY_STATUS.ERROR_UNPAID,
  PAY_STATUS.PARTIAL_ERROR_PAYMENT,
];
/* //pay status */

// Dashboard Map 에서 OFFLINE 이어도 online으로 간주되는 상태들
// pickup, inuse 는 offline 이어도 pickup 혹은 inuse 관련 상태로 표시됨.
export const SD_STATUSES_REGARDED_ONLINE = [
  RENTAL_STATUS_ASSIGNED,
  RENTAL_STATUS_PICKUP,
  RENTAL_STATUS_PICKUP_ARRIVED,
  FORCE_DISPATCH_STATUS_PICKUP_WARNING,
  RENTAL_STATUS_INUSE,
  FORCE_DISPATCH_STATUS_INUSE_WARNING,
];

/* order from */
export const STREET_HAILING = 0;
export const TADA_NA = 1;
export const TADA_CONCIERGE = 2;

export const ORDER_FROM_TYPE = [STREET_HAILING, TADA_NA, TADA_CONCIERGE];

export const ORDER_FROM_TYPE_STRINGS: Record<number, string> = {
  [STREET_HAILING]: 'Street Hailing',
  [TADA_NA]: 'Tada Ride',
  [TADA_CONCIERGE]: 'Tada Concierge',
};

export const TRANSLATE_ORDER_FROM_TYPE_STRINGS: Record<number, string> = {
  [STREET_HAILING]: 'constants.street_hailing',
  [TADA_NA]: 'constants.tada_na',
  [TADA_CONCIERGE]: 'constants.tada_concierge',
};
/* //order from */

/* auto dispatch */
export const AUTO_DISPATCH_SD = 1;
export const AUTO_DISPATCH_AD = 2;
export const AUTO_DISPATCH_DRIVER_PING = 3;
export const AUTO_DISPATCH_MDT = 4;
export const AUTO_DISPATCH_MDD = 5; //MY_DESTINATION_DISPATCH

export const AUTO_DISPATCH_TYPE = [AUTO_DISPATCH_SD, AUTO_DISPATCH_AD, AUTO_DISPATCH_DRIVER_PING, AUTO_DISPATCH_MDD, AUTO_DISPATCH_MDT];

export const AUTO_DISPATCH_TYPE_STRING: Record<number, string> = {
  [AUTO_DISPATCH_SD]: 'System Dispatch',
  [AUTO_DISPATCH_AD]: 'Auto Dispatch',
  [AUTO_DISPATCH_DRIVER_PING]: 'Driver Ping',
  [AUTO_DISPATCH_MDT]: 'MDT',
  [AUTO_DISPATCH_MDD]: 'Driver Destination',
};

export const TRANSLATE_AUTO_DISPATCH_TYPE_STRING: Record<number, string> = {
  [AUTO_DISPATCH_SD]: 'constants.auto_dispatch_sd',
  [AUTO_DISPATCH_AD]: 'constants.auto_dispatch_ad',
  [AUTO_DISPATCH_DRIVER_PING]: 'constants.auto_dispatch_driver_ping',
  [AUTO_DISPATCH_MDT]: 'constants.auto_dispatch_mdt',
  [AUTO_DISPATCH_MDD]: 'destination_dispatch',
};
/* //auto dispatch */

/* fraud */
export enum FRAUD_TYPE {
  DROP_OFF_MISMATCH_WITH_DEST = 1,
  TOO_EARLY_FINISHED = 2,
  TOO_MANY_CANCEL_AFTER_MATCH = 3,
  SAME_DRIVER_RIDER = 4,
  SAME_IMEI = 5,
  TOO_MANY_FINISHED_TRIPS = 6,
  SAME_DEST_TRIP = 7,
  TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT = 8,
  TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI = 9,
  SAME_DRIVER_RIDER_PHONE_NUMBER = 10,
  COMPLETE_WITHOUT_PICK_UP = 11,
  TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT = 12,
  DRIVER_IGNORE_RIDE = 13,
  SAME_DRIVER_RIDER_PAIR_STRICT = 14,
  SYSTEM_FINISHED_TRIP = 15,
  ARRIVED_TOO_FAR_AWAY = 16,
}

export const ALL_FRAUD_TYPES = [
  FRAUD_TYPE.DROP_OFF_MISMATCH_WITH_DEST,
  FRAUD_TYPE.TOO_EARLY_FINISHED,
  FRAUD_TYPE.TOO_MANY_CANCEL_AFTER_MATCH,
  FRAUD_TYPE.SAME_DRIVER_RIDER,
  FRAUD_TYPE.SAME_IMEI,
  FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS,
  FRAUD_TYPE.SAME_DEST_TRIP,
  FRAUD_TYPE.TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT,
  FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI,
  FRAUD_TYPE.SAME_DRIVER_RIDER_PHONE_NUMBER,
  FRAUD_TYPE.COMPLETE_WITHOUT_PICK_UP,
  FRAUD_TYPE.TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT,
  FRAUD_TYPE.DRIVER_IGNORE_RIDE,
  FRAUD_TYPE.SAME_DRIVER_RIDER_PAIR_STRICT,
  FRAUD_TYPE.SYSTEM_FINISHED_TRIP,
  FRAUD_TYPE.ARRIVED_TOO_FAR_AWAY,
];

export const FRAUD_TYPE_STRINGS: Record<FRAUD_TYPE, string> = {
  [FRAUD_TYPE.DROP_OFF_MISMATCH_WITH_DEST]: 'DROP_OFF_MISMATCH_WITH_DEST',
  [FRAUD_TYPE.TOO_EARLY_FINISHED]: 'TOO_EARLY_FINISHED',
  [FRAUD_TYPE.TOO_MANY_CANCEL_AFTER_MATCH]: 'TOO_MANY_CANCEL_AFTER_MATCH',
  [FRAUD_TYPE.SAME_DRIVER_RIDER]: 'SAME_DRIVER_RIDER',
  [FRAUD_TYPE.SAME_IMEI]: 'SAME_IMEI',
  [FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS]: 'TOO_MANY_FINISHED_TRIPS',
  [FRAUD_TYPE.SAME_DEST_TRIP]: 'SAME_DEST_TRIP',
  [FRAUD_TYPE.TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT]: 'TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT',
  [FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI]: 'TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI',
  [FRAUD_TYPE.SAME_DRIVER_RIDER_PHONE_NUMBER]: 'SAME_DRIVER_RIDER_PHONE_NUMBER',
  [FRAUD_TYPE.COMPLETE_WITHOUT_PICK_UP]: 'COMPLETE_WITHOUT_PICK_UP',
  [FRAUD_TYPE.TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT]: 'TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT',
  [FRAUD_TYPE.DRIVER_IGNORE_RIDE]: 'DRIVER_IGNORE_RIDE',
  [FRAUD_TYPE.SAME_DRIVER_RIDER_PAIR_STRICT]: 'SAME_DRIVER_RIDER_PAIR_STRICT',
  [FRAUD_TYPE.SYSTEM_FINISHED_TRIP]: 'SYSTEM_FINISHED_TRIP',
  [FRAUD_TYPE.ARRIVED_TOO_FAR_AWAY]: 'ARRIVED_TOO_FAR_AWAY',
};

export const TRANSLATE_FRAUD_TYPE_STRINGS: Record<FRAUD_TYPE, string> = {
  [FRAUD_TYPE.DROP_OFF_MISMATCH_WITH_DEST]: 'constants.fraud_type_drop_off_mismatch_with_dest',
  [FRAUD_TYPE.TOO_EARLY_FINISHED]: 'constants.fraud_type_too_early_finished',
  [FRAUD_TYPE.TOO_MANY_CANCEL_AFTER_MATCH]: 'constants.fraud_type_too_many_cancel_after_match',
  [FRAUD_TYPE.SAME_DRIVER_RIDER]: 'constants.fraud_type_same_driver_rider',
  [FRAUD_TYPE.SAME_IMEI]: 'constants.fraud_type_same_imei',
  [FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS]: 'constants.fraud_type_too_many_finished_trips',
  [FRAUD_TYPE.SAME_DEST_TRIP]: 'constants.fraud_type_same_dest_trip',
  [FRAUD_TYPE.TOO_CLOSE_BETWEEN_PICKUP_AND_ASSIGNED_POINT]: 'constants.fraud_type_too_close_between_pickup_and_assigned_point',
  [FRAUD_TYPE.TOO_MANY_FINISHED_TRIPS_FOR_SAME_IMEI]: 'constants.fraud_type_too_many_finished_trips_for_same_imei',
  [FRAUD_TYPE.SAME_DRIVER_RIDER_PHONE_NUMBER]: 'constants.fraud_type_same_driver_rider_phone_number',
  [FRAUD_TYPE.COMPLETE_WITHOUT_PICK_UP]: 'constants.fraud_type_complete_without_pick_up',
  [FRAUD_TYPE.TOO_FAR_AWAY_BETWEEN_PICKUP_AND_CALL_POINT]: 'constants.fraud_type_too_far_away_between_pickup_and_call_point',
  [FRAUD_TYPE.DRIVER_IGNORE_RIDE]: 'constants.fraud_type_driver_ignore_ride',
  [FRAUD_TYPE.SAME_DRIVER_RIDER_PAIR_STRICT]: 'constants.fraud_type_same_driver_dider_pair_strict',
  [FRAUD_TYPE.SYSTEM_FINISHED_TRIP]: 'constants.fraud_type_system_finished_trip',
  [FRAUD_TYPE.ARRIVED_TOO_FAR_AWAY]: 'constants.fraud_type_arrived_too_far_away',
};
/* //fraud */

/* penalty */
export const PENALTY_TYPE_PING = 'ping';
export const PENALTY_TYPE_AUTO_DISPATCH = 'ad';

export const PENALTY_TYPE_ALL = [PENALTY_TYPE_PING, PENALTY_TYPE_AUTO_DISPATCH];

export const PENALTY_TYPE_STRINGS = {
  [PENALTY_TYPE_PING]: 'Ping',
  [PENALTY_TYPE_AUTO_DISPATCH]: 'Auto Dispatch',
};
/* //penalty */

/* invalid status driver */
export const DRIVER_INVALID_STATUS_WRONG = 'invalid_status';
export const DRIVER_INVALID_STATUS_UNSYNC = 'unsync_status';

export const DRIVER_ALL_INVALID_STATUS = [DRIVER_INVALID_STATUS_WRONG, DRIVER_INVALID_STATUS_UNSYNC];

export const DRIVER_INVALID_STATUS_STRING = {
  [DRIVER_INVALID_STATUS_WRONG]: 'Invalid Status',
  [DRIVER_INVALID_STATUS_UNSYNC]: 'Rental Status Unsynced',
};
/* //invalid status driver */

/* inbox item */
export enum INBOX_ITEM_TYPE {
  PUSH = 0,
  ANNOUNCEMENT = 1,
  INBOX_TUTORIAL = 2,
  ACTIONABLE_MODAL = 3,
  DRIVER_TUTORIAL = 4,
}

export const INBOX_ITEM_TYPES = [
  INBOX_ITEM_TYPE.PUSH,
  INBOX_ITEM_TYPE.ANNOUNCEMENT,
  INBOX_ITEM_TYPE.INBOX_TUTORIAL,
  INBOX_ITEM_TYPE.ACTIONABLE_MODAL,
  INBOX_ITEM_TYPE.DRIVER_TUTORIAL,
];

export const INBOX_ITEM_TYPE_STRINGS = {
  [INBOX_ITEM_TYPE.PUSH]: 'push',
  [INBOX_ITEM_TYPE.ANNOUNCEMENT]: 'announcement',
  [INBOX_ITEM_TYPE.INBOX_TUTORIAL]: 'Inbox Tutorial (Deprecated)',
  [INBOX_ITEM_TYPE.ACTIONABLE_MODAL]: 'Actionable Modal',
  [INBOX_ITEM_TYPE.DRIVER_TUTORIAL]: 'Driver Tutorial',
};

/* new cash out */
export enum NEW_CASH_OUT_STATUS {
  PENDING = 10,
  PAID = 20,
  CANCELED = 300,
  REJECTED = 900,
  FAILED = 999,
}

export const NEW_CASH_OUT_STATUS_STRINGS = {
  [NEW_CASH_OUT_STATUS.PENDING]: 'constants.new_cash_out_status.pending',
  [NEW_CASH_OUT_STATUS.PAID]: 'constants.new_cash_out_status.paid',
  [NEW_CASH_OUT_STATUS.CANCELED]: 'constants.new_cash_out_status.canceled',
  [NEW_CASH_OUT_STATUS.REJECTED]: 'constants.new_cash_out_status.rejected',
  [NEW_CASH_OUT_STATUS.FAILED]: 'constants.new_cash_out_status.failed',
};
/* //new cash out */

/* shb request history */
export enum SHB_REQUEST_HISTORY_TYPE {
  PAYMENT = 10,
  PAYMENT_CAPTURE = 15,
  TRANSFER = 20,
  CHARGE = 30,
  CHARGE_TRIP_FARE_ADMIN = 40,
  CHARGE_DISCOUNT_FARE_ADMIN = 50,
  PAYMENT_CANCEL = 910,
  PAYMENT_TIME_OUT_ERROR = 919,
  TRANSFER_CANCEL = 920,
  CHARGE_CANCEL = 930,
  CHARGE_TRIP_FARE_ADMIN_CACNEL = 940,
  CHARGE_DISCOUNT_FARE_ADMIN_CANCEL = 950,
}

export const ALL_SHB_REQUEST_HISTORY_TYPE = [
  SHB_REQUEST_HISTORY_TYPE.PAYMENT,
  SHB_REQUEST_HISTORY_TYPE.PAYMENT_CAPTURE,
  SHB_REQUEST_HISTORY_TYPE.TRANSFER,
  SHB_REQUEST_HISTORY_TYPE.CHARGE,
  SHB_REQUEST_HISTORY_TYPE.CHARGE_TRIP_FARE_ADMIN,
  SHB_REQUEST_HISTORY_TYPE.CHARGE_DISCOUNT_FARE_ADMIN,
  SHB_REQUEST_HISTORY_TYPE.PAYMENT_CANCEL,
  SHB_REQUEST_HISTORY_TYPE.PAYMENT_TIME_OUT_ERROR,
  SHB_REQUEST_HISTORY_TYPE.TRANSFER_CANCEL,
  SHB_REQUEST_HISTORY_TYPE.CHARGE_CANCEL,
  SHB_REQUEST_HISTORY_TYPE.CHARGE_TRIP_FARE_ADMIN_CACNEL,
  SHB_REQUEST_HISTORY_TYPE.CHARGE_DISCOUNT_FARE_ADMIN_CANCEL,
];

export const SHB_REQUEST_HISTORY_TYPE_STRINGS = {
  [SHB_REQUEST_HISTORY_TYPE.PAYMENT]: 'constants.shb_request_history_type.payment',
  [SHB_REQUEST_HISTORY_TYPE.PAYMENT_CAPTURE]: 'constants.shb_request_history_type.payment_capture',
  [SHB_REQUEST_HISTORY_TYPE.TRANSFER]: 'constants.shb_request_history_type.transfer',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE]: 'constants.shb_request_history_type.charge',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE_TRIP_FARE_ADMIN]: 'constants.shb_request_history_type.charge_trip_fare_admin',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE_DISCOUNT_FARE_ADMIN]: 'constants.shb_request_history_type.charge_discount_fare_admin',
  [SHB_REQUEST_HISTORY_TYPE.PAYMENT_CANCEL]: 'constants.shb_request_history_type.payment_cancel',
  [SHB_REQUEST_HISTORY_TYPE.PAYMENT_TIME_OUT_ERROR]: 'constants.shb_request_history_type.payment_time_out_error',
  [SHB_REQUEST_HISTORY_TYPE.TRANSFER_CANCEL]: 'constants.shb_request_history_type.transfer_cancel',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE_CANCEL]: 'constants.shb_request_history_type.charge_cancel',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE_TRIP_FARE_ADMIN_CACNEL]: 'constants.shb_request_history_type.charge_trip_fare_admin_cacnel',
  [SHB_REQUEST_HISTORY_TYPE.CHARGE_DISCOUNT_FARE_ADMIN_CANCEL]: 'constants.shb_request_history_type.charge_discount_fare_admin_cancel',
};

export enum SHB_REQUEST_HISTORY_ACCOUNT_TYPE {
  USER = 10,
  DRIVER = 20,
}

export const ALL_SHB_REQUEST_HISTORY_ACCOUNT_TYPES = [SHB_REQUEST_HISTORY_ACCOUNT_TYPE.DRIVER, SHB_REQUEST_HISTORY_ACCOUNT_TYPE.USER];

export const SHB_REQUEST_HISTORY_ACCOUNT_TYPE_STRINGS = {
  [SHB_REQUEST_HISTORY_ACCOUNT_TYPE.USER]: 'constants.shb_request_history_account_type.user',
  [SHB_REQUEST_HISTORY_ACCOUNT_TYPE.DRIVER]: 'constants.shb_request_history_account_type.driver',
};
/* //shb request history */

/* hired dispatch config type */
export const HIRED_DISPATCH_CONFIG_TYPE_SEQUENCE_DISPATCH = 'seqd';
export const HIRED_DISPATCH_CONFIG_TYPE_NORMAL = 'normal';

export enum HIRED_DISPATCH_CONFIG_TYPE {
  SEQUENCE_DISPATCH = 'seqd',
  NORMAL = 'normal',
}

export const ALL_HIRED_DISPATCH_CONFIG_TYPE = [HIRED_DISPATCH_CONFIG_TYPE_SEQUENCE_DISPATCH, HIRED_DISPATCH_CONFIG_TYPE_NORMAL];

export const HIRED_DISPATCH_CONFIG_TYPE_STRINGS: Record<string, string> = {
  [HIRED_DISPATCH_CONFIG_TYPE_SEQUENCE_DISPATCH]: 'Sequence Dispatch',
  [HIRED_DISPATCH_CONFIG_TYPE_NORMAL]: 'Normal',
};
/* //hired dispatch config type */

/* h3resolution */
export enum H3Resolution {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
}

/* //h3resolution */

/* hourly rental request status */
export enum HOURLY_RENTAL_REQUEST_STATUS {
  APPROVED = 10,
  USING = 20,
  EXPIRED = 100,
  REJECTED = 900,
}

export const ALL_HOURLY_RENTAL_REQUEST_STATUS = [
  HOURLY_RENTAL_REQUEST_STATUS.APPROVED,
  HOURLY_RENTAL_REQUEST_STATUS.USING,
  HOURLY_RENTAL_REQUEST_STATUS.EXPIRED,
  HOURLY_RENTAL_REQUEST_STATUS.REJECTED,
];

export const HOURLY_RENTAL_REQUEST_STATUS_STRINGS = {
  [HOURLY_RENTAL_REQUEST_STATUS.APPROVED]: 'Approved',
  [HOURLY_RENTAL_REQUEST_STATUS.USING]: 'Using',
  [HOURLY_RENTAL_REQUEST_STATUS.EXPIRED]: 'Expired',
  [HOURLY_RENTAL_REQUEST_STATUS.REJECTED]: 'Rejected',
};
/* //hourly rental request status */

/* car color */
export enum CAR_COLORS {
  BLACK = 'black',
  WHITE = 'white',
  SILVER = 'silver',
  GREY = 'grey',
  BLUE = 'blue',
  RED = 'red',
  GREEN = 'green',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  YELLOW = 'yellow',
  BRONZE = 'bronze',
  BROWN = 'brown',
  GOLD = 'gold',
}

export const ALL_CAR_COLORS = [
  CAR_COLORS.BLACK,
  CAR_COLORS.WHITE,
  CAR_COLORS.SILVER,
  CAR_COLORS.GREY,
  CAR_COLORS.BLUE,
  CAR_COLORS.RED,
  CAR_COLORS.GREEN,
  CAR_COLORS.ORANGE,
  CAR_COLORS.PINK,
  CAR_COLORS.PURPLE,
  CAR_COLORS.YELLOW,
  CAR_COLORS.BRONZE,
  CAR_COLORS.BROWN,
  CAR_COLORS.GOLD,
];

export const CAR_COLORS_STRINGS: Record<CAR_COLORS | string, string> = {
  [CAR_COLORS.BLACK]: 'Black',
  [CAR_COLORS.WHITE]: 'White',
  [CAR_COLORS.SILVER]: 'Silver',
  [CAR_COLORS.GREY]: 'Grey',
  [CAR_COLORS.BLUE]: 'Blue',
  [CAR_COLORS.RED]: 'Red',
  [CAR_COLORS.GREEN]: 'Green',
  [CAR_COLORS.ORANGE]: 'Orange',
  [CAR_COLORS.PINK]: 'Pink',
  [CAR_COLORS.PURPLE]: 'Purple',
  [CAR_COLORS.YELLOW]: 'Yellow',
  [CAR_COLORS.BRONZE]: 'Bronze',
  [CAR_COLORS.BROWN]: 'Brown',
  [CAR_COLORS.GOLD]: 'Gold',
  [CAR_COLORS.BLACK.toUpperCase()]: 'Black',
  [CAR_COLORS.WHITE.toUpperCase()]: 'White',
  [CAR_COLORS.SILVER.toUpperCase()]: 'Silver',
  [CAR_COLORS.GREY.toUpperCase()]: 'Grey',
  [CAR_COLORS.BLUE.toUpperCase()]: 'Blue',
  [CAR_COLORS.RED.toUpperCase()]: 'Red',
  [CAR_COLORS.GREEN.toUpperCase()]: 'Green',
  [CAR_COLORS.ORANGE.toUpperCase()]: 'Orange',
  [CAR_COLORS.PINK.toUpperCase()]: 'Pink',
  [CAR_COLORS.PURPLE.toUpperCase()]: 'Purple',
  [CAR_COLORS.YELLOW.toUpperCase()]: 'Yellow',
  [CAR_COLORS.BRONZE.toUpperCase()]: 'Bronze',
  [CAR_COLORS.BROWN.toUpperCase()]: 'Brown',
  [CAR_COLORS.GOLD.toUpperCase()]: 'Gold',
};
/* //car color */

/* hourly rental request car type */
export enum HOURLY_RENTAL_REQUEST_CAR_TYPE {
  SEDAN = 0, // Economy
  VAN = 1, // Economy Large
  BUSINESS_VAN = 4, // Premium Large
  BLACK_SEDAN = 13, // Premium
}

export const ALL_HOURLY_RENTAL_REQUEST_CAR_TYPE = [
  HOURLY_RENTAL_REQUEST_CAR_TYPE.SEDAN,
  HOURLY_RENTAL_REQUEST_CAR_TYPE.VAN,
  HOURLY_RENTAL_REQUEST_CAR_TYPE.BUSINESS_VAN,
  HOURLY_RENTAL_REQUEST_CAR_TYPE.BLACK_SEDAN,
];
/* //hourly rental request car type */

/* hourly rental company */
export enum HOURLY_RENTAL_COMPANY {
  TRIBE_CAR = 'TRIBECAR',
  SHARIOT = 'SHARIOT',
  LUMENS = 'LUMENS',
}

export const ALL_HOURLY_RENTAL_COMPANY = [HOURLY_RENTAL_COMPANY.TRIBE_CAR, HOURLY_RENTAL_COMPANY.SHARIOT, HOURLY_RENTAL_COMPANY.LUMENS];

export const HOURLY_RENTAL_COMPANY_STRINGS = {
  [HOURLY_RENTAL_COMPANY.TRIBE_CAR]: 'Tribecar',
  [HOURLY_RENTAL_COMPANY.SHARIOT]: 'Shariot',
  [HOURLY_RENTAL_COMPANY.LUMENS]: 'Lumens',
};
/* //hourly rental company */

/* dispatch type */
export const DISPATCH_TYPE_RENTAL = 'Rental';
export const DISPATCH_TYPE_RIDE = 'Ride';
export const DISPATCH_TYPE_DELIVERY = 'Delivery';

export enum DISPATCH_TYPE {
  RENTAL = 'Rental',
  RIDE = 'Ride',
  DELIVERY = 'Delivery',
}

export const ALL_DISPATCH_TYPE = [DISPATCH_TYPE_RENTAL, DISPATCH_TYPE_RIDE, DISPATCH_TYPE_DELIVERY];
/* //dispatch type */

/* banner type */
export enum BANNER_TYPE {
  ALWAYS_DISPLAYED = 'always_displayed',
  COUPON_FROM_PROMO_ISSUED = 'coupon_from_promo_issued',
  TEXT = 'text',
}

export const ALL_BANNER_TYPE = [BANNER_TYPE.ALWAYS_DISPLAYED, BANNER_TYPE.COUPON_FROM_PROMO_ISSUED, BANNER_TYPE.TEXT];

export const USING_BANNER_TYPE = removeDeprecatedType(ALL_BANNER_TYPE, BANNER_TYPE.COUPON_FROM_PROMO_ISSUED);

export const BANNER_TYPE_STRINGS = {
  [BANNER_TYPE.ALWAYS_DISPLAYED]: 'constants.always_displayed',
  [BANNER_TYPE.COUPON_FROM_PROMO_ISSUED]: 'constants.coupon_from_promo_issued',
  [BANNER_TYPE.TEXT]: 'Text',
};

/* //banner type */

/* view content type */
export enum VIEW_CONTENT_TYPE {
  URL = 'url',
  IMAGE = 'image',
  HTML = 'html',
  SCHEME = 'scheme',
  TEXT = 'text',
}

export const ALL_VIEW_CONTENT_TYPE = [
  VIEW_CONTENT_TYPE.URL,
  VIEW_CONTENT_TYPE.IMAGE,
  VIEW_CONTENT_TYPE.HTML,
  VIEW_CONTENT_TYPE.SCHEME,
  VIEW_CONTENT_TYPE.TEXT,
];

export const VIEW_CONTENT_TYPE_STRINGS = {
  [VIEW_CONTENT_TYPE.URL]: 'url',
  [VIEW_CONTENT_TYPE.IMAGE]: 'image',
  [VIEW_CONTENT_TYPE.HTML]: 'html',
  [VIEW_CONTENT_TYPE.SCHEME]: 'scheme',
  [VIEW_CONTENT_TYPE.TEXT]: 'text',
};
/* //view content type */

/* dynamic surge car type */
export const CAR_GROUP = {
  CAR: 0,
  BIKE: 1,
  TUKTUK: 2,
  FOOT: 3,
  BUS: 4,
} as const;

export type CarGroup = valueOf<typeof CAR_GROUP>;

export const ALL_DYNAMIC_SURGE_CAR_GROUP = Object.values(CAR_GROUP);

export const DYNAMIC_SURGE_CAR_GROUP_STRINGS: Record<number, string> = {
  [CAR_GROUP.CAR]: 'Car',
  [CAR_GROUP.BIKE]: 'Bike',
  [CAR_GROUP.TUKTUK]: 'TukTuk',
  [CAR_GROUP.FOOT]: 'Foot',
  [CAR_GROUP.BUS]: 'Bus',
};

export const CAR_GROUP_BY_REGION: Record<string, number[]> = {
  [REGION.SG]: ALL_DYNAMIC_SURGE_CAR_GROUP,
  [REGION.KH]: [CAR_GROUP.CAR, CAR_GROUP.TUKTUK],
  [REGION.VN]: ALL_DYNAMIC_SURGE_CAR_GROUP,
  [REGION.TH]: ALL_DYNAMIC_SURGE_CAR_GROUP,
  [REGION.HK]: ALL_DYNAMIC_SURGE_CAR_GROUP,
};
/* //dynamic surge car type */

/* deposit status */
export enum DEPOSIT_STATUS {
  NONE = 0, // no deposit holded
  DEPRECATED_CASH_RETURN = 1, // DEPRECATED: old cash return type
  DEPRECATED_HOLDING = 2, // DEPRECATED: old balance holded type

  // CASH_RETURN = 10, // no balance hold, but cash should be returned
  CASH_PAY_THROUGH = 10, // no balance hold, but driver should pay for it
  BALANCE_HOLDED = 1000, // holding balance
}

export const ALL_DEPOSIT_STATUS = [
  DEPOSIT_STATUS.NONE,
  DEPOSIT_STATUS.DEPRECATED_CASH_RETURN,
  DEPOSIT_STATUS.DEPRECATED_HOLDING,
  // DEPOSIT_STATUS.CASH_RETURN,
  DEPOSIT_STATUS.CASH_PAY_THROUGH,
  DEPOSIT_STATUS.BALANCE_HOLDED,
];

export const DEPOSIT_STATUS_STRINGS = {
  [DEPOSIT_STATUS.NONE]: 'None',
  [DEPOSIT_STATUS.DEPRECATED_CASH_RETURN]: 'Deprecated Cash Return',
  [DEPOSIT_STATUS.DEPRECATED_HOLDING]: 'Deprecated Holding',
  // [DEPOSIT_STATUS.CASH_RETURN]: 'Cash Return',
  [DEPOSIT_STATUS.CASH_PAY_THROUGH]: 'Cash Pay Through',
  [DEPOSIT_STATUS.BALANCE_HOLDED]: 'Balance Holded',
};
/* //deposit status */

/* ride type */
export enum RIDE_TYPE {
  APP_METER_RIDE = 0,
  CAR_METER_RIDE = 1,
  BASE_RIDE = 10,
  ADVANCED_BOOKING = 20,
  DELIVERY = 100,
  DOD_RIDE = 200,
  VALET_RIDE = 201,
}

export const ALL_RIDE_TYPE = [
  RIDE_TYPE.APP_METER_RIDE,
  RIDE_TYPE.CAR_METER_RIDE,
  RIDE_TYPE.BASE_RIDE,
  RIDE_TYPE.ADVANCED_BOOKING,
  RIDE_TYPE.DELIVERY,
];

export const CORP_AVAILABLE_RIDE_TYPE = [RIDE_TYPE.BASE_RIDE, RIDE_TYPE.DELIVERY];

export const RIDE_TYPE_STRINGS: Record<RIDE_TYPE, string> = {
  [RIDE_TYPE.APP_METER_RIDE]: 'App Meter Ride',
  [RIDE_TYPE.CAR_METER_RIDE]: 'Car Meter Ride',
  [RIDE_TYPE.BASE_RIDE]: 'Base Ride',
  [RIDE_TYPE.ADVANCED_BOOKING]: 'Advanced Booking',
  [RIDE_TYPE.DELIVERY]: 'Delivery',
  [RIDE_TYPE.DOD_RIDE]: 'Goorm Ride',
  [RIDE_TYPE.VALET_RIDE]: 'Goorm Valet Ride',
};

export const TRANSLATE_RIDE_TYPE_STRINGS: Record<RIDE_TYPE, string> = {
  [RIDE_TYPE.APP_METER_RIDE]: 'constants.ride_type_app_meter_ride',
  [RIDE_TYPE.CAR_METER_RIDE]: 'constants.ride_type_car_meter_ride',
  [RIDE_TYPE.BASE_RIDE]: 'constants.ride_type_base_ride',
  [RIDE_TYPE.ADVANCED_BOOKING]: 'constants.ride_type_advanced_booking',
  [RIDE_TYPE.DELIVERY]: 'constants.ride_type_delivery',
  [RIDE_TYPE.DOD_RIDE]: 'constants.ride_type_dod_ride',
  [RIDE_TYPE.VALET_RIDE]: 'constants.ride_type_valet_ride',
};
/* //ride type */

/* driving status */
export enum DrivingStatus {
  INUSE_WARNING = -160,
  PICKUP_WARNING = -140,
  RETURN_WARNING = -101,
  RETURN_ARRIVED = -5,
  RETURN = -1,
  IDLE = 0,
  ASSIGNED = 40,
  PICKUP = 50,
  PICKUP_ARRIVED = 55,
  INUSE = 60, // ride 하나 수행중
  INUSEB2B = 65, // b2b잡 수행 중
}

export const ALL_DRIVING_STATUS = [
  DrivingStatus.INUSE_WARNING,
  DrivingStatus.PICKUP_WARNING,
  DrivingStatus.RETURN_WARNING,
  DrivingStatus.RETURN_ARRIVED,
  DrivingStatus.RETURN,
  DrivingStatus.IDLE,
  DrivingStatus.ASSIGNED,
  DrivingStatus.PICKUP,
  DrivingStatus.PICKUP_ARRIVED,
  DrivingStatus.INUSE,
  DrivingStatus.INUSEB2B,
];

export const DRIVING_STATUS_STRINGS: Record<DrivingStatus, string> = {
  [DrivingStatus.INUSE_WARNING]: 'Inuse Warning',
  [DrivingStatus.PICKUP_WARNING]: 'Pickup Warning',
  [DrivingStatus.RETURN_WARNING]: 'Return Warning',
  [DrivingStatus.RETURN_ARRIVED]: 'Return Arrived',
  [DrivingStatus.RETURN]: 'Return',
  [DrivingStatus.IDLE]: 'Idle',
  [DrivingStatus.ASSIGNED]: 'Assigned',
  [DrivingStatus.PICKUP]: 'Pickup',
  [DrivingStatus.PICKUP_ARRIVED]: 'Pickup Arrived',
  [DrivingStatus.INUSE]: 'Inuse',
  [DrivingStatus.INUSEB2B]: 'Inuse b2b',
};
/* //driving status */

/* penalty */
export enum PENALTY_ACTION_TYPE {
  IMPOSED = 'imposed',
  TIME_EXTENDED = 'time_extended',
  RELEASED = 'released',
}

export const PENALTY_ACTION_TYPE_STRINGS = {
  [PENALTY_ACTION_TYPE.IMPOSED]: 'Imposed',
  [PENALTY_ACTION_TYPE.TIME_EXTENDED]: 'Time Extended',
  [PENALTY_ACTION_TYPE.RELEASED]: 'Released',
};

export enum PENALTY_TYPE {
  RIDE_CREATE_PENALTY = 'RideCreatePenalty',
  SYSTEM_DISPATCH_PENALTY = 'SystemDispatchPenalty',
  AUTO_DISPATCH_PENALTY = 'AutoDispatchPenalty',
  PRIORITY_DISPATCH_PENALTY = 'PriorityDispatchPenalty',
  SEQUENCE_DISPATCH_PENALTY = 'SequenceDispatchPenalty',
  NORMAL_DISPATCH_PENALTY = 'NormalDispatchPenalty',
  DRIVER_STATUS_UPDATE_PENALTY = 'DriverStatusUpdatePenalty',
  AUTO_DISPATCH_DRIVER_STATUS_UPDATE_PENALTY = 'AutoDispatchDriverStatusUpdatePenalty',
  RIDE_DISPATCH_PENALTY = 'RideDispatchPenalty',
  CREDITCARD_ONLY_DISPATCH = 'CreditcardOnlyDispatchPenalty',
  DELIVERY_DISPATCH = 'DeliveryDispatchPenalty',
  FRAUD_FEE = 'FraudFee',
  ADVANCED_DISPATCH = 'AdvancedDispatchPenalty',
  CASHOUT = 'CashoutPenalty',
  WITHDRAW = 'WithdrawPenalty',
  BAN_FROM_ALL_DRIVER_MISSION = 'BanFromAllDriverMissionPenalty',
  ACCOUNT_ON_HOLD = 'AccountOnHoldPenalty',
  ACCIDENT = 'AccidentPenalty',
  RIDER_PROMO_PENALTY = 'RiderPromoPenalty',
  DRIVER_PROMO_PENALTY = 'DriverPromoPenalty',
}

export const ALL_PENALTY_TYPES = Object.values(PENALTY_TYPE);

export const RIDER_PENALTY_TYPES = [PENALTY_TYPE.RIDER_PROMO_PENALTY, PENALTY_TYPE.RIDE_CREATE_PENALTY, PENALTY_TYPE.RIDE_DISPATCH_PENALTY];

export const DRIVER_PENALTY_TYPES = [
  PENALTY_TYPE.DRIVER_PROMO_PENALTY,
  PENALTY_TYPE.ACCOUNT_ON_HOLD,
  PENALTY_TYPE.SYSTEM_DISPATCH_PENALTY,
  PENALTY_TYPE.AUTO_DISPATCH_PENALTY,
  PENALTY_TYPE.PRIORITY_DISPATCH_PENALTY,
  PENALTY_TYPE.SEQUENCE_DISPATCH_PENALTY,
  PENALTY_TYPE.NORMAL_DISPATCH_PENALTY,
  PENALTY_TYPE.DRIVER_STATUS_UPDATE_PENALTY,
  PENALTY_TYPE.AUTO_DISPATCH_DRIVER_STATUS_UPDATE_PENALTY,
  PENALTY_TYPE.CREDITCARD_ONLY_DISPATCH,
  PENALTY_TYPE.DELIVERY_DISPATCH,
  PENALTY_TYPE.ADVANCED_DISPATCH,
  PENALTY_TYPE.CASHOUT,
  PENALTY_TYPE.WITHDRAW,
  PENALTY_TYPE.BAN_FROM_ALL_DRIVER_MISSION,
  PENALTY_TYPE.ACCIDENT,
];

export const PENALTY_TYPES_STRINGS = {
  [PENALTY_TYPE.RIDE_CREATE_PENALTY]: 'Ride Create Penalty',
  [PENALTY_TYPE.SYSTEM_DISPATCH_PENALTY]: 'System Dispatch Penalty',
  [PENALTY_TYPE.AUTO_DISPATCH_PENALTY]: 'Auto Dispatch Penalty',
  [PENALTY_TYPE.PRIORITY_DISPATCH_PENALTY]: 'Priority Dispatch Penalty',
  [PENALTY_TYPE.SEQUENCE_DISPATCH_PENALTY]: 'Sequence Dispatch Penalty',
  [PENALTY_TYPE.NORMAL_DISPATCH_PENALTY]: 'Normal Dispatch Penalty',
  [PENALTY_TYPE.DRIVER_STATUS_UPDATE_PENALTY]: 'Driver Status Update Penalty',
  [PENALTY_TYPE.AUTO_DISPATCH_DRIVER_STATUS_UPDATE_PENALTY]: 'Auto Dispatch Driver Status Update Penalty',
  [PENALTY_TYPE.RIDE_DISPATCH_PENALTY]: 'Ride Dispatch Penalty',
  [PENALTY_TYPE.CREDITCARD_ONLY_DISPATCH]: 'Credit Card only Dispatch',
  [PENALTY_TYPE.DELIVERY_DISPATCH]: 'Delivery Dispatch Penalty',
  [PENALTY_TYPE.FRAUD_FEE]: 'Penalty Fee Charge',
  [PENALTY_TYPE.ADVANCED_DISPATCH]: 'Advanced Dispatch',
  [PENALTY_TYPE.CASHOUT]: 'Cash Out',
  [PENALTY_TYPE.WITHDRAW]: 'With Draw',
  [PENALTY_TYPE.BAN_FROM_ALL_DRIVER_MISSION]: 'Ban From All Driver Mission',
  [PENALTY_TYPE.ACCOUNT_ON_HOLD]: 'Account On-Hold Penalty',
  [PENALTY_TYPE.ACCIDENT]: 'Accident Penalty',
  [PENALTY_TYPE.RIDER_PROMO_PENALTY]: 'Rider Promo Penalty',
  [PENALTY_TYPE.DRIVER_PROMO_PENALTY]: 'Driver Promo Penalty',
};

export enum PENALTY_USER_TYPE {
  DRIVER = 'Driver',
  RIDER = 'Rider',
}

export enum PENALTY_REASON_TYPE {
  RIDER_CANCEL_TOO_MANY_TIMES = 'RIDER_CANCEL_TOO_MANY_TIMES',
  DRIVER_GOT_TOO_MANY_CANCELLATION = 'DRIVER_GOT_TOO_MANY_CANCELLATION',
  DRIVER_COMMITTED_FRAUDS = 'DRIVER_COMMITTED_FRAUDS',
  DRIVER_CANCEL_TOO_MANY_TIMES = 'DRIVER_CANCEL_TOO_MANY_TIMES',
  DRIVER_CANCEL_AUTO_DISPATCH = 'DRIVER_CANCEL_AUTO_DISPATCH',
  DRIVER_BALANCE_TOO_LOW = 'DRIVER_BALANCE_TOO_LOW',
  ADMIN = 'ADMIN',
  FINE_PAYMENT_DELAYED = 'FINE_PAYMENT_DELAYED',
  AUTO_CLICKER_DETECTED = 'AUTO_CLICKER_DETECTED',
  FEE_RELATED_FRAUD_COMMITTED = 'FEE_RELATED_FRAUD_COMMITTED',
  DRIVER_RESERVATION_CANCEL = 'DRIVER_RESERVATION_CANCEL',
  SYSTEM = 'SYSTEM ',
}

export const ALL_PENALTY_REASON_TYPES = Object.values(PENALTY_REASON_TYPE);

export const PENALTY_REASON_TYPE_STRINGS: { [key in PENALTY_REASON_TYPE]: string } = {
  [PENALTY_REASON_TYPE.RIDER_CANCEL_TOO_MANY_TIMES]: 'Rider Cancel Too Many Times',
  [PENALTY_REASON_TYPE.DRIVER_GOT_TOO_MANY_CANCELLATION]: 'Driver Got Too Many Cancellation',
  [PENALTY_REASON_TYPE.DRIVER_COMMITTED_FRAUDS]: 'Driver Committed Frauds',
  [PENALTY_REASON_TYPE.DRIVER_CANCEL_TOO_MANY_TIMES]: 'Driver Cancel Too Many Times',
  [PENALTY_REASON_TYPE.DRIVER_CANCEL_AUTO_DISPATCH]: 'Driver Cancel Auto Dispatch',
  [PENALTY_REASON_TYPE.DRIVER_BALANCE_TOO_LOW]: 'Driver Balance Too Low',
  [PENALTY_REASON_TYPE.ADMIN]: 'Admin',
  [PENALTY_REASON_TYPE.FINE_PAYMENT_DELAYED]: 'Fine Payment Delayed',
  [PENALTY_REASON_TYPE.AUTO_CLICKER_DETECTED]: 'Auto Clicker Detected',
  [PENALTY_REASON_TYPE.FEE_RELATED_FRAUD_COMMITTED]: 'Fee Related Fraud Committed',
  [PENALTY_REASON_TYPE.DRIVER_RESERVATION_CANCEL]: 'Driver Reservation cancel',
  [PENALTY_REASON_TYPE.SYSTEM]: 'System',
};

/* //penalty */

export enum USER_MODEL {
  Driver = 'Driver',
  Rider = 'Rider',
  DeliveryVendor = 'DeliveryVendor',
  DeliveryAgent = 'DeliveryAgent',
  CorpManager = 'CorpManager',
}

export const USER_MODEL_STRINGS = {
  [USER_MODEL.Driver]: 'constants.user_model.driver',
  [USER_MODEL.Rider]: 'constants.user_model.rider',
  [USER_MODEL.DeliveryVendor]: 'constants.user_model.deliveryvendor',
  [USER_MODEL.DeliveryAgent]: 'constants.user_model.delivery_agent',
  [USER_MODEL.CorpManager]: 'constants.user_model.corp_manager',
};

export const ALL_USER_MODEL = [USER_MODEL.Driver, USER_MODEL.Rider, USER_MODEL.DeliveryVendor, USER_MODEL.DeliveryAgent];

export const PENALTY_FORM_USER_MODEL_LIST = [USER_MODEL.Driver, USER_MODEL.Rider];

/* //account user model */

export enum ACCOUNT_DETAIL_PAGE_TYPE {
  CURRENT_ACCOUNT = 'Current Account',
  DEPOSIT_ACCOUNT = 'Deposit Account',
}

export const ACCOUNT_DETAIL_PAGE_TYPE_STRINGS = {
  [ACCOUNT_DETAIL_PAGE_TYPE.CURRENT_ACCOUNT]: 'constants.account_detail_page_type.current_account',
  [ACCOUNT_DETAIL_PAGE_TYPE.DEPOSIT_ACCOUNT]: 'constants.account_detail_page_type.deposit_account',
};

/* review filter type */
export const RIDE_MODEL_TYPE = {
  RIDE: 'Ride',
  RENTAL: 'Rental',
};

export const REVIEW_ENTITY_TYPE = {
  REVIEWER: 'Reviewer',
  REVIEWEE: 'Reviewee',
  ALL: 'All',
};

export const USER_MODEL_TYPE = {
  DRIVER: 'Driver',
  RIDER: 'Rider',
  ALL: 'All',
};
/* //review filter type */

/* additional fare */
export const ADDITIONAL_FARE_OPTIONS_MAX = 3;
/* //additional fare */

/* fare */
export enum FARE_TYPE {
  APP_METERED_FARE = 0, // 앱에서 전송하는 gps를 통해 가격을 측정하는 상품
  CAR_METERED_FARE = 1, // 차에 있는 미터기를 통해 가격을 입력 받는 상품
  FIXED_FARE = 2, // destination이 있어서 미리 estimation 한 가격으로 만드는 상품
}

export const ALL_FARE_TYPE = [FARE_TYPE.APP_METERED_FARE, FARE_TYPE.CAR_METERED_FARE, FARE_TYPE.FIXED_FARE];

export const FARE_TYPE_STRINGS: Record<FARE_TYPE, string> = {
  [FARE_TYPE.APP_METERED_FARE]: 'App Metered Fare',
  [FARE_TYPE.CAR_METERED_FARE]: 'Car Metered Fare',
  [FARE_TYPE.FIXED_FARE]: 'Fixed Fare',
};

export const TRANSLATE_FARE_TYPE_STRINGS: Record<FARE_TYPE, string> = {
  [FARE_TYPE.APP_METERED_FARE]: 'constants.fare_type_app_metered_fare',
  [FARE_TYPE.CAR_METERED_FARE]: 'constants.fare_type_car_metered_fare',
  [FARE_TYPE.FIXED_FARE]: 'constants.fare_type_fixed_fare',
};
/* //fare */

/* ride option */
export enum RIDE_OPTION_TYPE {
  SMART_CALL = 0,
  CAR_SEAT = 1,
  BOOSTER_SEAT = 2,
  CHANGE_OF_DESTINATION = 3,
  FUEL_SURCHARGE = 4,
  WAITING_FEE = 5,
  AIRPORT_FEE = 6,
  PET_CARRIER = 7,
  PET_LEASHED = 8,
  WHEELCHAIR_SPACE = 9,
  ETC = 999,
}

export const ALL_RIDE_OPTION_TYPES = [
  RIDE_OPTION_TYPE.SMART_CALL,
  RIDE_OPTION_TYPE.CAR_SEAT,
  RIDE_OPTION_TYPE.BOOSTER_SEAT,
  RIDE_OPTION_TYPE.CHANGE_OF_DESTINATION,
  RIDE_OPTION_TYPE.FUEL_SURCHARGE,
  RIDE_OPTION_TYPE.WAITING_FEE,
  RIDE_OPTION_TYPE.AIRPORT_FEE,
  RIDE_OPTION_TYPE.PET_CARRIER,
  RIDE_OPTION_TYPE.PET_LEASHED,
  RIDE_OPTION_TYPE.WHEELCHAIR_SPACE,
];

const ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR = ALL_RIDE_OPTION_TYPES.filter((type) => type !== RIDE_OPTION_TYPE.WHEELCHAIR_SPACE);

export const ALL_RIDE_OPTION_TYPES_BY_REGION: Record<string, RIDE_OPTION_TYPE[]> = {
  [REGION.SG]: ALL_RIDE_OPTION_TYPES,
  [REGION.KH]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.VN]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.TH]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.HK]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.ET]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.ID]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.KR]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
  [REGION.GLOBAL]: ALL_RIDE_OPTION_TYPES_WITHOUT_WHEELCHAIR,
};

export const RIDE_OPTION_TYPE_STRINGS: Record<RIDE_OPTION_TYPE, string> = {
  [RIDE_OPTION_TYPE.SMART_CALL]: 'Smart Call',
  [RIDE_OPTION_TYPE.CAR_SEAT]: 'Car Seat',
  [RIDE_OPTION_TYPE.BOOSTER_SEAT]: 'Booster Seat',
  [RIDE_OPTION_TYPE.CHANGE_OF_DESTINATION]: 'Change Of Destination',
  [RIDE_OPTION_TYPE.FUEL_SURCHARGE]: 'Fuel Surcharge',
  [RIDE_OPTION_TYPE.WAITING_FEE]: 'Waiting Fee',
  [RIDE_OPTION_TYPE.AIRPORT_FEE]: 'Airport Fee',
  [RIDE_OPTION_TYPE.PET_CARRIER]: 'Pet (Carrier)',
  [RIDE_OPTION_TYPE.PET_LEASHED]: 'Pet (Leashed)',
  [RIDE_OPTION_TYPE.WHEELCHAIR_SPACE]: 'Wheelchair Space',
  [RIDE_OPTION_TYPE.ETC]: 'Others',
};

/* //ride option */

/* charge status */
export enum CHARGE_STATUS {
  PENDING = 0,
  UNCAPTURED = 10,
  SUCCEEDED = 20,
  REFUNDED = 90,
  ERROR = 900,
}

export const CHARGE_STATUS_STRINGS: Record<CHARGE_STATUS, string> = {
  [CHARGE_STATUS.PENDING]: 'Pending',
  [CHARGE_STATUS.UNCAPTURED]: 'Uncaptured',
  [CHARGE_STATUS.SUCCEEDED]: 'Successded',
  [CHARGE_STATUS.REFUNDED]: 'Refunded',
  [CHARGE_STATUS.ERROR]: 'Error',
};

export const TRANSLATE_CHARGE_STATUS_STRINGS: Record<CHARGE_STATUS, string> = {
  [CHARGE_STATUS.PENDING]: 'constants.charge_status_pending',
  [CHARGE_STATUS.UNCAPTURED]: 'constants.charge_status_uncaptured',
  [CHARGE_STATUS.SUCCEEDED]: 'constants.charge_status_succeeded',
  [CHARGE_STATUS.REFUNDED]: 'constants.charge_status_refunded',
  [CHARGE_STATUS.ERROR]: 'constants.charge_status_error',
};
/* //charge status */

/* fail reason */

export const DELIVERY_FAIL_REASON = [
  FAIL_REASON.DELIVERY_PICKUP_ADDRESS_WRONG,
  FAIL_REASON.ITEM_TOO_HEAVY,
  FAIL_REASON.ITEM_TOO_BIG,
  FAIL_REASON.RECIPIENT_UNCONTACTABLE,
  FAIL_REASON.RECIPIENT_REJECTED_PARCEL,
  FAIL_REASON.DELIVERY_ADDRESS_WRONG,
  FAIL_REASON.RECIPIENT_CANNOT_MAKE_PAYMENT,
  FAIL_REASON.RECIPIENT_UNCONTACTABLE_NOSHOW,
  FAIL_REASON.NO_SMALL_CHANGES,
  FAIL_REASON.PAPERCASH_TOO_OLD_TO_ACCEPT,
  FAIL_REASON.RECEIVER_ASKS_WAIT_TOO_LONG,
  FAIL_REASON.PHONE_NO_BALANCE_CALL_OUT,
  FAIL_REASON.WRONG_PARCEL_DELIVERED,
  FAIL_REASON.SENDER_UNCONTACTABLE_NOSHOW,
  FAIL_REASON.SENDER_UNAVAILABLE,
  FAIL_REASON.OTHERS,
];

export const FAIL_REASON_STRINGS: Record<FAIL_REASON, string> = {
  /** common reasons **/
  [FAIL_REASON.OTHERS]: 'Others',

  /** driver reasons **/
  [FAIL_REASON.RIDER_TOO_FAR]: 'Rider Too Far',
  [FAIL_REASON.DRIVER_WAIT_TOO_LONG]: 'Driver Wait Too Long',
  [FAIL_REASON.VEHICLE_BROKE_DOWN]: 'Vehicle Broke Down',
  [FAIL_REASON.ACCEPTED_ORDER_BY_MISTAKE]: 'Accepted Order By Mistake',
  [FAIL_REASON.RIDER_REQUESTED_TO_CANCEL]: 'Rider Requested To Cancel',
  [FAIL_REASON.RIDER_NO_SHOW]: 'Rider No Show',
  [FAIL_REASON.RIDER_WITH_CHILD]: 'Rider With Child',
  [FAIL_REASON.RIDER_INSERTED_WRONG_PICKUP]: 'Rider Inserted Wrong Pickup',
  [FAIL_REASON.RIDER_INSERTED_WRONG_DROPOFF]: 'Rider Inserted Wrong Dropoff',
  [FAIL_REASON.DRIVER_UNABLE]: 'Driver Unable',
  [FAIL_REASON.AD_IGNORE]: 'Ad Ignore',
  [FAIL_REASON.RIDER_BULKY_ITEMS_CANNOT_FIT_INTO_BOOT]: 'Rider Bulky Items Cannot Fit Into Boot',
  [FAIL_REASON.RIDER_WITH_PET]: 'Rider With Pet',
  [FAIL_REASON.RIDER_REFUSE_TO_CANCEL]: 'Rider Refuse to Cancel',
  [FAIL_REASON.RIDER_HAS_TOO_MANY_LUGGAGES]: 'Rider has too Many Luggages',
  [FAIL_REASON.RIDER_REFUSE_TO_WEAR_MASK]: 'Rider Refuse to Wear Mask',
  [FAIL_REASON.TOO_MANY_RIDERS]: 'Too many Riders',
  [FAIL_REASON.MDT_CONFIRM_ACK_TIMEOUT]: 'MDT confirm cannot be checked',
  [FAIL_REASON.MDD_IGNORE]: 'MDD Ignore',
  [FAIL_REASON.FAKE_LOCATION_DETECTED]: 'Fake Location Detected',

  /** rider reasons **/
  [FAIL_REASON.DRIVER_TOO_FAR]: 'Driver Too Far',
  [FAIL_REASON.INSERTED_WRONG_LOCATION]: 'Inserted Wrong Location',
  [FAIL_REASON.RIDER_WAIT_TOO_LONG]: 'Rider Wait Too Long',
  [FAIL_REASON.ANOTHER_TRAVEL_OPTION]: 'Another Travel Option',
  [FAIL_REASON.DRIVER_REQUESTED_TO_CANCEL]: 'Driver Requested To Cancel',
  [FAIL_REASON.DRIVER_UNCONTACTABLE]: 'Driver UnContactable',
  [FAIL_REASON.ANOTHER_RIDER_IN_VEHICLE]: 'Another Rider In Vehicle',
  [FAIL_REASON.DIFFERENT_DRIVER_PICK_UP]: 'Different Driver Pick Up',
  [FAIL_REASON.VEHICLE_DETAIL_DIFFERENT]: 'Vehicle Detail Different',
  [FAIL_REASON.DRIVER_NO_SHOW]: 'Driver No Show',
  [FAIL_REASON.AUTO_CANCEL_BY_PAYMENT_FAILED]: 'Auto Cancel By Payment Failed',
  [FAIL_REASON.AUTO_CANCEL_BY_INCORRECT_CVC]: 'Auto Cancel By Incorrect CVC',

  /** delivery-ride cancel reasons **/
  [FAIL_REASON.VEHICLE_TECHNICAL_PROBLEMS]: 'Vehicle Technical Problems',
  [FAIL_REASON.PERSONAL_EMERGENCY]: 'Personal Emergency',
  [FAIL_REASON.GOT_AN_ACCIDENT]: 'Got An Accident',
  [FAIL_REASON.DEPOSIT_REQUEST_REJECTED]: 'Deposit Request Rejected',
  [FAIL_REASON.SENDER_PICKUP_PAYMENT_FAILED]: 'Sender Pickup Payment Failed',
  [FAIL_REASON.CANCELED_VIA_EXTERNAL_API]: 'Canceld via external api',

  /** delivery pickup fail reasons **/
  [FAIL_REASON.DELIVERY_PICKUP_ADDRESS_WRONG]: 'Delivery Pickup Address Wrong',
  [FAIL_REASON.ITEM_TOO_HEAVY]: 'Item too Heavy',
  [FAIL_REASON.ITEM_TOO_BIG]: 'Item too Big',
  [FAIL_REASON.PROHIBITED_ITEM]: 'Prohibited Item',
  [FAIL_REASON.ITEM_DOES_NOT_FIT_DESCRIPTION]: 'Item does not fit description',

  /** delivery drop off fail reasons **/
  [FAIL_REASON.RECIPIENT_UNCONTACTABLE]: 'Recipient UnContactable',
  [FAIL_REASON.RECIPIENT_REJECTED_PARCEL]: 'Recipient Rejected Parcel',
  [FAIL_REASON.DELIVERY_ADDRESS_WRONG]: 'Delivery Address Wrong',
  [FAIL_REASON.RECIPIENT_CANNOT_MAKE_PAYMENT]: 'Recipient Cannot Make Payment',
  [FAIL_REASON.RECIPIENT_UNCONTACTABLE_NOSHOW]: 'Recipient UnContactable NoShow',
  [FAIL_REASON.NO_SMALL_CHANGES]: 'No Small Changes',
  [FAIL_REASON.PAPERCASH_TOO_OLD_TO_ACCEPT]: 'Papercash Too Old To Accept',
  [FAIL_REASON.RECEIVER_ASKS_WAIT_TOO_LONG]: 'Receiver Asks Wait Too Long',
  [FAIL_REASON.PHONE_NO_BALANCE_CALL_OUT]: 'Phone No Balance Call Out',
  [FAIL_REASON.WRONG_PARCEL_DELIVERED]: 'Wrong Parcel Delivered',

  /** delivery return fail reasons **/
  [FAIL_REASON.SENDER_UNCONTACTABLE_NOSHOW]: 'Sender Uncontactable Noshow',
  [FAIL_REASON.SENDER_UNAVAILABLE]: 'Sender Unavailable',

  /** dod cancel reasons **/
  [FAIL_REASON.RIDER_CHANGE_PAYMENT_METHOD]: 'Rider Change Payment Method',
  [FAIL_REASON.RIDER_ADD_WAYPOINT]: 'Rider Add Waypoint',
  [FAIL_REASON.RIDER_CREATE_NEW_RIDE]: 'Rider Create New Ride',

  /** special mark cancel reason cleared by admin **/
  [FAIL_REASON.REMOVED]: 'Removed',
};
/* //fail reason */

/* payment transaction status */
export enum PAYMENT_TRANSACTION_STATUS {
  INITIAL = 0,
  REQUIRES_PAYMENT_METHOD = 10,
  READY = 20,
  PROCESSING = 100,
  USER_PAYMENT_WAITING = 110,
  HELD = 150,
  PAID = 200,
  REFUNDING = 300,
  CANCELED = 400,
}

export const PAYMENT_TRANSACTION_STATUS_STRINGS: Record<PAYMENT_TRANSACTION_STATUS, string> = {
  [PAYMENT_TRANSACTION_STATUS.INITIAL]: 'Initial',
  [PAYMENT_TRANSACTION_STATUS.REQUIRES_PAYMENT_METHOD]: ' Requires Payment Method',
  [PAYMENT_TRANSACTION_STATUS.READY]: 'Ready',
  [PAYMENT_TRANSACTION_STATUS.PROCESSING]: 'Processing',
  [PAYMENT_TRANSACTION_STATUS.USER_PAYMENT_WAITING]: 'User Payment Waiting',
  [PAYMENT_TRANSACTION_STATUS.HELD]: 'Held',
  [PAYMENT_TRANSACTION_STATUS.PAID]: 'Paid',
  [PAYMENT_TRANSACTION_STATUS.REFUNDING]: 'Refunding',
  [PAYMENT_TRANSACTION_STATUS.CANCELED]: 'Canceled',
};

export const ALL_PAYMENT_TRANSACTION_STATUS = [
  PAYMENT_TRANSACTION_STATUS.INITIAL,
  PAYMENT_TRANSACTION_STATUS.REQUIRES_PAYMENT_METHOD,
  PAYMENT_TRANSACTION_STATUS.READY,
  PAYMENT_TRANSACTION_STATUS.PROCESSING,
  PAYMENT_TRANSACTION_STATUS.USER_PAYMENT_WAITING,
  PAYMENT_TRANSACTION_STATUS.HELD,
  PAYMENT_TRANSACTION_STATUS.PAID,
  PAYMENT_TRANSACTION_STATUS.REFUNDING,
  PAYMENT_TRANSACTION_STATUS.CANCELED,
];
/* //payment transaction status */

/* eta message */
export const ETA_MESSAGE = {
  SG_0: 'SG_0',
  SG_1: 'SG_1',
  SG_2: 'SG_2',
  KH_0: 'KH_0',
  KH_1: 'KH_1',
  KH_2: 'KH_2',
};

export const ETA_MESSAGE_STRINGS = {
  [ETA_MESSAGE.SG_0]: '3 mins',
  [ETA_MESSAGE.SG_1]: '5 mins',
  [ETA_MESSAGE.SG_2]: '7 mins',
  [ETA_MESSAGE.KH_0]: '1 mins',
  [ETA_MESSAGE.KH_1]: '3 mins',
  [ETA_MESSAGE.KH_2]: '10 mins',
};
/* //eta message */

/* additional fee */
export enum ADDITIONAL_FEE_TYPE {
  INTERCITY_FEE = 101,
  ETC = 999,
}

export const ALL_ADDITIONAL_FEE_TYPE = [ADDITIONAL_FEE_TYPE.INTERCITY_FEE, ADDITIONAL_FEE_TYPE.ETC];

export const ADDITIONAL_FEE_TYPE_STRINGS: Record<ADDITIONAL_FEE_TYPE, string> = {
  [ADDITIONAL_FEE_TYPE.INTERCITY_FEE]: 'Intercity Fee',
  [ADDITIONAL_FEE_TYPE.ETC]: 'Etc',
};
/* //additional fee */

/* promotion valid until policy */
export enum PROMO_VALID_UNTIL_POLICY {
  INHERIT = 0,
  AFTER_ISSUE = 1,
  FIXED = 2,
}

/* price excess policy */
export enum PRICE_EXCESS_POLICY {
  APPLY = 0,
  REJECT = 1,
}

export const PRICE_EXCESS_POLICY_APPLY = 0;
export const PRICE_EXCESS_POLICY_REJECT = 1;

export const ALL_PRICE_EXCESS_POLICY = [PRICE_EXCESS_POLICY_APPLY, PRICE_EXCESS_POLICY_REJECT];

export const PRICE_EXCESS_POLICY_STRINGS: Record<number, string> = {
  [PRICE_EXCESS_POLICY_APPLY]: 'constants.price_excess_policy.apply',
  [PRICE_EXCESS_POLICY_REJECT]: 'constants.price_excess_policy.reject',
};
/* //price excess policy */

/* promotion countcheck policy */
export enum COUNT_CHECK_POLICY {
  ISSUED = 0,
  USED = 1,
}

export const COUNT_CHECK_POLICY_PUBLISH = 0;
export const COUNT_CHECK_POLICY_USED = 1;

export const COUNT_CHECK_POLICY_STRINGS: Record<number, string> = {
  [COUNT_CHECK_POLICY_PUBLISH]: 'ISSUED',
  [COUNT_CHECK_POLICY_USED]: 'USED',
};

export const TRANSLATE_COUNT_CHECK_POLICY_STRINGS = {
  [COUNT_CHECK_POLICY_PUBLISH]: 'constants.count_check_policy.publish',
  [COUNT_CHECK_POLICY_USED]: 'constants.count_check_policy.used',
};
/* //promotion countcheck policy */

export const TIMESLOT_DAY_OF_THE_WEEK = ['Mon.', 'Tue.', 'Wed.', 'Thu.', 'Fri.', 'Sat.', 'Sun.'];

export const DISCOUNT_TYPE_REQUIRED_MAX_AMOUNT = [DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_TARGET];

/* coupon */
export const COUPON_STATUS_TYPE = {
  READY: 0,
  USED: 2,
  CANCELED: 10,
  EXPIRED: 100,
} as const;

export type CouponStatusType = valueOf<typeof COUPON_STATUS_TYPE>;

export const COUPON_STATUS = Object.values(COUPON_STATUS_TYPE);

export const COUPON_STATUS_STRINGS: Record<number, string> = {
  [COUPON_STATUS_TYPE.READY]: 'constants.coupon_status.ready',
  [COUPON_STATUS_TYPE.USED]: 'constants.coupon_status.used',
  [COUPON_STATUS_TYPE.CANCELED]: 'constants.coupon_status.canceled',
  [COUPON_STATUS_TYPE.EXPIRED]: 'constants.coupon_status.expired',
};
/* //coupon */

/* app type */
export enum APP_TYPE {
  DRIVER = 'driver',
  RIDER = 'rider',
  DELIVERY_VENDOR = 'delivery_vendor',
}

export const ALL_APP_TYPE = [APP_TYPE.DRIVER, APP_TYPE.RIDER, APP_TYPE.DELIVERY_VENDOR];

export const APP_TYPE_STRINGS = {
  [APP_TYPE.DRIVER]: 'Driver',
  [APP_TYPE.RIDER]: 'Rider',
  [APP_TYPE.DELIVERY_VENDOR]: 'Delivery Vendor',
};
/* //app type */

/* os */
export enum OS {
  IOS = 'ios',
  ANDROID = 'android',
  WEB = 'web',
}

export const ALL_OS = Object.values(OS) as OS[];

export const OS_STRINGS = {
  [OS.IOS]: 'Ios',
  [OS.ANDROID]: 'Android',
  [OS.WEB]: 'Web',
};

/* //os */

export enum TOP_UP_BANK_INFO_STATUS {
  REQUESTED = 'REQUESTED',
  ASSIGNED = 'ASSIGNED',
  NOAVAIL = 'NOAVAIL',
  ERROR = 'ERROR',
}

export enum ADDITIONAL_PRICE_TYPE {
  LATE_NIGHT_SURGE = 0,
  ADDITIONAL_STOP = 1,
  FUEL_SURCHARGE = 2,
  AIRPORT_FEE = 3,
}

export const ALL_ADDITIONAL_PRICE_TYPE = [
  ADDITIONAL_PRICE_TYPE.LATE_NIGHT_SURGE,
  ADDITIONAL_PRICE_TYPE.ADDITIONAL_STOP,
  ADDITIONAL_PRICE_TYPE.FUEL_SURCHARGE,
  ADDITIONAL_PRICE_TYPE.AIRPORT_FEE,
];

export const ADDITIONAL_PRICE_TYPE_STRINGS: Record<ADDITIONAL_PRICE_TYPE, string> = {
  [ADDITIONAL_PRICE_TYPE.LATE_NIGHT_SURGE]: 'Late Night Surge',
  [ADDITIONAL_PRICE_TYPE.ADDITIONAL_STOP]: 'Additional Stop',
  [ADDITIONAL_PRICE_TYPE.FUEL_SURCHARGE]: "Driver's Fee",
  [ADDITIONAL_PRICE_TYPE.AIRPORT_FEE]: 'Airport Fee',
};

// monday tuesday wednesday thursday friday saturday sunday
export enum WEEK_DAYS {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const ALL_WEEK_DAYS = [
  WEEK_DAYS.MONDAY,
  WEEK_DAYS.TUESDAY,
  WEEK_DAYS.WEDNESDAY,
  WEEK_DAYS.THURSDAY,
  WEEK_DAYS.FRIDAY,
  WEEK_DAYS.SATURDAY,
  WEEK_DAYS.SUNDAY,
];

export const WEEK_DAY_STRINGS: Record<WEEK_DAYS | number, string> = {
  [WEEK_DAYS.MONDAY]: 'Monday',
  [WEEK_DAYS.TUESDAY]: 'Tuesday',
  [WEEK_DAYS.WEDNESDAY]: 'Wednesday',
  [WEEK_DAYS.THURSDAY]: 'Thursday',
  [WEEK_DAYS.FRIDAY]: 'Friday',
  [WEEK_DAYS.SATURDAY]: 'Saturday',
  [WEEK_DAYS.SUNDAY]: 'Sunday',
};

export const MULTI_SELECT_WEEK_DAY_OPTIONS = [
  { value: WEEK_DAYS.MONDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.MONDAY] },
  { value: WEEK_DAYS.TUESDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.TUESDAY] },
  { value: WEEK_DAYS.WEDNESDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.WEDNESDAY] },
  { value: WEEK_DAYS.THURSDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.THURSDAY] },
  { value: WEEK_DAYS.FRIDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.FRIDAY] },
  { value: WEEK_DAYS.SATURDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.SATURDAY] },
  { value: WEEK_DAYS.SUNDAY, label: WEEK_DAY_STRINGS[WEEK_DAYS.SUNDAY] },
];

export const BANNER_TAG = {
  RIDER_APP_MAIN_SQUARE: '',
  RIDER_APP_MAIN_349_161: 'RIDER_APP_MAIN_349_161',
  RIDER_APP_SIDE_295_80: 'RIDER_APP_SIDE_295_80',
  RIDER_APP_TOP_750_420: 'RIDER_APP_TOP_750_420',
  DELIVERY_MERCHANT_MAIN_290_107: 'DELIVERY_MERCHANT_MAIN_290_107',
  DRIVER_APP_MAIN_841_1260: 'DRIVER_APP_MAIN_841_1260',
  DRIVER_MAIN_TEXT: 'DRIVER_MAIN_TEXT',
  DRIVER_APP_SIDE_295_80: 'DRIVER_APP_SIDE_295_80',
} as const;

export const BANNER_TAG_STRINGS = {
  [BANNER_TAG.RIDER_APP_MAIN_SQUARE]: 'RIDER_APP_MAIN_SQUARE(NULL) (Deprecated)',
  [BANNER_TAG.RIDER_APP_MAIN_349_161]: 'RIDER_APP_MAIN_349_161',
  [BANNER_TAG.RIDER_APP_SIDE_295_80]: 'RIDER_APP_SIDE_295_80',
  [BANNER_TAG.DELIVERY_MERCHANT_MAIN_290_107]: 'DELIVERY_MERCHANT_MAIN_290_107',
  [BANNER_TAG.DRIVER_APP_MAIN_841_1260]: 'DRIVER_APP_MAIN_841_1260 (Deprecated)',
  [BANNER_TAG.DRIVER_MAIN_TEXT]: 'DRIVER_MAIN_TEXT',
  [BANNER_TAG.DRIVER_APP_SIDE_295_80]: 'DRIVER_APP_SIDE_295_80',
  [BANNER_TAG.RIDER_APP_TOP_750_420]: 'RIDER_APP_TOP_750_420',
};

export const BANNER_TAG_OPTIONS = [
  {
    value: BANNER_TAG.RIDER_APP_MAIN_SQUARE,
    text: BANNER_TAG_STRINGS[BANNER_TAG.RIDER_APP_MAIN_SQUARE],
    disabled: true,
  },
  {
    value: BANNER_TAG.RIDER_APP_MAIN_349_161,
    text: BANNER_TAG_STRINGS[BANNER_TAG.RIDER_APP_MAIN_349_161],
  },
  {
    value: BANNER_TAG.RIDER_APP_SIDE_295_80,
    text: BANNER_TAG_STRINGS[BANNER_TAG.RIDER_APP_SIDE_295_80],
  },
  {
    value: BANNER_TAG.RIDER_APP_TOP_750_420,
    text: BANNER_TAG_STRINGS[BANNER_TAG.RIDER_APP_TOP_750_420],
  },
  {
    value: BANNER_TAG.DELIVERY_MERCHANT_MAIN_290_107,
    text: BANNER_TAG_STRINGS[BANNER_TAG.DELIVERY_MERCHANT_MAIN_290_107],
  },
  {
    value: BANNER_TAG.DRIVER_APP_MAIN_841_1260,
    text: BANNER_TAG_STRINGS[BANNER_TAG.DRIVER_APP_MAIN_841_1260],
    disabled: true,
  },
  {
    value: BANNER_TAG.DRIVER_MAIN_TEXT,
    text: BANNER_TAG_STRINGS[BANNER_TAG.DRIVER_MAIN_TEXT],
  },
  {
    value: BANNER_TAG.DRIVER_APP_SIDE_295_80,
    text: BANNER_TAG_STRINGS[BANNER_TAG.DRIVER_APP_SIDE_295_80],
  },
];

export const DEFAULT_BANNER_TAG = BANNER_TAG_OPTIONS[0].text;

export enum TOP_UP_CATEGORY {
  DELIVERY_COD = 'Delivery COD',
  DELIVERY_PAYMENT = 'Delivery Payment',
  INCENTIVES = 'Incentives',
  FUNDED = 'Funded subsidy (like govt relief fund or premier program for SG)',
  COMPONSATION = 'Compensation for non-paid trips',
  OTHER = 'Other expense',
}

export const TOP_UP_CATEGORIES = [
  TOP_UP_CATEGORY.DELIVERY_COD,
  TOP_UP_CATEGORY.DELIVERY_PAYMENT,
  TOP_UP_CATEGORY.INCENTIVES,
  TOP_UP_CATEGORY.FUNDED,
  TOP_UP_CATEGORY.COMPONSATION,
  TOP_UP_CATEGORY.OTHER,
];

export enum PING_ROUTE_ENGINE_TYPE {
  GRAPHHOPPER = 0,
  QI = 1,
}

export enum PLACE_TYPE {
  TADA_PLACE = 0,
  GOOGLE_PLACE = 10,
  ONEMAP_PLACE = 20,
}

export enum Model {
  Driver = 'Driver',
  Rider = 'Rider',
  Ride = 'Ride',
  Delivery = 'Delivery',
  DeliveryRide = 'DeliveryRide',
  Admin = 'Admin',
}

export enum ERROR {
  LAST_SCROLL = 'LAST_SCROLL', // Infinite Scroll 마지막일때
}

export enum DRIVER_TIER {
  NON_ACTIVE = 0,
  THIRD_GRADE = 1,
  SECOND_GRADE = 2,
  FIRST_GRADE = 3,
}

export const DRIVER_TIER_STRINGS = {
  [DRIVER_TIER.NON_ACTIVE]: 'Non Active',
  [DRIVER_TIER.THIRD_GRADE]: 'Third Grade',
  [DRIVER_TIER.SECOND_GRADE]: 'Second Grade',
  [DRIVER_TIER.FIRST_GRADE]: 'First Grade',
};

export enum DRIVER_REPLIED_ETA {
  SG_3_MINUTES = 'SG_0', // Used in SG, 3 minutes
  SG_5_MINUTES = 'SG_1', // Used in SG, 5 minutes
  SG_7_MINUTES = 'SG_2', // Used in SG, 7 minutes
  KH_ARRIVING_SOON = 'KH_0', // Used in KH, arriving soon
  KH_3_MINUTES = 'KH_1', // Used in KH, 2-3 mins
  KH_HEAVY_TRAFFIC = 'KH_2', // Used in KH, heavy traffic
}

export enum COMPLIMENT_TYPE {
  SKILLFUL = 'SKILLFUL',
  CAREFUL = 'CAREFUL',
  KIND = 'KIND',
  SKIP = 'SKIP',
}

export enum SAFETY_GRADE {
  AAA = 'AAA',
  AA = 'AA',
  A = 'A',
  B = 'B',
  C = 'C',
}

export enum APPLICATION_TYPE {
  DRIVER_SIGN_UP = 'DRIVER_SIGN_UP',
  DRIVER_VL_EXPIRY_DATE_CHANGE = 'DRIVER_VL_EXPIRY_DATE_CHANGE',
  VEHICLE_REGISTER = 'VEHICLE_REGISTER',
  VEHICLE_CHANGE = 'VEHICLE_CHANGE',
  VEHICLE_RENTAL = 'VEHICLE_RENTAL',
  VEHICLE_INSURANCE_CHANGE = 'VEHICLE_INSURANCE_CHANGE',
  DRIVER_NRIC_CHANGE = 'DRIVER_NRIC_CHANGE',
  DRIVER_DRIVING_LICENSE_CHANGE = 'DRIVER_DRIVING_LICENSE_CHANGE',
  DRIVER_BANK_INFO_CHANGE = 'DRIVER_BANK_INFO_CHANGE',
  VEHICLE_ELECTRONIC_MARKINGS_CHANGE = 'VEHICLE_ELECTRONIC_MARKINGS_CHANGE',
  DRIVER_POLICE_CLEARANCE_CERTIFICATE_CHANGE = 'DRIVER_POLICE_CLEARANCE_CERTIFICATE_CHANGE',
  DRIVER_TDIP_CHANGE = 'DRIVER_TDIP_CHANGE',
}

export const APPLICATION_TYPE_STRINGS: Record<string, string> = {
  [APPLICATION_TYPE.DRIVER_SIGN_UP]: 'DRIVER SIGN UP',
  [APPLICATION_TYPE.DRIVER_VL_EXPIRY_DATE_CHANGE]: 'VLEXPIRYDATE CHANGE',
  [APPLICATION_TYPE.VEHICLE_REGISTER]: 'VEHICLE REGISTER',
  [APPLICATION_TYPE.VEHICLE_CHANGE]: 'VEHICLE CHANGE',
  [APPLICATION_TYPE.VEHICLE_RENTAL]: 'VEHICLE RENTAL',
  [APPLICATION_TYPE.VEHICLE_INSURANCE_CHANGE]: 'INSURANCE DATE CHANGE',
  [APPLICATION_TYPE.DRIVER_NRIC_CHANGE]: 'DRIVER NRIC CHANGE',
  [APPLICATION_TYPE.DRIVER_DRIVING_LICENSE_CHANGE]: 'DRIVER DRIVING LICENSE CHANGE',
  [APPLICATION_TYPE.DRIVER_BANK_INFO_CHANGE]: 'DRIVER BANK INFO CHANGE',
  [APPLICATION_TYPE.VEHICLE_ELECTRONIC_MARKINGS_CHANGE]: 'VEHICLE ELECTRONIC MARKINGS CHANGE',
  [APPLICATION_TYPE.DRIVER_POLICE_CLEARANCE_CERTIFICATE_CHANGE]: 'DRIVER POLICE CLEARANCE CERTIFICATE CHANGE',
  [APPLICATION_TYPE.DRIVER_TDIP_CHANGE]: 'DRIVER TDIP CHANGE',
};

export const ALL_APPLICATION_TYPES = Object.values(APPLICATION_TYPE);

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
}

export const ALL_GENDERS = [GENDER.MALE, GENDER.FEMALE];

export const GENDER_STRINGS = {
  [GENDER.MALE]: 'MALE',
  [GENDER.FEMALE]: 'FEMALE',
};

export enum DRIVER_REVIEW {
  OTHERS = 999,
  DIFFERENT_VEHICLE_OR_CAR = 1,
  ROUTE_ISSUES = 2,
  DANGEROUS_DRIVING = 3,
  BAD_DRIVER_ATTITUDE = 4,
  OVER_CHARGING = 5,
  VEHICLE_CONDITION = 6,
  HARASSMENT = 7,
  UNPROFESSIONAL_CLOTHING = 8,
}

export const DRIVER_REVIEW_STRINGS: { [key in number | DRIVER_REVIEW]: string } = {
  [DRIVER_REVIEW.OTHERS]: 'OTHERS',
  [DRIVER_REVIEW.DIFFERENT_VEHICLE_OR_CAR]: 'DIFFERENT_VEHICLE_OR_CAR',
  [DRIVER_REVIEW.ROUTE_ISSUES]: 'ROUTE_ISSUES',
  [DRIVER_REVIEW.DANGEROUS_DRIVING]: 'DANGEROUS_DRIVING',
  [DRIVER_REVIEW.BAD_DRIVER_ATTITUDE]: 'BAD_DRIVER_ATTITUDE',
  [DRIVER_REVIEW.OVER_CHARGING]: 'OVER_CHARGING',
  [DRIVER_REVIEW.VEHICLE_CONDITION]: 'VEHICLE_CONDITION',
  [DRIVER_REVIEW.HARASSMENT]: 'HARASSMENT',
  [DRIVER_REVIEW.UNPROFESSIONAL_CLOTHING]: 'UNPROFESSIONAL_CLOTHING',
};

export enum RENTAL_STATUS {
  RESERVED = 'RESERVED',
  IN_USE = 'IN_USE',
  EXPIRED = 'EXPIRED',
  REJECTED = 'REJECTED',
}

export const ENGINE_TYPE = {
  HYBRID: 'HYBRID',
  ELECTRIC: 'ELECTRIC',
  STANDARD: 'STANDARD',
} as const;

export type EngineType = valueOf<typeof ENGINE_TYPE>;

export const ALL_ENGINE_TYPES = [ENGINE_TYPE.HYBRID, ENGINE_TYPE.ELECTRIC, ENGINE_TYPE.STANDARD];

export enum TARGET_TYPE {
  RIDE = 1,
  DELIVERY = 2,
}

export const ALL_TARGET_TYPES = [TARGET_TYPE.RIDE, TARGET_TYPE.DELIVERY];

export const TARGET_TYPE_STRINGS: Record<number, string> = {
  [TARGET_TYPE.RIDE]: 'constants.target_type_ride',
  [TARGET_TYPE.DELIVERY]: 'constants.target_type_delivery',
};

export enum RESERVATION_RIDE_STAT {
  CREATED = 'CREATED',
  ASSIGNED = 'ASSIGNED',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  UPCOMING = 'UPCOMING',
}

export const ALL_RESERVATION_RIDE_STAT = [
  RESERVATION_RIDE_STAT.CREATED,
  RESERVATION_RIDE_STAT.ASSIGNED,
  RESERVATION_RIDE_STAT.ONGOING,
  RESERVATION_RIDE_STAT.FINISHED,
  RESERVATION_RIDE_STAT.UPCOMING,
];

export enum ENTITY_TRANSACTION_HISTORY_TYPE {
  DELIVERY_SENDER = 'DELIVERY_SENDER',
  DELIVERY_RECEIVER = 'DELIVERY_RECEIVER',
  RIDE = 'RIDE',
}

export enum DRIVER_GROUP_ACTION_TYPE {
  ADD = 'Add',
  REMOVE = 'Remove',
}

export const ALL_DRIVER_GROUP_ACTION_TYPES = Object.values(DRIVER_GROUP_ACTION_TYPE);

export const POLYGON_GEOFENCE_POLICY = {
  PICKUP_OR_DESTINATION: 1, // default
  PICKUP_AND_DESTINATION: 2, // ignore waypoint
  PICKUP_ONLY: 3,
  DESTINATION_ONLY: 4,
  EVERY_LOCATIONS: 5, // delivery should validate all locations (each parcel destinations)
  ANY_LOCATION: 6, // can apply if any locations contained in polygon
} as const;

export const POLYGON_GEOFENCE_POLICY_STRINGS = {
  [POLYGON_GEOFENCE_POLICY.PICKUP_OR_DESTINATION]: 'Pickup Or Destination',
  [POLYGON_GEOFENCE_POLICY.PICKUP_AND_DESTINATION]: 'Pickup And Destination',
  [POLYGON_GEOFENCE_POLICY.PICKUP_ONLY]: 'Pickup Only',
  [POLYGON_GEOFENCE_POLICY.DESTINATION_ONLY]: 'Destination Only',
  [POLYGON_GEOFENCE_POLICY.EVERY_LOCATIONS]: 'Every Locations',
  [POLYGON_GEOFENCE_POLICY.ANY_LOCATION]: 'Any Location',
};

export type PolygonGeofencePolicy = valueOf<typeof POLYGON_GEOFENCE_POLICY>;

export const ALL_POLYGON_GEOFENCE_POLICY = Object.values(POLYGON_GEOFENCE_POLICY);

export const ALL_POLYGON_GEOFENCE_POLICY_FOR_LOYALTY = [
  POLYGON_GEOFENCE_POLICY.PICKUP_OR_DESTINATION,
  POLYGON_GEOFENCE_POLICY.PICKUP_AND_DESTINATION,
  POLYGON_GEOFENCE_POLICY.PICKUP_ONLY,
  POLYGON_GEOFENCE_POLICY.DESTINATION_ONLY,
];

export const POLYGON_GEO_FENCE_POLICY_FOR_LOYALTY_OPTION = ALL_POLYGON_GEOFENCE_POLICY_FOR_LOYALTY.map((value) => ({
  text: POLYGON_GEOFENCE_POLICY_STRINGS[value],
  value,
}));

export enum INBOX_PUSH_MESSAGE_TYPE {
  RIDE_EXPIRED = 'RIDE_EXPIRED',
  RIDE_CANCELED = 'RIDE_CANCELED',
  RIDE_CANCELED_BY_DRIVER = 'RIDE_CANCELED_BY_DRIVER',
  RIDE_PICKUP_ARRIVED = 'RIDE_PICKUP_ARRIVED',
  RIDE_ASSIGNED = 'RIDE_ASSIGNED',
  RIDE_STARTED = 'RIDE_STARTED',
  RIDE_FINISHED = 'RIDE_FINISHED',
  RIDE_PAY_AFTER_USE = 'RIDE_PAY_AFTER_USE',
  RIDE_SYSTEM_DISPATCHED = 'RIDE_SYSTEM_DISPATCHED',
  RIDE_AUTO_DISPATCHED = 'RIDE_AUTO_DISPATCHED',
  RIDE_PICKUP_ETA_WARNING_FIRST_PHASE = 'RIDE_PICKUP_ETA_WARNING_FIRST_PHASE',
  RIDE_PICKUP_ETA_WARNING_SECOND_PHASE = 'RIDE_PICKUP_ETA_WARNING_SECOND_PHASE',
  RIDE_PICKUP_ETA_WARNING_THIRD_PHASE = 'RIDE_PICKUP_ETA_WARNING_THIRD_PHASE',
  NEW_RIDE_NA = 'NEW_RIDE_NA',
  NOTIFY_ZONE = 'NOTIFY_ZONE',
  NEW_RIDE_BASE = 'NEW_RIDE_BASE',
  NEW_RIDE_DOD = 'NEW_RIDE_DOD',
  RENTAL_EXPIRED = 'RENTAL_EXPIRED',
  HOURLY_RENTAL_REGISTERED = 'HOURLY_RENTAL_REGISTERED',
  HOURLY_RENTAL_EXPIRED = 'HOURLY_RENTAL_EXPIRED',
  ACTIONABLE_MODAL = 'ACTIONABLE_MODAL',
  DOC_PASSED = 'DOC_PASSED',
  RESERVATION_RIDE_MATCHED = 'RESERVATION_RIDE_MATCHED',
  RESERVATION_RIDE_CONFIRMED = 'RESERVATION_RIDE_CONFIRMED',
  RESERVATION_RIDE_EXPIRED = 'RESERVATION_RIDE_EXPIRED',
  RESERVATION_DRIVER_REMINDER_UPCOMING = 'RESERVATION_DRIVER_REMINDER_UPCOMING',
  RESERVATION_DRIVER_REMINDER_CHECK_TOO_FAR = 'RESERVATION_DRIVER_REMINDER_CHECK_TOO_FAR',
  RESERVATION_DRIVER_EN_ROUTE = 'RESERVATION_DRIVER_EN_ROUTE',
  RESERVATION_RIDER_CANCELED = 'RESERVATION_RIDER_CANCELED',
  RESERVATION_DRIVER_CANCELED = 'RESERVATION_DRIVER_CANCELED',
}

export const ALL_INBOX_PUSH_MESSAGE_TYPE = [
  INBOX_PUSH_MESSAGE_TYPE.RIDE_EXPIRED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_CANCELED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_CANCELED_BY_DRIVER,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ARRIVED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_ASSIGNED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_STARTED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_FINISHED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_PAY_AFTER_USE,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_SYSTEM_DISPATCHED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_AUTO_DISPATCHED,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_FIRST_PHASE,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_SECOND_PHASE,
  INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_THIRD_PHASE,
  INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_NA,
  INBOX_PUSH_MESSAGE_TYPE.NOTIFY_ZONE,
  INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_BASE,
  INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_DOD,
  INBOX_PUSH_MESSAGE_TYPE.RENTAL_EXPIRED,
  INBOX_PUSH_MESSAGE_TYPE.HOURLY_RENTAL_REGISTERED,
  INBOX_PUSH_MESSAGE_TYPE.HOURLY_RENTAL_EXPIRED,
  INBOX_PUSH_MESSAGE_TYPE.ACTIONABLE_MODAL,
  INBOX_PUSH_MESSAGE_TYPE.DOC_PASSED,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_MATCHED,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_CONFIRMED,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_EXPIRED,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_REMINDER_UPCOMING,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_REMINDER_CHECK_TOO_FAR,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_EN_ROUTE,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDER_CANCELED,
  INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_CANCELED,
];

export const INBOX_PUSH_MESSAGE_TYPE_STRINGS = {
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_EXPIRED]: 'Ride Expired',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_CANCELED]: 'Ride Canceled',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_CANCELED_BY_DRIVER]: 'Ride Canceled by Driver',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ARRIVED]: 'Ride Pickup Arrived',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_ASSIGNED]: 'Ride Assigned',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_STARTED]: 'Ride Started',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_FINISHED]: 'Ride Finished',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_PAY_AFTER_USE]: 'Ride Pay After Use',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_SYSTEM_DISPATCHED]: 'Ride System Dispatched',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_AUTO_DISPATCHED]: 'Ride Auto Dispatched',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_FIRST_PHASE]: 'Ride Pickup ETA Warning First Phase',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_SECOND_PHASE]: 'Ride Pickup ETA Warning Second Phase',
  [INBOX_PUSH_MESSAGE_TYPE.RIDE_PICKUP_ETA_WARNING_THIRD_PHASE]: 'Ride Pickup ETA Warning Third Phase',
  [INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_NA]: 'New Ride N/A',
  [INBOX_PUSH_MESSAGE_TYPE.NOTIFY_ZONE]: 'Notify Zone',
  [INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_BASE]: 'New Ride Base',
  [INBOX_PUSH_MESSAGE_TYPE.NEW_RIDE_DOD]: 'New Ride DOD',
  [INBOX_PUSH_MESSAGE_TYPE.RENTAL_EXPIRED]: 'Rental Expired',
  [INBOX_PUSH_MESSAGE_TYPE.HOURLY_RENTAL_REGISTERED]: 'Hourly Rental Registered',
  [INBOX_PUSH_MESSAGE_TYPE.HOURLY_RENTAL_EXPIRED]: 'Hourly Rental Expired',
  [INBOX_PUSH_MESSAGE_TYPE.ACTIONABLE_MODAL]: 'Actionable Modal',
  [INBOX_PUSH_MESSAGE_TYPE.DOC_PASSED]: 'DOC Passed',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_MATCHED]: 'Reservation Ride Matched',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_CONFIRMED]: 'Reservation Ride Confirmed',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDE_EXPIRED]: 'Reservation Ride Expired',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_REMINDER_UPCOMING]: 'Reservation Driver Reminder Upcoming',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_REMINDER_CHECK_TOO_FAR]: 'Reservation Driver Reminder Check too Far',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_EN_ROUTE]: 'Reservation Driver EN Route',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_RIDER_CANCELED]: 'Reservation Rider Canceled',
  [INBOX_PUSH_MESSAGE_TYPE.RESERVATION_DRIVER_CANCELED]: 'Reservation Driver Canceled',
};

export const VACCINATED_STATUS = {
  VACCINATED: 'VACCINATED',
  UNVACCINATED: 'UNVACCINATED',
  EXEMPTED: 'EXEMPTED',
} as const;

export type VaccinatedStatus = valueOf<typeof VACCINATED_STATUS>;

export const ALL_VACCINATED_STATUS = Object.values(VACCINATED_STATUS);

export const VACCINATED_STATUS_OPTIONS = ALL_VACCINATED_STATUS.map<{ text: string; value: VaccinatedStatus }>((status) => ({
  value: status,
  text: status,
}));

export const VACCINATED_DATE_PRESET = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  EXPIRED: 'EXPIRED',
} as const;

export type VaccinatedDatePreset = valueOf<typeof VACCINATED_DATE_PRESET>;

export const VACCINATED_EXPIRE_DATE: Record<VaccinatedDatePreset, { before: number; after?: number }> = {
  [VACCINATED_DATE_PRESET.MONTH]: {
    before: 240,
    after: 270,
  },
  [VACCINATED_DATE_PRESET.WEEK]: {
    before: 263,
    after: 270,
  },
  [VACCINATED_DATE_PRESET.EXPIRED]: {
    before: 270,
  },
};

export const VACCINATED_BULK_STATUS = {
  VACCINATED: 'vaccinated',
  UNVACCINATED: 'not_vaccinated',
  EXEMPTED: 'vaccine_exempted',
} as const;

export enum CHANGE_DESTINATION_STATUS {
  CHANGING = 0,
  CHANGED = 10,
  CANCELED = 100,
  DRIVER_REJECTED = 110,
  REVERTED = 200,
}

export const CHANGE_DESTINATION_STATUS_STRINGS = {
  [CHANGE_DESTINATION_STATUS.CHANGING]: 'Changing',
  [CHANGE_DESTINATION_STATUS.CHANGED]: 'Changed',
  [CHANGE_DESTINATION_STATUS.CANCELED]: 'Canceled',
  [CHANGE_DESTINATION_STATUS.DRIVER_REJECTED]: 'Rejected',
  [CHANGE_DESTINATION_STATUS.REVERTED]: 'Reverted',
};

export const REWARD_TYPE = {
  CASH: 1,
  EARNING_GUARANTEE: 2,
  COMPENSATION_SYSTEM_FEE: 3,
  MEMBERSHIP: 4,
  CASH_PER_TRIP: 5,
  // Rider Mission
  MANUAL: 6,
  PROMOTION: 7,
  PARTNER_UNIQUE_REWARD: 8,
  UPLOADED_VOUCHERS: 9,
} as const;

export type RewardType = valueOf<typeof REWARD_TYPE>;

export const ALL_REWARD_TYPES = Object.values(REWARD_TYPE);

export const ALL_RIDER_MISSION_REWARD_TYPES = [
  REWARD_TYPE.MANUAL,
  REWARD_TYPE.PROMOTION,
  REWARD_TYPE.PARTNER_UNIQUE_REWARD,
  REWARD_TYPE.UPLOADED_VOUCHERS,
];

export const REWARD_TYPE_STRINGS: Record<RewardType, string> = {
  [REWARD_TYPE.CASH]: 'Cash',
  [REWARD_TYPE.EARNING_GUARANTEE]: 'Earning Guarantee',
  [REWARD_TYPE.COMPENSATION_SYSTEM_FEE]: 'Compensation System Fee',
  [REWARD_TYPE.MEMBERSHIP]: 'Membership',
  [REWARD_TYPE.CASH_PER_TRIP]: 'Cash per trip',
  [REWARD_TYPE.MANUAL]: 'Manual',
  [REWARD_TYPE.PROMOTION]: 'Promotion',
  [REWARD_TYPE.PARTNER_UNIQUE_REWARD]: 'Partner Unique Reward',
  [REWARD_TYPE.UPLOADED_VOUCHERS]: 'Uploaded Vouchers',
};

export const DRIVER_INCENTIVE_DISPATCH_TYPE = {
  DRIVER_PING: 0,
  AD: 1,
  SD: 2,
  MDT: 3,
} as const;

export const ALL_DRIVER_INCENTIVE_DISPATCH_TYPES = Object.values(DRIVER_INCENTIVE_DISPATCH_TYPE);

export type DriverIncentiveDispatchType = valueOf<typeof DRIVER_INCENTIVE_DISPATCH_TYPE>;

export const DRIVER_INCENTIVE_DISPATCH_TYPE_STRINGS: Record<DriverIncentiveDispatchType, string> = {
  [DRIVER_INCENTIVE_DISPATCH_TYPE.SD]: 'SD',
  [DRIVER_INCENTIVE_DISPATCH_TYPE.AD]: 'AD',
  [DRIVER_INCENTIVE_DISPATCH_TYPE.DRIVER_PING]: 'Driver Ping',
  [DRIVER_INCENTIVE_DISPATCH_TYPE.MDT]: 'MDT',
};

export const DRIVER_INCENTIVE_TARGET_AMOUNT_TITLE_BY_MISSION: Record<MissionType, (region: REGION) => string> = {
  [MISSION_TYPE.TOTAL_RIDES]: () => 'Target Amount (# of finished rides)',
  [MISSION_TYPE.TOTAL_EARNING]: (region: REGION) => `Target Amount (Total earning amount, ${CURRENCIES_FROM_REGION[region][0]})`,
  [MISSION_TYPE.TOTAL_SYSTEM_FEE]: (region: REGION) => `Target Amount (Total system fee amount, ${CURRENCIES_FROM_REGION[region][0]})`,
  [MISSION_TYPE.STAMP]: () => '',
};

export const VALUE_TYPE = {
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  IMAGE: 'IMAGE',
  DOCS: 'DOCS',
  INT: 'INT',
  INSTANT: 'INSTANT',
  DATE: 'DATE',
} as const;

export type ValueType = valueOf<typeof VALUE_TYPE>;

export const MEANING_TYPE = {
  PROFILE_PICTURE: 'PROFILE_PICTURE',
  NRIC: 'NRIC',
  NRIC_FRONT: 'NRIC_FRONT',
  NRIC_BACK: 'NRIC_BACK',
  RESIDENTIAL_ADDRESS: 'RESIDENTIAL_ADDRESS',
  DRIVING_LICENSE_BACK: 'DRIVING_LICENSE_BACK',
  DRIVING_LICENSE_FRONT: 'DRIVING_LICENSE_FRONT',
  PDVL_FRONT: 'PDVL_FRONT',
  PDVL_BACK: 'PDVL_BACK',
  TDVL_FRONT: 'TDVL_FRONT',
  TDVL_BACK: 'TDVL_BACK',
  PDVL: 'PDVL',
  JUDICIAL_RECORD: 'JUDICIAL_RECORD',
  CAR_MODEL: 'CAR_MODEL',
  CAR_MAKER: 'CAR_MAKER',
  CAR_PLATE: 'CAR_PLATE',
  CAR_YEAR: 'CAR_YEAR',
  CAR_COLOR: 'CAR_COLOR',
  CAR_IVRD: 'CAR_IVRD',
  CAR_IVRD_DOC: 'CAR_IVRD_DOC',
  CAR_PHOTO: 'CAR_PHOTO',
  CAR_TYPE: 'CAR_TYPE',
  INSURANCE_DOCS: 'INSURANCE_DOCS',
  INSURANCE_EXPIRE_DATE: 'INSURANCE_EXPIRE_DATE',
  TAXI_FRONT: 'TAXI_FRONT',
  TAXI_MDT: 'TAXI_MDT',
  TAXI_LICENSE_PLATE_NUMBER: 'TAXI_LICENSE_PLATE_NUMBER',
  TAXI_COMPANY: 'TAXI_COMPANY',
  EQUIPMENTS: 'EQUIPMENTS',
  VEHICLE_REGISTER: 'VEHICLE_REGISTER',
  VEHICLE_REGISTER_BACK: 'VEHICLE_REGISTER_BACK',
  VEHICLE_OWNERSHIP: 'VEHICLE_OWNERSHIP',
  VEHICLE_OWNERSHIP_DOCS: 'VEHICLE_OWNERSHIP_DOCS',
  DECAL: 'DECAL',
  VTI_DOCS: 'VTI_DOCS',
  NEXT_VEHICLE_INSPECTION_DATE: 'NEXT_VEHICLE_INSPECTION_DATE',
  BOOKING_CONFIRMATION_IMAGE: 'BOOKING_CONFIRMATION_IMAGE',
  BOOKING_CONFIRMATION_NUMBER: 'BOOKING_CONFIRMATION_NUMBER',
  RENTAL_START_AT: 'RENTAL_START_AT',
  RENTAL_END_AT: 'RENTAL_END_AT',
  RENTAL_COMPANY: 'RENTAL_COMPANY',
  VL_ID: 'VL_ID',
  VL_EXPIRY_DATE: 'VL_EXPIRY_DATE',
  ENGINE_TYPE: 'ENGINE_TYPE',
  VACCINATED_PROOF: 'VACCINATED_PROOF',
  LAST_VACCINATED_DATE: 'LAST_VACCINATED_DATE',
  VACCINATED_STATUS: 'VACCINATED_STATUS',
} as const;

export type MeaningType = valueOf<typeof MEANING_TYPE>;

export const TRANSACTION_CHANNEL = {
  // TOP_UP, CASH_OUT
  DBS: 'DBS',
  WING: 'WING', // PAYMENT_METHOD too
  ABA: 'ABA',
  SBC: 'SBC',
  CITI: 'CITI',
  // PAYMENT_METHOD
  ALIPAY_P: 'ALIPAY_P',
  PIPAY_P: 'PIPAY_P',
  CMCB: 'CMCB',
  CREDITCARD: 'CREDITCARD',
  CORPORATE: 'CORPORATE',
  TADAPAY: 'TADAPAY',
  MOMO_P: 'MOMO_P',
  NETSQR: 'NETSQR',
  OCBC_PAO: 'OCBC_PAO',
  DELIVERY: 'DELIVERY',
  ABA_AOF: 'ABA_AOF',
} as const;

export type TRANSACTION_CHANNEL_TYPE = valueOf<typeof TRANSACTION_CHANNEL>;

export const ALL_TRANSACTION_CHANNEL_TYPES = Object.values(TRANSACTION_CHANNEL);

export const TRANSACTION_CHANNEL_STRINGS = {
  [TRANSACTION_CHANNEL.DBS]: 'DBS',
  [TRANSACTION_CHANNEL.WING]: 'Wing',
  [TRANSACTION_CHANNEL.ABA]: 'ABA',
  [TRANSACTION_CHANNEL.SBC]: 'SBC',
  [TRANSACTION_CHANNEL.ALIPAY_P]: 'Alipay Post',
  [TRANSACTION_CHANNEL.PIPAY_P]: 'Pipay Post',
  [TRANSACTION_CHANNEL.CMCB]: 'CMCB',
  [TRANSACTION_CHANNEL.CREDITCARD]: 'Credit Card',
  [TRANSACTION_CHANNEL.CORPORATE]: 'Corporate',
  [TRANSACTION_CHANNEL.TADAPAY]: 'TADA Wallet',
  [TRANSACTION_CHANNEL.MOMO_P]: 'MoMo Pay',
  [TRANSACTION_CHANNEL.NETSQR]: 'NETSQR',
  [TRANSACTION_CHANNEL.OCBC_PAO]: 'OCBC Pay Anyone',
  [TRANSACTION_CHANNEL.DELIVERY]: 'Delivery',
  [TRANSACTION_CHANNEL.ABA_AOF]: 'ABA_AOF',
  [TRANSACTION_CHANNEL.CITI]: 'CITI',
};

export const CASHOUT_STATUS = {
  DAILY: 'daily',
  TEMPORARY: 'temporary',
} as const;

export type CASHOUT_STATUS_TYPE = valueOf<typeof CASHOUT_STATUS>;

export const CASHOUT_STATUS_STRINGS = Object.values(CASHOUT_STATUS);

export const WEEK_END_UNTIL = 335;

export const WAITING_FEE_STATUS = {
  CREATED: 10,
  CONVERTED_TO_CANCELLATION_FEE: 50,
  PAID: 100,
  REMOVED_BY_DRIVER: 200,
  REMOVED_BY_ADMIN: 210,
  INVALID: 300,
} as const;

export const WAITING_FEE_STATUS_STRINGS = {
  [WAITING_FEE_STATUS.CREATED]: 'Created',
  [WAITING_FEE_STATUS.CONVERTED_TO_CANCELLATION_FEE]: 'Converted To Cancellation Fee',
  [WAITING_FEE_STATUS.PAID]: 'Paid',
  [WAITING_FEE_STATUS.REMOVED_BY_DRIVER]: 'Removed By Driver',
  [WAITING_FEE_STATUS.REMOVED_BY_ADMIN]: 'Removed By Admin',
  [WAITING_FEE_STATUS.INVALID]: 'Invalid',
} as const;

export type WaitingFeeStatus = valueOf<typeof WAITING_FEE_STATUS>;
export const FAQ_APP = {
  RIDER: 'RIDER',
  DRIVER: 'DRIVER',
} as const;

export type FAQ_APP_TYPE = valueOf<typeof FAQ_APP>;

export const FAQ_APP_TYPE_STRINGS: Record<FAQ_APP_TYPE, string> = {
  [FAQ_APP.RIDER]: 'Rider App',
  [FAQ_APP.DRIVER]: 'Rriver App',
};

export const QUESTION_STATUS = {
  IN_REVIEW: 'IN_REVIEW',
  REJECT: 'REJECT',
  RELEASE: 'RELEASE',
} as const;

export type QuestionStatus = valueOf<typeof QUESTION_STATUS>;

export const QUESTION_STATUS_STRINGS: Record<QuestionStatus, string> = {
  [QUESTION_STATUS.IN_REVIEW]: 'In Review',
  [QUESTION_STATUS.REJECT]: 'Reject',
  [QUESTION_STATUS.RELEASE]: 'Release',
};

export const CONTENT_DATA_TYPE = {
  TEXT: 'TEXT',
  HTML: 'HTML',
} as const;

export type ContentDataType = valueOf<typeof CONTENT_DATA_TYPE>;

export const CONTENT_DATA_TYPE_STRINGS: Record<ContentDataType, string> = {
  [CONTENT_DATA_TYPE.TEXT]: 'Text',
  [CONTENT_DATA_TYPE.HTML]: 'Html',
};

export const MATCH_TYPE = {
  PREFIX: 'PREFIX',
  EXACT_MATCH: 'EXACT_MATCH',
} as const;

export type MatchType = valueOf<typeof MATCH_TYPE>;

export const ALL_MATCH_TYPES = Object.values(MATCH_TYPE);

export const MATCH_TYPE_STRING: Record<MatchType, string> = {
  [MATCH_TYPE.PREFIX]: 'Prefix',
  [MATCH_TYPE.EXACT_MATCH]: 'Exact Match',
};

export const SKIP_TYPE = {
  SKIP_CHECK: 'SKIP_CHECK',
  THROW_ERROR: 'THROW_ERROR',
} as const;

export type SkipType = valueOf<typeof SKIP_TYPE>;

export const ALL_SKIP_TYPES = Object.values(SKIP_TYPE);

export const SKIP_TYPE_STRING: Record<SkipType, string> = {
  [SKIP_TYPE.SKIP_CHECK]: 'Skip Check',
  [SKIP_TYPE.THROW_ERROR]: 'Throw Error',
};

export const TRANSACTION_APPROVAL_MODE = {
  IMMEDIATELY: 0,
  PENDING: 1,
  DELAY: 2,
} as const;

export type TransactionApprovalMode = valueOf<typeof TRANSACTION_APPROVAL_MODE>;

export const TRANSACTION_APPROVAL_MODE_STRING: Record<TransactionApprovalMode, string> = {
  [TRANSACTION_APPROVAL_MODE.IMMEDIATELY]: 'IMMEDIATELY',
  [TRANSACTION_APPROVAL_MODE.PENDING]: 'PENDING',
  [TRANSACTION_APPROVAL_MODE.DELAY]: 'DELAY',
};

export const ALL_TRANSACTION_APPROVAL_MODE = Object.values(TRANSACTION_APPROVAL_MODE);

export const ERROR_CODE = {
  RULE_EXIST_ERROR: 'already_enabled_rule_exists',
} as const;

export const TRANSACTION_LINK_BY_PM: Record<
  Exclude<
    AVAILABLE_PAYMENT_METHOD_STRING,
    | AVAILABLE_PAYMENT_METHOD_STRING.CASH
    | AVAILABLE_PAYMENT_METHOD_STRING.CREDITCARD
    | AVAILABLE_PAYMENT_METHOD_STRING.TADAPAY
    | AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE
    | AVAILABLE_PAYMENT_METHOD_STRING.DELIVERY
    | AVAILABLE_PAYMENT_METHOD_STRING.ABA_AOF
    | AVAILABLE_PAYMENT_METHOD_STRING.CORPORATE_CARD
    | AVAILABLE_PAYMENT_METHOD_STRING.ABA
    | AVAILABLE_PAYMENT_METHOD_STRING.WING
    | AVAILABLE_PAYMENT_METHOD_STRING.CURRENT_BALANCE
  >,
  string
> = {
  [AVAILABLE_PAYMENT_METHOD_STRING.PAO]: `${PATH.PaoTransactions}?page=1&rentalNumber=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.OCBC_PAO]: `${PATH.PaoTransactions}?page=1&rentalNumber=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.NETSQR]: `${PATH.PaoTransactions}?page=1&rentalNumber=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.CMCB]: `${PATH.CmcbTransactions}?page=1&size=20&rideId=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.ALIPAY_P]: `${PATH.AlipayTransactions}?page=1&rentalNumber=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.PIPAY_P]: `${PATH.PipayTransactions}?page=1&rentalNumber=`,
  [AVAILABLE_PAYMENT_METHOD_STRING.MOMOPAY_P]: `${PATH.MomopayTransactions}?page=1&rentalNumber=`,
};

export const ADDITIONAL_FARE_PRICE_CONSTRAINT: Partial<Record<Currency, Partial<Record<AdditionalFareType, Record<'min' | 'max', number>>>>> = {
  [Currency.SGD]: {
    [ADDITIONAL_FARE_TYPE.TOLL_FEE]: {
      min: -20,
      max: 20,
    },
    [ADDITIONAL_FARE_TYPE.ADDITIONAL_STOP]: {
      min: -100,
      max: 100,
    },
    [ADDITIONAL_FARE_TYPE.CAR_SEAT]: {
      min: -4,
      max: 4,
    },
    [ADDITIONAL_FARE_TYPE.BOOKING_FEE]: {
      min: -5,
      max: 5,
    },
    [ADDITIONAL_FARE_TYPE.AIRPORT_FEE]: {
      min: -100,
      max: 100,
    },
    [ADDITIONAL_FARE_TYPE.WAITING_FEE]: {
      min: -100,
      max: 100,
    },
    [ADDITIONAL_FARE_TYPE.GANTRY_FEE]: {
      min: -10,
      max: 10,
    },
    [ADDITIONAL_FARE_TYPE.BOOSTER_SEAT]: {
      min: -2,
      max: 2,
    },
    [ADDITIONAL_FARE_TYPE.ETC]: {
      min: -100,
      max: 100,
    },
  },
};

export const PROGRESS_STATUS = {
  PENDING: 0,
  IN_PROGRESS: 10,
  SUCCESS: 100,
  FAIL: 200,
} as const;

export type ProgressStatus = valueOf<typeof PROGRESS_STATUS>;

export const PROGRESS_STATUS_STRINGS: Record<ProgressStatus, string> = {
  [PROGRESS_STATUS.PENDING]: 'Pending',
  [PROGRESS_STATUS.IN_PROGRESS]: 'In Progress',
  [PROGRESS_STATUS.SUCCESS]: 'Success',
  [PROGRESS_STATUS.FAIL]: 'Fail',
};

export const TRANSACTION_STATUS = {
  0: 'WAIT_READY',
  10: 'READY',
  60: 'FINISHED',
  100: 'CANCELED',
  110: 'CANCELED_REFUNDED',
  900: 'ERROR',
  910: 'CANCEL_FAILED',
} as const;

export type TransactionStatus = valueOf<typeof TRANSACTION_STATUS>;

// ALL REGION
export const TRANSACTION_CATEGORY_TYPE = {
  INCENTIVES: 1,
  NON_PAID_TRIP_COMPENSATION: 2,
  FUNDED_SUBSIDY: 3,
  PAY_RIDER_FEE: 4,
  BALANCE_TRANSFER: 5,
  TIPS: 6,
  ERP: 7,
  CANCELLATION: 8,
  ADDITIONAL_FARES: 9,
  DELIVERY_COD: 11,
  DELIVERY_PAYMENT: 12,
  ONION_LOAN_REPAYMENT: 101,
  OTHERS: 999,
} as const;

export type TransactionCategoryType = valueOf<typeof TRANSACTION_CATEGORY_TYPE>;

export const TRANSACTION_CATEGORY_TYPE_STRINGS: Record<TransactionCategoryType, string> = {
  [TRANSACTION_CATEGORY_TYPE.INCENTIVES]: 'Incentives',
  [TRANSACTION_CATEGORY_TYPE.NON_PAID_TRIP_COMPENSATION]: 'Compensation for non-paid trips',
  [TRANSACTION_CATEGORY_TYPE.FUNDED_SUBSIDY]: 'Funded subsidy (like govt relief fund or premier program for SG)',
  [TRANSACTION_CATEGORY_TYPE.PAY_RIDER_FEE]: 'Pay Rider Fee',
  [TRANSACTION_CATEGORY_TYPE.BALANCE_TRANSFER]: 'Transfer of Balance',
  [TRANSACTION_CATEGORY_TYPE.TIPS]: 'Tips',
  [TRANSACTION_CATEGORY_TYPE.ERP]: 'ERP',
  [TRANSACTION_CATEGORY_TYPE.CANCELLATION]: 'Cancellation',
  [TRANSACTION_CATEGORY_TYPE.ADDITIONAL_FARES]: 'Additional Fares',
  [TRANSACTION_CATEGORY_TYPE.DELIVERY_COD]: 'Delivery COD',
  [TRANSACTION_CATEGORY_TYPE.DELIVERY_PAYMENT]: 'Delivery Payment',
  [TRANSACTION_CATEGORY_TYPE.ONION_LOAN_REPAYMENT]: 'Loan Repayment (Onion)',
  [TRANSACTION_CATEGORY_TYPE.OTHERS]: 'Others',
};

export const ALL_TRANSACTION_CATEGORY_TYPE = Object.values(TRANSACTION_CATEGORY_TYPE);

export const TIME_SELECTOR_TYPE = {
  NOW: 0,
  CREATE_TIME: 1,
  DROP_OFF_TIME: 2,
} as const;

export type TimeSelectorType = valueOf<typeof TIME_SELECTOR_TYPE>;

export const TIME_SELECTOR_TYPE_STRINGS: Record<TimeSelectorType, string> = {
  [TIME_SELECTOR_TYPE.NOW]: 'Now',
  [TIME_SELECTOR_TYPE.CREATE_TIME]: 'Create Time',
  [TIME_SELECTOR_TYPE.DROP_OFF_TIME]: 'Drop Off Time',
};

export const ALL_TIME_SELECTOR_TYPE = Object.values(TIME_SELECTOR_TYPE);

export const SCHEDULE_SURGE_PRESET_WEEK_DAY = {
  SUN: 'SUNDAY',
  MON: 'MONDAY',
  TUE: 'TUESDAY',
  WED: 'WEDNESDAY',
  THU: 'THURSDAY',
  FRI: 'FRIDAY',
  SAT: 'SATURDAY',
};

export type ScheduleSurgePresetWeekDay = valueOf<typeof SCHEDULE_SURGE_PRESET_WEEK_DAY>;

export const ALL_SCHEDULE_SURGE_PRESET_WEEK_DAY = Object.values(SCHEDULE_SURGE_PRESET_WEEK_DAY);

export const SCHEDULE_SURGE_PRESET_WEEK_DAY_STRINGS: Record<ScheduleSurgePresetWeekDay, string> = {
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.SUN]: 'Sunday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.MON]: 'Monday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.TUE]: 'Tuesday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.WED]: 'Wednesday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.THU]: 'Thursday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.FRI]: 'Friday',
  [SCHEDULE_SURGE_PRESET_WEEK_DAY.SAT]: 'Saturday',
};

export const TICKET_TYPE = {
  SIGN_UP_ISSUE: 'sign_up_issue',
  TRIP_ISSUE: 'trip_issue',
  DRIVER_ISSUES: 'driver_issues',
  CUST_SUGGESTIONS: 'cust_suggestions',
  DRIVER_SUGGESTIONS: 'driver_suggestions',
} as const;

export type TicketType = valueOf<typeof TICKET_TYPE>;

export const ALL_TICKET_TYPE = Object.values(TICKET_TYPE);

export const TICKET_PRIORITY = {
  URGENT: 'urgent',
  HIGH: 'high',
  NORMAL: 'normal',
  LOW: 'low',
} as const;

export type TicketPriority = valueOf<typeof TICKET_PRIORITY>;

export const ALL_TICKET_PRIORITY = Object.values(TICKET_PRIORITY);

export const TICKET_PRIORITY_STRINGS: Record<TicketPriority, string> = {
  [TICKET_PRIORITY.URGENT]: 'Urgent',
  [TICKET_PRIORITY.HIGH]: 'High',
  [TICKET_PRIORITY.NORMAL]: 'Normal',
  [TICKET_PRIORITY.LOW]: 'Low',
};

export const DRIVER_TUTORIAL_DRIVER_TYPES_SG = [DRIVER_TYPE.PRIVATE_HIRE, DRIVER_TYPE.TAXI, DRIVER_TYPE.HOURLY_RENTAL, DRIVER_TYPE.DELIVERY];

export const DRIVER_TUTORIAL_DRIVER_TYPES_KH = [
  DRIVER_TYPE.PRIVATE_HIRE,
  DRIVER_TYPE.TAXI,
  DRIVER_TYPE.TUKTUK,
  DRIVER_TYPE.BIKE,
  DRIVER_TYPE.DELIVERY,
];

export const DRIVER_TUTORIAL_DRIVER_TYPES_VN = [DRIVER_TYPE.PRIVATE_HIRE, DRIVER_TYPE.TAXI, DRIVER_TYPE.BIKE];

export const DRIVER_TUTORIAL_DRIVER_TYPES_TH = [DRIVER_TYPE.PRIVATE_HIRE, DRIVER_TYPE.TAXI, DRIVER_TYPE.BIKE];

export const DRIVER_TUTORIAL_DRIVER_TYPES_BY_REGION: Record<REGION | string, DRIVER_TYPE[]> = {
  [REGION.SG]: DRIVER_TUTORIAL_DRIVER_TYPES_SG,
  [REGION.KH]: DRIVER_TUTORIAL_DRIVER_TYPES_KH,
  [REGION.VN]: DRIVER_TUTORIAL_DRIVER_TYPES_VN,
  [REGION.ET]: ALL_DRIVER_TYPES,
  [REGION.KR]: ALL_DRIVER_TYPES,
  [REGION.HK]: ALL_DRIVER_TYPES,
  [REGION.ID]: ALL_DRIVER_TYPES,
  [REGION.TH]: DRIVER_TUTORIAL_DRIVER_TYPES_TH,
  [REGION.GLOBAL]: ALL_DRIVER_TYPES,
};

export const ACCOUNT_DELETE_STATUS = {
  PENDING: 'pending',
  DELETED: 'deleted',
  RESIGNED: 'resigned',
} as const;

export type AccountDeleteStatus = valueOf<typeof ACCOUNT_DELETE_STATUS>;

export const ALL_ACCOUNT_DELETE_STATUS = Object.values(ACCOUNT_DELETE_STATUS);

export const GATEWAY = {
  STRIPE: 'stripe',
  ADYEN: 'adyen',
  MOOVPAY: 'moovpay',
  ABA: 'aba',
  IAMPORT: 'iamport',
  PAYDOLLAR: 'paydollar',
} as const;

export type GatewayType = valueOf<typeof GATEWAY>;

export const TX_ACTION = {
  CREATE_CHARGE: 'CREATE_CHARGE',
  CAPTURE_CHARGE: 'CAPTURE_CHARGE',
  CANCEL: 'CANCEL',
  REFUND: 'REFUND',
  UPDATE_CHARGE: 'UPDATE_CHARGE',
} as const;

export type TxActionType = valueOf<typeof TX_ACTION>;

export const TUTORIAL_TYPE = {
  NEW_SIGN_UP: 1,
  ALL_DRIVERS: 2,
  SPECIFIC_DRIVERS: 3,
} as const;

export type TutorialType = valueOf<typeof TUTORIAL_TYPE>;

export const TUTORIAL_TYPE_STRING = {
  [TUTORIAL_TYPE.NEW_SIGN_UP]: 'New Signup',
  [TUTORIAL_TYPE.ALL_DRIVERS]: 'All Drivers',
  [TUTORIAL_TYPE.SPECIFIC_DRIVERS]: 'Specific Drivers',
};

export const ALL_TUTORIAL_TYPE = Object.values(TUTORIAL_TYPE);

export const FILE_UPLOADER_TYPE = {
  IMAGE: 'Image',
  EXCEL: 'Excel',
} as const;

export type FileUploaderType = valueOf<typeof FILE_UPLOADER_TYPE>;

export const CORPORATE_MEMBER_ROLE = {
  CONDITIONAL: 'conditional',
  NORMAL: 'normal',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
};

export const INVITE_WITH_EMAIL_FILTER_ROLES = [CORPORATE_MEMBER_ROLE.CONDITIONAL, CORPORATE_MEMBER_ROLE.NORMAL];

export type CorporateMemberRole = valueOf<typeof CORPORATE_MEMBER_ROLE>;

export const CORPORATE_MEMBER_ROLE_STRINGS = {
  [CORPORATE_MEMBER_ROLE.CONDITIONAL]: 'Conditional',
  [CORPORATE_MEMBER_ROLE.NORMAL]: 'Normal',
  [CORPORATE_MEMBER_ROLE.ADMIN]: 'Admin',
  [CORPORATE_MEMBER_ROLE.SUPER_ADMIN]: 'Super Admin',
};

export const INVITATION_STATUS = {
  PENDING: 'Pending',
  CONFIRMED: 'Confirmed',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  REGISTERED: 'Registered',
};

export const INVITE_WITH_EMAIL_FILTER_STATUSES = [
  INVITATION_STATUS.PENDING,
  INVITATION_STATUS.CONFIRMED,
  INVITATION_STATUS.DECLINED,
  INVITATION_STATUS.EXPIRED,
];

export type InvitationStatus = valueOf<typeof INVITATION_STATUS>;

export const TRANSACTION_PROGRESS_STATUS = {
  PENDING: 0,
  SUCCESS: 100,
  FAIL: 900,
} as const;

export type TransactionProgressStatus = valueOf<typeof TRANSACTION_PROGRESS_STATUS>;

export const EXPORT_TYPE = {
  TEXT: 'TEXT',
  HTML: 'HTML',
} as const;

export type ExportType = valueOf<typeof EXPORT_TYPE>;

export const WING_TRANSACTION_TYPE = {
  TOP_UP: 'top_up',
  QR_PAY: 'qr_pay',
} as const;

export type WingTransactionType = valueOf<typeof WING_TRANSACTION_TYPE>;

export const WING_TRANSACTION_TYPE_STRING = {
  [WING_TRANSACTION_TYPE.TOP_UP]: 'Top Up',
  [WING_TRANSACTION_TYPE.QR_PAY]: 'QR Pay',
};

export const CAR_SIZE = {
  S: 'S',
  M: 'M',
  L: 'L',
} as const;

export const CAR_SIZE_UNIT = {
  [CAR_SIZE.S]: 90,
  [CAR_SIZE.M]: 120,
} as const;

export const CAR_PLATE_COLOR = {
  WHITE: 'white',
  GREEN: 'green',
  YELLOW: 'yellow',
  RED: 'RED',
} as const;

export const ALL_COLOR_PLATE_COLOR = Object.values(CAR_PLATE_COLOR);

export const RIDE_EQUIPMENT_TYPE = {
  CARSEAT: 2, // 1 shl 1
  BOOSTER_SEAT: 64, //1 shl 6
  DELIVERY_SPACE: 128, //1 shl 7
  PETS: 256, //1 shl 8
  WHEEL_CHAIR: 512, //1 shl 9
} as const;

export const ALL_RIDE_EQUIPMENT_TYPE = Object.values(RIDE_EQUIPMENT_TYPE);

const ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR = ALL_RIDE_EQUIPMENT_TYPE.filter((type) => type !== RIDE_EQUIPMENT_TYPE.WHEEL_CHAIR);

export const ALL_RIDE_EQUIPMENT_TYPE_BY_REGION: Record<REGION, RideEquipmentType[]> = {
  [REGION.SG]: ALL_RIDE_EQUIPMENT_TYPE,
  [REGION.KH]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.VN]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.TH]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.HK]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.ET]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.ID]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.KR]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
  [REGION.GLOBAL]: ALL_RIDE_EQUIPMENT_TYPE_WITHOUT_WHEEL_CHAIR,
};

export type RideEquipmentType = valueOf<typeof RIDE_EQUIPMENT_TYPE>;

export const RIDE_EQUIPMENT_STRING: Record<RideEquipmentType, string> = {
  [RIDE_EQUIPMENT_TYPE.CARSEAT]: 'CAR SEAT',
  [RIDE_EQUIPMENT_TYPE.BOOSTER_SEAT]: 'BOOSTER SEAT',
  [RIDE_EQUIPMENT_TYPE.DELIVERY_SPACE]: 'DELIVERY',
  [RIDE_EQUIPMENT_TYPE.PETS]: 'PETS',
  [RIDE_EQUIPMENT_TYPE.WHEEL_CHAIR]: 'WHEELCHAIR SPACE',
};

export const MAINTENANCE_TYPE = {
  TOP_UP: 20,
  CASH_OUT: 30,
  PAYMENT_METHOD: 50,
} as const;

export type MaintenanceType = valueOf<typeof MAINTENANCE_TYPE>;

export const MAINTENANCE_TYPE_STRINGS = {
  [MAINTENANCE_TYPE.TOP_UP]: 'TOP_UP',
  [MAINTENANCE_TYPE.CASH_OUT]: 'CASH_OUT',
  [MAINTENANCE_TYPE.PAYMENT_METHOD]: 'PAYMENT_METHOD',
};

export const ALL_MAINTENANCE_TYPE = Object.values(MAINTENANCE_TYPE);

export const ALL_TRANSACTION_CHANNEL_BY_MAINTENANCE_TYPE: Record<MaintenanceType, TRANSACTION_CHANNEL_TYPE[]> = {
  [MAINTENANCE_TYPE.CASH_OUT]: [
    TRANSACTION_CHANNEL.DBS,
    TRANSACTION_CHANNEL.WING,
    TRANSACTION_CHANNEL.ABA,
    TRANSACTION_CHANNEL.SBC,
    TRANSACTION_CHANNEL.CITI,
  ],
  [MAINTENANCE_TYPE.TOP_UP]: [
    TRANSACTION_CHANNEL.DBS,
    TRANSACTION_CHANNEL.WING,
    TRANSACTION_CHANNEL.ABA,
    TRANSACTION_CHANNEL.SBC,
    TRANSACTION_CHANNEL.CITI,
  ],
  [MAINTENANCE_TYPE.PAYMENT_METHOD]: [
    TRANSACTION_CHANNEL.ALIPAY_P,
    TRANSACTION_CHANNEL.PIPAY_P,
    TRANSACTION_CHANNEL.CMCB,
    TRANSACTION_CHANNEL.WING,
    TRANSACTION_CHANNEL.CREDITCARD,
    TRANSACTION_CHANNEL.CORPORATE,
    TRANSACTION_CHANNEL.TADAPAY,
    TRANSACTION_CHANNEL.MOMO_P,
    TRANSACTION_CHANNEL.NETSQR,
    TRANSACTION_CHANNEL.OCBC_PAO,
    TRANSACTION_CHANNEL.DELIVERY,
    TRANSACTION_CHANNEL.ABA_AOF,
  ],
};

export const PING_LOG_DISPATCH_TYPE_STRING: Record<string, string> = {
  ['normal']: 'Driver Ping',
  ['ad']: 'Auto Dispatch',
  ['mdd']: 'MDD',
};

export const LOCATION_POLICY = {
  BOTH: 1,
  ONLY_PICK_UP: 2,
  ONLY_DROP_OFF: 3,
} as const;

export type LocationPolicyType = valueOf<typeof LOCATION_POLICY>;

export const ALL_LOCATION_POLICY_TYPE = Object.values(LOCATION_POLICY);

export const LOCATION_POLICY_STRINGS: Record<LocationPolicyType, string> = {
  [LOCATION_POLICY.BOTH]: 'Both',
  [LOCATION_POLICY.ONLY_PICK_UP]: 'Only Pickup',
  [LOCATION_POLICY.ONLY_DROP_OFF]: 'Only Dropoff',
};

export const COORD_TYPE = {
  UNKNOWN: -1,
  BAIDU: 0,
  GOOGLE: 2,
} as const;

export type CoordType = valueOf<typeof COORD_TYPE>;

export const BULK_COUPON_ISSUE_PROGRESS_STATUS = {
  IN_PROGRESS: 100,
  FINISH: 200,
  FAILED: 900,
} as const;

export type BulkCouponIssueProgressStatus = valueOf<typeof BULK_COUPON_ISSUE_PROGRESS_STATUS>;

export const BULK_COUPON_ISSUE_PROGRESS_STATUS_STRING: Record<BulkCouponIssueProgressStatus, string> = {
  [BULK_COUPON_ISSUE_PROGRESS_STATUS.IN_PROGRESS]: 'In Progress',
  [BULK_COUPON_ISSUE_PROGRESS_STATUS.FINISH]: 'Finish',
  [BULK_COUPON_ISSUE_PROGRESS_STATUS.FAILED]: 'Failed',
};

export const ATTRIBUTE_TYPE = {
  AIRPORT: 'AIRPORT',
  CATEGORY_AIRPORT: 'CATEGORY_AIRPORT',
  CATEGORY_PUBLIC_HOUSING: 'CATEGORY_PUBLIC_HOUSING',
  CATEGORY_PRIVATE_HOUSING: 'CATEGORY_PRIVATE_HOUSING',
  CATEGORY_LANDED_HOUSING: 'CATEGORY_LANDED_HOUSING',
  CATEGORY_RETAIL: 'CATEGORY_RETAIL',
  CATEGORY_OFFICE: 'CATEGORY_OFFICE',
  CATEGORY_HOTEL: 'CATEGORY_HOTEL',
  CATEGORY_SCHOOL: 'CATEGORY_SCHOOL',
  CATEGORY_SHOP_HOUSE: 'CATEGORY_SHOP_HOUSE',
  CATEGORY_GOVERNMENT_INSTITUTE: 'CATEGORY_GOVERNMENT_INSTITUTE',
  CATEGORY_HOSPITAL: 'CATEGORY_HOSPITAL',
  CATEGORY_ATTRACTION: 'CATEGORY_ATTRACTION',
  CATEGORY_INDUSTRIAL: 'CATEGORY_INDUSTRIAL',
  CATEGORY_CAR_PARK: 'CATEGORY_CAR_PARK',
  CATEGORY_OTHERS: 'CATEGORY_OTHERS',
} as const;

export type AttributeType = valueOf<typeof ATTRIBUTE_TYPE>;

export const ALL_ATTRIBUTE_TYPE = Object.values(ATTRIBUTE_TYPE);

export const ALL_CATEGORY_ATTRIBUTE_TYPE = [
  ATTRIBUTE_TYPE.CATEGORY_AIRPORT,
  ATTRIBUTE_TYPE.CATEGORY_PUBLIC_HOUSING,
  ATTRIBUTE_TYPE.CATEGORY_PRIVATE_HOUSING,
  ATTRIBUTE_TYPE.CATEGORY_LANDED_HOUSING,
  ATTRIBUTE_TYPE.CATEGORY_RETAIL,
  ATTRIBUTE_TYPE.CATEGORY_OFFICE,
  ATTRIBUTE_TYPE.CATEGORY_HOTEL,
  ATTRIBUTE_TYPE.CATEGORY_SCHOOL,
  ATTRIBUTE_TYPE.CATEGORY_SHOP_HOUSE,
  ATTRIBUTE_TYPE.CATEGORY_GOVERNMENT_INSTITUTE,
  ATTRIBUTE_TYPE.CATEGORY_HOSPITAL,
  ATTRIBUTE_TYPE.CATEGORY_ATTRACTION,
  ATTRIBUTE_TYPE.CATEGORY_INDUSTRIAL,
  ATTRIBUTE_TYPE.CATEGORY_CAR_PARK,
  ATTRIBUTE_TYPE.CATEGORY_OTHERS,
];

export const ATTRIBUTE_TYPE_STRING = {
  [ATTRIBUTE_TYPE.AIRPORT]: 'Airport',
  [ATTRIBUTE_TYPE.CATEGORY_AIRPORT]: 'Airport Category',
  [ATTRIBUTE_TYPE.CATEGORY_PUBLIC_HOUSING]: 'Public Housing',
  [ATTRIBUTE_TYPE.CATEGORY_PRIVATE_HOUSING]: 'Private Housing',
  [ATTRIBUTE_TYPE.CATEGORY_LANDED_HOUSING]: 'Landed Housing',
  [ATTRIBUTE_TYPE.CATEGORY_RETAIL]: 'Retail',
  [ATTRIBUTE_TYPE.CATEGORY_OFFICE]: 'Office',
  [ATTRIBUTE_TYPE.CATEGORY_HOTEL]: 'Hotel',
  [ATTRIBUTE_TYPE.CATEGORY_SCHOOL]: 'School',
  [ATTRIBUTE_TYPE.CATEGORY_SHOP_HOUSE]: 'Shophouse',
  [ATTRIBUTE_TYPE.CATEGORY_GOVERNMENT_INSTITUTE]: 'Government Institute',
  [ATTRIBUTE_TYPE.CATEGORY_HOSPITAL]: 'Hospital',
  [ATTRIBUTE_TYPE.CATEGORY_ATTRACTION]: 'Attraction',
  [ATTRIBUTE_TYPE.CATEGORY_INDUSTRIAL]: 'Industrial',
  [ATTRIBUTE_TYPE.CATEGORY_CAR_PARK]: 'Carpark',
  [ATTRIBUTE_TYPE.CATEGORY_OTHERS]: 'Others',
};

export const CARD_OWNER_TYPE = {
  PERSONAL: 'PERSONAL',
  CORP_PERSONAL: 'CORP_PERSONAL',
  CORP_PUBLIC: 'CORP_PUBLIC',
} as const;

export type CardOwnerType = valueOf<typeof CARD_OWNER_TYPE>;

export const COUPON_CATEGORY = {
  MERCHANT_VOUCHERS: 1,
  MARKETING_CAMPAIGNS: 2,
  CORPORATE: 3,
  PARTNERSHIPS: 4,
  BULK_VOUCHER_PURCHASE: 5,
  SERVICE_RECOVERY: 6,
  OTHERS: 999,
} as const;

export type CouponCategoryType = valueOf<typeof COUPON_CATEGORY>;

export const ALL_COUPON_CATEGORY = Object.values(COUPON_CATEGORY);

export const COUPON_CATEGORY_STRING = {
  [COUPON_CATEGORY.MERCHANT_VOUCHERS]: 'Merchant Vouchers',
  [COUPON_CATEGORY.MARKETING_CAMPAIGNS]: 'Marketing Campaigns',
  [COUPON_CATEGORY.CORPORATE]: 'Corporate',
  [COUPON_CATEGORY.PARTNERSHIPS]: 'Partnerships',
  [COUPON_CATEGORY.BULK_VOUCHER_PURCHASE]: 'Bulk Voucher Purchase',
  [COUPON_CATEGORY.SERVICE_RECOVERY]: 'Service Recovery',
  [COUPON_CATEGORY.OTHERS]: 'Others',
};

export const ROUTE_ENGINE_TYPE = {
  GRAPH_HOPPER: 'GraphHopper',
  GOOGLE_MAP: 'GoogleMap',
  QI: 'QI',
} as const;

export type RouteEngineType = valueOf<typeof ROUTE_ENGINE_TYPE>;

export const ALL_ROUTE_ENGINE_TYPE = Object.values(ROUTE_ENGINE_TYPE);

export const ROUTING_SCHEME = {
  SHORTEST: 'shortest',
  FASTEST: 'fastest',
  STRAIGHT_LINE: 'straight_line',
  CUSTOM: 'custom',
} as const;

export type RoutingSchemeType = valueOf<typeof ROUTING_SCHEME>;

export const ALL_ROUTING_SCHEME_TYPE = Object.values(ROUTING_SCHEME);

export const LIGHT_MAP_STYLE = [
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [
      {
        color: '#c7c7c7',
      },
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ebebeb',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: 'transit.station.bus',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit.station.bus',
    elementType: 'labels.icon',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 50,
      },
    ],
  },
  {
    featureType: 'transit.station.rail',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        color: '#acdbfd',
      },
    ],
  },
];

export const MAP_FILTER_ALL_VALUE = -999;

export const REVIEWER_TYPE = {
  All: 'All',
  DRIVER: 'DRIVER',
  RIDER: 'RIDER',
} as const;

export type ReviewerType = valueOf<typeof REVIEWER_TYPE>;

export const ALL_REVIEWER_TYPE = Object.values(REVIEWER_TYPE);

export const REVIEWER_TYPE_STRING = {
  [REVIEWER_TYPE.DRIVER]: 'Driver',
  [REVIEWER_TYPE.RIDER]: 'Rider',
  [REVIEWER_TYPE.All]: 'All',
};

export const REVIEW_TAG_TYPE = {
  /** good quality :: reviewer: rider, reviewee : driver **/
  DRIVER_EXCELLENT_SERVICE: 401,
  DRIVER_AWESOME_PERSONALITY: 402,
  DRIVER_CLEAN_VEHICLE: 403,
  DRIVER_SAFE_DRIVING: 404,
  DRIVER_EXPOERT_NAVIGATOR: 405,
  /** bad quality :: reviewer: rider, reviewee : driver **/
  DRIVER_RUDE: 451,
  DRIVER_RECKLESS_DRIVING: 452,
  DRIVER_POOR_NAVIGATION: 453,
  DRIVER_UNCLEAN_VEHICLE: 454,
  DRIVER_POOR_SERVICE: 455,
  DRIVER_INSENSITIVE_QUESTIONS: 456,
  DRIVER_DID_NOT_PICKUP: 457,
  DRIVER_APP_SHOWED_A_DIFFERENT_DRIVER: 458,
  /** good quality :: reviewer: driver, reviewee : rider **/
  RIDER_EASY_GOING: 501,
  RIDER_PUNCTUAL: 502,
  RIDER_ENJOYS_SILENCE: 503,
  RIDER_AWESOME_PERSONALITY: 504,
  RIDER_NEAT_AND_TIDY: 505,
  /** bad quality :: reviewer: driver, reviewee : rider **/
  RIDER_NOT_PUNCTUAL: 551,
  RIDER_RUDE: 552,
  RIDER_UNRESPONSIVE_CALLS: 553,
  RIDER_OFFER_EXCESSIVE_DIRECTIONS: 554,
  RIDER_WRONG_PICKUP_ADDRESS: 555,
  RIDER_INSENSITIVE_QUESTIONS: 556,
  RIDER_AGGRESSIVE_BEHAVIOUR: 557,
  RIDER_DIRTIED_MY_VEHICLE: 558,
  //Good, Bad 각각 하나씩 Others 태그내려옴
  OTHERS: 999,
} as const;

export type ReviewTagType = valueOf<typeof REVIEW_TAG_TYPE>;

export const APPROVAL_COUNT = {
  0: 0,
  1: 1,
} as const;

export const ALL_APPROVAL_COUNT = Object.values(APPROVAL_COUNT);

export const QUALITY_VIEW = {
  GOOD_QUALITY_1: 1,
  GOOD_QUALITY_2: 2,
  GOOD_QUALITY_3: 3,
  GOOD_QUALITY_4: 4,
  GOOD_QUALITY_5: 5,
  BAD_QUALITY_1: 6,
  BAD_QUALITY_2: 7,
  BAD_QUALITY_3: 8,
  BAD_QUALITY_4: 9,
  BAD_QUALITY_5: 10,
  BAD_QUALITY_6: 11,
  BAD_QUALITY_7: 12,
  BAD_QUALITY_8: 13,
  DRIVER_OTHER_QUALITY: 499,
  RIDER_OTHER_QUALITY: 599,
} as const;

export const QUALITY_VIEW_BY_REVIEW_TAG: Record<number, number> = {
  [REVIEW_TAG_TYPE.DRIVER_EXCELLENT_SERVICE]: QUALITY_VIEW.GOOD_QUALITY_1,
  [REVIEW_TAG_TYPE.DRIVER_AWESOME_PERSONALITY]: QUALITY_VIEW.GOOD_QUALITY_2,
  [REVIEW_TAG_TYPE.DRIVER_CLEAN_VEHICLE]: QUALITY_VIEW.GOOD_QUALITY_3,
  [REVIEW_TAG_TYPE.DRIVER_SAFE_DRIVING]: QUALITY_VIEW.GOOD_QUALITY_4,
  [REVIEW_TAG_TYPE.DRIVER_EXPOERT_NAVIGATOR]: QUALITY_VIEW.GOOD_QUALITY_5,
  [REVIEW_TAG_TYPE.DRIVER_RUDE]: QUALITY_VIEW.BAD_QUALITY_1,
  [REVIEW_TAG_TYPE.DRIVER_RECKLESS_DRIVING]: QUALITY_VIEW.BAD_QUALITY_2,
  [REVIEW_TAG_TYPE.DRIVER_POOR_NAVIGATION]: QUALITY_VIEW.BAD_QUALITY_3,
  [REVIEW_TAG_TYPE.DRIVER_UNCLEAN_VEHICLE]: QUALITY_VIEW.BAD_QUALITY_4,
  [REVIEW_TAG_TYPE.DRIVER_POOR_SERVICE]: QUALITY_VIEW.BAD_QUALITY_5,
  [REVIEW_TAG_TYPE.DRIVER_INSENSITIVE_QUESTIONS]: QUALITY_VIEW.BAD_QUALITY_6,
  [REVIEW_TAG_TYPE.DRIVER_DID_NOT_PICKUP]: QUALITY_VIEW.BAD_QUALITY_7,
  [REVIEW_TAG_TYPE.DRIVER_APP_SHOWED_A_DIFFERENT_DRIVER]: QUALITY_VIEW.BAD_QUALITY_8,
  [REVIEW_TAG_TYPE.RIDER_EASY_GOING]: QUALITY_VIEW.GOOD_QUALITY_1,
  [REVIEW_TAG_TYPE.RIDER_PUNCTUAL]: QUALITY_VIEW.GOOD_QUALITY_2,
  [REVIEW_TAG_TYPE.RIDER_ENJOYS_SILENCE]: QUALITY_VIEW.GOOD_QUALITY_3,
  [REVIEW_TAG_TYPE.RIDER_AWESOME_PERSONALITY]: QUALITY_VIEW.GOOD_QUALITY_4,
  [REVIEW_TAG_TYPE.RIDER_NEAT_AND_TIDY]: QUALITY_VIEW.GOOD_QUALITY_5,
  [REVIEW_TAG_TYPE.RIDER_NOT_PUNCTUAL]: QUALITY_VIEW.BAD_QUALITY_1,
  [REVIEW_TAG_TYPE.RIDER_RUDE]: QUALITY_VIEW.BAD_QUALITY_2,
  [REVIEW_TAG_TYPE.RIDER_UNRESPONSIVE_CALLS]: QUALITY_VIEW.BAD_QUALITY_3,
  [REVIEW_TAG_TYPE.RIDER_OFFER_EXCESSIVE_DIRECTIONS]: QUALITY_VIEW.BAD_QUALITY_4,
  [REVIEW_TAG_TYPE.RIDER_WRONG_PICKUP_ADDRESS]: QUALITY_VIEW.BAD_QUALITY_5,
  [REVIEW_TAG_TYPE.RIDER_INSENSITIVE_QUESTIONS]: QUALITY_VIEW.BAD_QUALITY_6,
  [REVIEW_TAG_TYPE.RIDER_AGGRESSIVE_BEHAVIOUR]: QUALITY_VIEW.BAD_QUALITY_7,
  [REVIEW_TAG_TYPE.RIDER_DIRTIED_MY_VEHICLE]: QUALITY_VIEW.BAD_QUALITY_8,
};

export const PASSWORD_CRITERIA_REGEX: Partial<Record<PasswordCriteriaType, RegExp>> = {
  [PASSWORD_CRITERIA.MINIMUM_CHARACTER_LENGTH]: regex.passwordMinimumCharacterLength,
  [PASSWORD_CRITERIA.CONTAIN_BOTH_LETTER_AND_NUMBER]: regex.passwordContainBothLetterAndNumber,
  [PASSWORD_CRITERIA.CONTAIN_UPPER_CASE_LETTER]: regex.passwordContainUpperCaseLetter,
  [PASSWORD_CRITERIA.CONTAIN_LOWER_CASE_LETTER]: regex.passwordContainLowerCaseLetter,
  [PASSWORD_CRITERIA.CONTAIN_SYMBOL]: regex.passwordContainSymbol,
  [PASSWORD_CRITERIA.DO_NOT_INCLUDE_SPACE]: regex.passwordNotIncludeSpace,
};

export const OTHER_QUALITY_VIEW_BY_USER_MODEL: Record<string, number> = {
  [USER_MODEL.Rider]: QUALITY_VIEW.RIDER_OTHER_QUALITY,
  [USER_MODEL.Driver]: QUALITY_VIEW.DRIVER_OTHER_QUALITY,
};

export const MINIMUM_DESKTOP_WIDTH = 992;

export const VALIDATE_DRIVER_TYPES = [DRIVER_TYPE.PRIVATE_HIRE, DRIVER_TYPE.TAXI, DRIVER_TYPE.HOURLY_RENTAL, DRIVER_TYPE.DELIVERY];

export interface BulkCreateTransactionsDto {
  region: REGION;
  referenceYear: number;
  referenceMonth: number;
  transactionCategoryType: TransactionCategoryType | SGTransactionCategoryType;
}

export const ALLOWANCE_TYPE_EMPTY_OPTION_VALUE = 999;

export const CITIES_BY_REGION: Record<string, CITY[]> = {
  SG: [CITY.SINGAPORE],
  KH: [CITY.PHNOMPENH, CITY.SIEMREAP, CITY.SIHANOUK_VILLE, CITY.KPK],
  VN: [CITY.HOCHIMINH],
  KR: [CITY.SEOUL],
  TH: [CITY.BANGKOK],
  HK: [CITY.HONGKONG],
};

export const DYNAMIC_SURGE_COLORS = ['#66ff00', '#99ff00', '#ccff00', '#00ff00', '#FFCC00', '#ff9900', '#ff6600', '#FF3300', '#FF0000'];
